import React, { useState } from 'react';
import { Tabs } from 'antd';
import TaxesTable from '@app/components/tables/BasicTable/ReportTable/TaxesTable';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

const { TabPane } = Tabs;

const TaxesPage: React.FC = () => {
  const yearData = [
    { id: 1, tax: 'Tax 1', rate: 10, numberOfOrder: 20, taxAmount: 200, shippingTaxAmount: 50, totalTax: 250 },
  ];

  const lastMonthData = [
    { id: 1, tax: 'Tax 1', rate: 10, numberOfOrder: 15, taxAmount: 150, shippingTaxAmount: 40, totalTax: 190 },
  ];

  const thisMonthData = [
    { id: 1, tax: 'Tax 1', rate: 10, numberOfOrder: 25, taxAmount: 250, shippingTaxAmount: 60, totalTax: 310 },
  ];

  const [activeTab, setActiveTab] = useState('year');

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div>
    <BaseCard id="basic-table" title="Taxes" padding="1.25rem">
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Year" key="year">
          <TaxesTable data={yearData} />
        </TabPane>
        <TabPane tab="Last Month" key="lastMonth">
          <TaxesTable data={lastMonthData} />
        </TabPane>
        <TabPane tab="This Month" key="thisMonth">
          <TaxesTable data={thisMonthData} />
        </TabPane>
      </Tabs>
      </BaseCard>
    </div>
  );
};

export default TaxesPage;
