import { get, post, remove } from '@app/api/http.api';

export interface Appointment {
  bill_first_name: any;
  bill_last_name: any;
  id: number | null;
  created_at: string | null;
  updated_at: string | null;
  order_line_id: number | null;
  customer_status: string | null;
  status: string | null;
  staff: string | null;
  all_day: boolean | null;
  customer_timezone: string | null;
  start_date_time: string | null;
  end_date_time: string | null;
  order_id: number | null;
  product_id: number | null;
  product_name: string | null;
  qty: number | null;
  customer_id: number | null;
  order_created_at: string | null;
  appointment_status: string;
}

export interface OrderOption {
  id: number | null;
  created_at?: string;
  customer_id?: number;
  bill_first_name?: string;
  bill_last_name?: string;
  bill_company?: string;
  bill_address_line1?: string;
  bill_address_line2?: string;
  bill_city?: string;
  bill_postcode?: string;
  bill_country?: string;
  bill_state?: string;
  bill_email?: string;
  bill_phone_no?: string;
}

export interface Product {
  id?: number;
  name?: string;
  is_appointable: boolean;
  quantity?: number
}

export interface CustomerOption {
  id?: number;
  first_name?: string;
  last_name?: string;
  bill_email?: string;
}

export interface CustomerTimezoneOption {
  key?: number;
  name?: string;
}

export interface StaffOption {
  key?: string;
  name?: string;
}

export interface CustomerStatusOption {
  key?: string;
  name?: string;
}

export interface AppointmentStatusOption {
  key?: string;
  name?: string;
  disabled?: boolean;
}

export interface AppointmentApiData {
  appointment: Appointment;
  order_options: OrderOption[];
  customer_options: CustomerOption[];
  product_options: Product[];
  customer_status_options: CustomerStatusOption[];
  appointment_status_options: AppointmentStatusOption[];
  staff_options: StaffOption[];
  selected_customer: CustomerDetails;
  editable:boolean;
}

export interface AppointmentRequest {
  id: number;
  order_id: number | null;
  customer_id: number;
  customer_status: string | null;
  status: string | null;
  product_id: number;
  staff: string | null;
  qty: number;
  all_day: boolean | null;
  start_date_time: string | null;
  end_date_time: string | null;
  customer_timezone: string | null;
  bill_first_name: string;
  bill_last_name: string;
  bill_company: string;
  bill_address_line1: string;
  bill_address_line2: string;
  bill_city: string;
  bill_postcode: string;
  bill_country: string;
  bill_state: string;
  bill_email: string;
  bill_phone_no: string;
}

export interface AppointmentTableRow {
  bill_first_name: any;
  bill_last_name: any;
  id: number;
  status: string;
  staff: string;
  all_day: boolean;
  start_date_time: string;
  end_date_time: string;
  order_id: number;
  product_id: number;
  qty: number;
  product_name: string;
  customer_name: string;
  customer_status: string;
  sale_price: number;
  bill_phone_no: number;
  bill_email: string;
  appointment_status: string;
}

export interface CustomerDetails {
  id: number;
  first_name: string;
  last_name: string;
  company: string;
  address_line1: string;
  address_line2: string;
  postcode: string;
  country: string;
  state: string;
  phone: string;
  city: string | null;
  bill_email: string;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
  status?:string | null;
}

export interface AppointmentTableData {
  appointments: {
    data: AppointmentTableRow[];
    pagination: Pagination;
  };
  status_options: AppointmentStatusOption[];
}

export interface AppointmentTableDataApi {
  appointments: PaginatedResponse<AppointmentTableRow>;
  status_options: AppointmentStatusOption[];
}

export const getAppointmentTableData = async (parameters:Parameters): Promise<AppointmentTableData> => {
  try {
    const { data, status } = await get<AppointmentTableDataApi>(
      'appointments',
      { params: parameters },
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);

    const appointments: AppointmentTableRow[] = data.appointments.data.map((rawData: AppointmentTableRow) => ({
      id: rawData.id,
      status: rawData.status,
      staff: rawData.staff,
      all_day: rawData.all_day,
      start_date_time: rawData.start_date_time,
      end_date_time: rawData.end_date_time,
      order_id: rawData.order_id,
      product_id: rawData.product_id,
      qty: rawData.qty,
      product_name: rawData.product_name,
      customer_name: rawData.bill_first_name + ' ' + rawData.bill_last_name,
      bill_first_name: rawData.bill_first_name,
      bill_last_name: rawData.bill_last_name,
      customer_status: rawData.customer_status,
      sale_price: rawData.sale_price,
      bill_email: rawData.bill_email,
      bill_phone_no: rawData.bill_phone_no,
      appointment_status: rawData.appointment_status
    }));

    return {appointments:{
      data: appointments,
      pagination: {
        current: data.appointments.current_page,
        pageSize: data.appointments.per_page,
        total: data.appointments.total,
      },
    },
    status_options:data.status_options
  };
  } catch (error: any) {
    throw error;
  }
};

export const getCustomerDetails = async (id: number) => {
  try {
    const { data, status } = await get<CustomerDetails>(`appointments/customer/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const confirmAppointment = async (id: number) => {
  try {
    const { message, status } = await get(`appointments/${id}/confirm`, undefined, true);
    console.log(`Status: ${status}`, `message: ${message}`);
    return message;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const getAppointments = async () => {
  try {
    const { data, status } = await get<Appointment[]>(`appointments`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storeAppointments = async (StoreAppointmentData: AppointmentRequest) => {
  try {
    const { data, status } = await post<AppointmentRequest>(
      `appointments/create`,
      StoreAppointmentData,
      undefined,
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const updateAppointment = async (id: number, updateAppointmentData: AppointmentRequest) => {
  try {
    const { data, status } = await post<AppointmentRequest>(
      `appointments/${id}/edit`,
      updateAppointmentData,
      undefined,
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createAppointment = async () => {
  try {
    const { data, status } = await get<AppointmentApiData>(`appointments/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showAppointment = async (id: number) => {
  try {
    const { data, status } = await get<AppointmentApiData>(`appointments/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editAppointment = async (id: number) => {
  try {
    const { data, status } = await get<AppointmentApiData>(`/appointments/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const deleteAppointment = async (id: number) => {
  try {
    const { status } = await remove(`/appointments/${id}`, undefined, true);
    console.log(`Status: ${status}`);
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
