import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Modal, Checkbox, Tooltip, Button, InputRef, Input } from 'antd';
import { SearchOutlined, DownloadOutlined, EllipsisOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Customer as CustomerType, Pagination, getCustomerTableData } from '@app/api/customers.api';
import { CSVLink } from 'react-csv';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { IoEye } from 'react-icons/io5';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import dayjs from 'dayjs';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

interface CustomerTableRow {
  id: number;
  name: string;
  // lastActive: string;
  // dateRegistered: string;
  created_at: string;
  email: string;
  orders: number;
  totalSpend: number;
  aov: number;
  country: string;
  city: string;
  state: string;
  postalCode: string;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const CustomerTable: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: CustomerTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const [csvData, setCSVData] = useState<CustomerTableRow[]>([]);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleErrorMessages = useApiErrorHandler();

  const handleDownload = () => {
    const csvData = tableData.data.map((row) => ({
      name: row.name,
      // lastActive: row.lastActive,
      // dateRegistered: row.dateRegistered,
      created_at: row.created_at,
      email: row.email,
      orders: row.orders,
      totalSpend: row.totalSpend,
      aov: row.aov,
      country: row.country,
      city: row.city,
      state: row.state,
      postalCode: row.postalCode,
    }));

    // Trigger CSV download
    setCSVData(csvData as CustomerTableRow[]);
  };

  const fetch = useCallback(async (pagination: Pagination) => {
    try {
      setTableData((prevData) => ({ ...prevData, loading: true }));
      const response = await getCustomerTableData({ ...pagination, search: form.getFieldValue('search')});
      setTableData({ data: response.data, pagination: response.pagination, loading: false });
    } catch (error) {
      console.error('Error fetching customer data:', error);
      setTableData((prevData) => ({ ...prevData, loading: false }));
      handleErrorMessages(error);
    }
  }, []);

  useEffect(() => {
    fetch(tableData.pagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.id !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const handleFilter = (value: string) => {
    // TODO: handle filter
  };

  const handlebtnClickEdit = (id: number) => {
    navigate(`/edit-customer/${id}`);
  };

  const handlebtnClickView = (id: number) => {
    navigate(`/show-customer/${id}`);
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Customers`}</span>
    );
  };

  const columns: ColumnsType<CustomerTableRow> = [
    {
      title: t('Username'),
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: t('Last Active'),
    //   dataIndex: 'lastActive',
    //   key: 'lastActive',
    // },
    {
      title: t('Date Registered'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => dayjs(record.created_at).format('LLL'),
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('Orders'),
      dataIndex: 'orders',
      key: 'orders',
    },
    {
      title: t('Total Spend'),
      dataIndex: 'totalSpend',
      key: 'totalSpend',
    },
    {
      title: t('AOV'),
      dataIndex: 'aov',
      key: 'aov',
    },
    {
      title: t('Country'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: t('City'),
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: t('State'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('Postal Code'),
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (_, record) => {
        return (
          <BaseSpace>
            <Tooltip title={t('view')}>
              <Button type="text" onClick={() => handlebtnClickView(record.id)} icon={<IoEye />} />
            </Tooltip>

            <Tooltip title={t('common.edit')}>
              <Button type="text" onClick={() => handlebtnClickEdit(record.id)} icon={<MdModeEdit />} />
            </Tooltip>

            <Tooltip title={t('tables.delete')}>
              <Button type="text" danger onClick={() => handleDeleteRow(record.id)} icon={<MdDelete />} />
            </Tooltip>
          </BaseSpace>
        );
      },
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>({});

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalOk = () => {
    setModalVisible(false);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const toggleColumn = (columnKey: string) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey], // Toggle the visibility
    }));
  };

  const [modalVisible, setModalVisible] = useState(false);
  const searchRef = useRef<InputRef>(null);

  return (
    <BaseCard id="basic-table" title={'Customers'} padding="1.25rem"
    extra={
      <BaseButtonsForm
      form={form}
      layout="inline"
      isFieldsChanged={fieldsChanged}
      setFieldsChanged={setFieldsChanged}
    >
      <BaseButtonsForm.Item name={'search'}>
        <Input
          size="middle"
          suffix={
            <Button
              icon={<SearchOutlined />}
              size="middle"
              type="text"
              onClick={() => {
                fetch(initialPagination);
              }}
            />
          }
          placeholder={'Search Customers'}
        />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item>
        <Button
          type="ghost"
          size="middle"
          icon={<ReloadOutlined />}
          onClick={() => {
            form.resetFields(['search']);
            fetch(initialPagination);
          }}
        />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item>
          <CSVLink data={csvData} filename={'customer_data.csv'}>
          <BaseButton icon={<DownloadOutlined />} type="default" onClick={handleDownload}/>
          </CSVLink>
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item>
        <BaseButton icon={<EllipsisOutlined />} type="default" onClick={showModal}></BaseButton>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
    }>
 
      <BaseTable
        columns={columns.filter((column) => visibleColumns[column.key as string] !== false)}
        dataSource={tableData.data}
        pagination={{
          current: tableData.pagination.current,
          pageSize: tableData.pagination.pageSize,
          total: tableData.pagination.total,
          showTotal,
        }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: true, scrollToFirstRowOnChange: true }}
      />

      <Modal title="Columns" visible={modalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        {columns.map((column) => (
          <div key={column.key}>
            <Checkbox
              checked={(visibleColumns[column.key as string] as any) !== false}
              onChange={() => toggleColumn(column.key as string)}
            >
              {(column as any).title || (column as any).dataIndex}
            </Checkbox>
          </div>
        ))}
      </Modal>
    
    </BaseCard>
    
  );
};

export default CustomerTable;
