import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { BaseButton } from "@app/components/common/BaseButton/BaseButton";
import { BaseCard } from "@app/components/common/BaseCard/BaseCard";
import { PageTitle } from "@app/components/common/PageTitle/PageTitle";
import { AppointmentsTable } from "@app/components/tables/BasicTable/AppointmentsTable";
import { Space, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const AppointmentsTablePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>('');
  
    const handlebtnClick = () => {
      navigate('/add-appointment');
    };
  
    const handleSearch = (value: string) => {
      // Update the searchText state when the user performs a search
      setSearchText(value);
    };
  
    return (
      <>
      <PageTitle>{t('Appointments')}</PageTitle>
        <Space style={{ marginBottom: '16px', justifyContent: 'space-between', width: '100%' }}>
          <BaseButton icon={<PlusOutlined />} type="primary" onClick={handlebtnClick}>
            {t('Add New Appointment')}
          </BaseButton>
          {/* <Input
            prefix={<SearchOutlined />}
            placeholder={t('Search Appointments')}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          /> */}
        </Space>
        
          <AppointmentsTable />
       
      </>
    );
  };
  
  export default AppointmentsTablePage;