import React from 'react';
import { EventTable } from '@app/components/tables/BasicTable/EventTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {TypeOfEvent} from '@app/api/event.api';
import { Card, Space } from 'antd';

const EventsTablePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handlebtnClick = () => {
    navigate('/add-event');
  };

  return (
    <>
      {/* <PageTitle>{t('Events')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <Space style={{ marginBottom: '16px', justifyContent: 'space-between', width: '100%' }}>
        <BaseButton icon={<PlusOutlined />} type="primary" onClick={handlebtnClick}>
          {t('Add Event')}
        </BaseButton>
      </Space>

      
        <EventTable eventType={TypeOfEvent.EVENT}/>
      
    </>
  );
};

export default EventsTablePage;
