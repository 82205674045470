import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryForm } from '@app/components/forms/CategoryForm/CategoryForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const AddCategoryPage: React.FC = () => {
  const { t } = useTranslation();
  

  return (
    <>
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <CategoryForm type={"create"} editable={true}  /> 
      
    </>
  );
};

export default AddCategoryPage;
