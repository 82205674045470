
import React from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import StockTable from '@app/components/tables/BasicTable/ReportTable/StockTable';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

const StockPage: React.FC = () => {
  return (
    <>
      <PageTitle>Stock</PageTitle>
      <StockTable searchText="" />
    </>
  );
};

export default StockPage;