import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventForm } from '@app/components/forms/EventForm/EventForm'; 

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import {TypeOfEvent} from '@app/api/event.api';


interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const ViewEventPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();



  return (
    <>
        {/* <PageTitle>{t('View-Event')}</PageTitle> */}
        <PageTitle>{"JRDC-Admin"}</PageTitle>
        <EventForm type={"view"} id={id} editable={false} eventType={TypeOfEvent.CONFERENCE}/>
    </>
  );
};

export default ViewEventPage;
