import React, { useEffect, useState } from 'react';
import { Tabs, DatePicker } from 'antd';
import CustomerChart from '@app/components/tables/BasicTable/ReportTable/CustomerChart';
import { CSVLink } from 'react-csv';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ReportDetails, getCustomReportData, getReport } from '@app/api/report.api';
import { notificationController } from '@app/controllers/notificationController';

const { TabPane } = Tabs;

const CustomerChartPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('years');
  const [yearsData, setYearsData] = useState<ReportDetails | null>(null);
  const [yearData, setYearData] = useState<ReportDetails | null>(null);
  const [lastMonthData, setLastMonthData] = useState<ReportDetails | null>(null);
  const [thisMonthData, setThisMonthData] = useState<ReportDetails | null>(null);
  const [lastSevenDaysData, setLastSevenDaysData] = useState<ReportDetails | null>(null);
  const [customData, setCustomData] = useState<ReportDetails | null>(null);

  const fetchData = () => {
    getReport('customers')
      .then((response) => {
        const data = response ?? [];
        setYearsData(data?.find((e) => e.period === 'years') ?? null);
        setYearData(data?.find((e) => e.period === 'year') ?? null);
        setLastMonthData(data?.find((e) => e.period === 'last-month') ?? null);
        setThisMonthData(data?.find((e) => e.period === 'this-month') ?? null);
        setLastSevenDaysData(data?.find((e) => e.period === 'last-7-days') ?? null);
        setCustomData(data?.find((e) => e.period === 'custom') ?? null);
      })
      .catch((e) => notificationController.error({ message: 'Failed to fetch data!' }));
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const handleCustomDateChange = (dates:any, dateStrings: [string, string]) => {
    setActiveTab('custom');
    getCustomReportData(dateStrings,'customers')
    .then(response => setCustomData(response))
    .catch(e=>notificationController.error({message:'Failed to fetch custom data!'}))
  };

  const prepareCSVData = (period: string) => {
    if (period === 'years') {
      return yearsData?.stats;
    } else if (period === 'year') {
      return yearData?.stats ?? []
    } else if (period === 'lastMonth') {
      return lastMonthData?.stats ?? []
    } else if (period === 'thisMonth') {
      return thisMonthData?.stats ?? []
    } else if (period === 'last7Days') {
      return lastSevenDaysData?.stats ?? []
    } else if (period === 'custom') {
      return customData?.stats ?? []
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BaseCard id="basic-table" title="Customers" padding="1.25rem">
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Years" key="years">
          <CustomerChart period="years"
          xAxisData={yearsData?.stats?.map(e => { return e.x_axis; }) ?? []}
          customerOrdersData={yearsData?.stats?.map(e => { return e.no_customer_orders ?? 0; }) ?? []}
          guestOrdersData={yearsData?.stats?.map(e => { return e.no_guest_orders ?? 0; }) ?? []} 
          totalGuestOrders={yearsData?.summary_stats?.no_guest_orders ?? 0} 
          totalCustomerOrders={yearsData?.summary_stats?.no_customer_orders ?? 1} />
          <CSVLink data={prepareCSVData('years') ?? []} filename="customer_data_years.csv">
            <BaseButton>Download CSV</BaseButton>
          </CSVLink>
        </TabPane>
        <TabPane tab="Year" key="year">
          <CustomerChart period="year"
          xAxisData={yearData?.stats?.map(e => { return e.x_axis; }) ?? []}
          customerOrdersData={yearData?.stats?.map(e => { return e.no_customer_orders ?? 0; }) ?? []}
          guestOrdersData={yearData?.stats?.map(e => { return e.no_guest_orders ?? 0; }) ?? []} 
          totalGuestOrders={yearData?.summary_stats?.no_guest_orders ?? 0} 
          totalCustomerOrders={yearData?.summary_stats?.no_customer_orders ?? 1} />
          <CSVLink data={prepareCSVData('year')?? []} filename="customer_data_year.csv">
            <BaseButton>Download CSV</BaseButton>
          </CSVLink>
        </TabPane>
        <TabPane tab="Last Month" key="lastMonth">
          <CustomerChart period="lastMonth"
          xAxisData={lastMonthData?.stats?.map(e => { return e.x_axis; }) ?? []}
          customerOrdersData={lastMonthData?.stats?.map(e => { return e.no_customer_orders ?? 0; }) ?? []}
          guestOrdersData={lastMonthData?.stats?.map(e => { return e.no_guest_orders ?? 0; }) ?? []}
          totalGuestOrders={lastMonthData?.summary_stats?.no_guest_orders ?? 0} 
          totalCustomerOrders={lastMonthData?.summary_stats?.no_customer_orders ?? 1} />
          <CSVLink data={prepareCSVData('lastMonth')?? []} filename="customer_data_lastMonth.csv">
            <BaseButton>Download CSV</BaseButton>
          </CSVLink>
        </TabPane>
        <TabPane tab="This Month" key="thisMonth">
          <CustomerChart period="thisMonth"
          xAxisData={thisMonthData?.stats?.map(e => { return e.x_axis; }) ?? []}
          customerOrdersData={thisMonthData?.stats?.map(e => { return e.no_customer_orders ?? 0; }) ?? []}
          guestOrdersData={thisMonthData?.stats?.map(e => { return e.no_guest_orders ?? 0; }) ?? []} 
          totalGuestOrders={thisMonthData?.summary_stats?.no_guest_orders ?? 0} 
          totalCustomerOrders={thisMonthData?.summary_stats?.no_customer_orders ?? 1} />
          <CSVLink data={prepareCSVData('thisMonth')?? []} filename="customer_data_thisMonth.csv">
            <BaseButton>Download CSV</BaseButton>
          </CSVLink>
        </TabPane>
        <TabPane tab="Last 7 Days" key="last7Days">
          <CustomerChart period="last7Days"
          xAxisData={lastSevenDaysData?.stats?.map(e => { return e.x_axis; }) ?? []}
          customerOrdersData={lastSevenDaysData?.stats?.map(e => { return e.no_customer_orders ?? 0; }) ?? []}
          guestOrdersData={lastSevenDaysData?.stats?.map(e => { return e.no_guest_orders ?? 0; }) ?? []} 
          totalGuestOrders={lastSevenDaysData?.summary_stats?.no_guest_orders ?? 0} 
          totalCustomerOrders={lastSevenDaysData?.summary_stats?.no_customer_orders ?? 1} />
          <CSVLink data={prepareCSVData('last7Days')?? []} filename="customer_data_last7Days.csv">
            <BaseButton>Download CSV</BaseButton>
          </CSVLink>
        </TabPane>
        <TabPane tab="Custom:" key="custom">
          <div style={{ position: 'relative' , marginBottom:'20px'}}>
            <DatePicker.RangePicker
             onChange={handleCustomDateChange}
            />
          </div>
          <CustomerChart period="custom" 
          xAxisData={customData?.stats?.map(e => { return e.x_axis; }) ?? []}
          customerOrdersData={customData?.stats?.map(e => { return e.no_customer_orders ?? 0; }) ?? []}
          guestOrdersData={customData?.stats?.map(e => { return e.no_guest_orders ?? 0; }) ?? []} 
          totalGuestOrders={customData?.summary_stats?.no_guest_orders ?? 0} 
          totalCustomerOrders={customData?.summary_stats?.no_customer_orders ?? 1} />
          <CSVLink data={prepareCSVData('custom')?? []} filename="customer_data_custom.csv">
            <BaseButton>Download CSV</BaseButton>
          </CSVLink>
        </TabPane>
      </Tabs>
    </BaseCard>
  );
};

export default CustomerChartPage;
