import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import CustomerForm from '@app/components/forms/CustomerForm/CustomerForm';

const CustomerPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('Customers')}</PageTitle>
      <CustomerForm editable={true} type={'create'} />
    </>
  );
};

export default CustomerPage;
