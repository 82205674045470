// Token management service
class TokenService {
  private static instance: TokenService;
  private token: string | null;

  private constructor() {
    // Retrieve the token from local storage during initialization
    this.token = localStorage.getItem('access_token');
  }

  public static getInstance(): TokenService {
    if (!this.instance) {
      this.instance = new TokenService();
    }
    return this.instance;
  }

  public setToken(token: string): void {
    // Set the token in local storage
    localStorage.setItem('access_token', token);
    this.token = token;
;
  }

  public getToken(): string | null {
    return this.token;
  }

  public deleteToken(): void {
    localStorage.removeItem('access_token');
    this.token = null;
  } 
}


// Export the TokenService instance
export const tokenService = TokenService.getInstance();