import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { InputRef, Space, Tooltip } from 'antd';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';

import {
  showCategory,
  editCategory,
  createCategory,
  storeCategory,
  updateCategory,
  CategoryRequest,
  CategoryOption,
  StatusOption,
  DisplayTypeOption,
  CategoryApiData,
  Category,
} from 'api/category.api';
import { Input } from 'antd';
import { useMounted } from '@app/hooks/useMounted';

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface CategoryFormProps extends TypeOfForm {
  editable: boolean;
  id?: string;
}

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const dummyDisplayTypes = [
  { value: 'type1', label: 'Type 1' },
  { value: 'type2', label: 'Type 2' },
];

export const CategoryForm: React.FC<CategoryFormProps> = ({ type, id, editable = false }) => {
  const categoryId = id ? parseInt(id) : null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const [categoryName, setCategoryName] = useState<string>('');
  const [pageTitle, setPageTitle] = useState<string>('');
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<StatusOption[]>([]);
  const [displayTypeOptions, setDisplayTypeOptions] = useState<DisplayTypeOption[]>([]);
  const { isMounted } = useMounted();
  const [category, setCategory] = useState<Category | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [slug, setSlug] = useState<string>('');
  const nameInputRef = useRef<InputRef>(null);

  const handleErrorMessages = useApiErrorHandler();

  const handleSubmit = async (values: CategoryRequest) => {
    setLoading(true);
    values.image = selectedImage;
    console.log(values);

    if (editable) {
      if (type === 'create') {
        storeCategory(values)
          .then((res: any) => {
            setLoading(false);
            console.log(res);
            navigate(`/show-category/${res?.data?.id}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      } else if (type === 'edit' && categoryId) {
        console.log(categoryId, values);
        updateCategory(categoryId, values)
          .then(() => {
            setLoading(false);
            navigate(`/show-category/${categoryId}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      }
    }
  };
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNameChange = (e: any) => {
    const a = nameInputRef?.current?.input?.value ?? '';
    const b = a.replace(/\s+/g, ' ').trim().replaceAll(' ', '_').toLowerCase();
    form.setFieldValue('slug', b);
  };

  const setApiData = (res: CategoryApiData) => {
    console.log('fsdfsf', res);
    setCategory(res?.category);
    setCategoryOptions(res?.category_options);
    setStatusOptions(res?.status_options);
    setDisplayTypeOptions(res?.display_type_options);
    setImageUrl(res?.category?.image_url);

    form.setFieldsValue({
      name: res?.category?.name,
      slug: res?.category?.slug,
      description: res?.category?.description,
      parent_id: res?.category?.parent_id,
      display_type: res?.category?.display_type,
      status: res?.category?.status,
    });
  };

  useEffect(() => {
    setLoading(true);
    if (categoryId) {
      if (type === 'view') {
        showCategory(categoryId)
          .then((res: any) => {
            setPageTitle(`${res?.category?.name}`);
            setApiData(res);
            setLoading(false);
          })
          .catch((error) => handleErrorMessages(error));
      } else if (type === 'edit') {
        editCategory(categoryId)
          .then((res: any) => {
            setLoading(false);
            setPageTitle(`${res?.category?.name}`);
            setApiData(res);
          })
          .catch((error) => handleErrorMessages(error));
      }
    } else {
      createCategory()
        .then((res: any) => {
          setLoading(false);
          console.log('fddddsdfdf', res);
          setPageTitle(`New`);
          setApiData(res);
        })
        .catch((error) => handleErrorMessages(error));
    }
  }, []); // The empty dependency array means this effect will only run once, equivalent to componentDidMount

  return (
    <BaseCard
      id="validation form"
      // title={t('Add Product Category')}
      title={`Product Category - ` + pageTitle || 'New'}
      padding="1.25rem"
      style={{ margin: '80px', marginTop: '0px' }}
      extra={
        <>
          {(type === 'view' && (
            <>
              <Space>
                <Tooltip title={t('common.edit')}>
                  <BaseButton type="text" onClick={() => navigate(`/edit-category/${id}`)} icon={<MdModeEdit />} />
                </Tooltip>
              </Space>
            </>
          )) ||
            (type === 'edit' && (
              <>
                <Space>
                  <Tooltip title={t('view')}>
                    <BaseButton type="text" onClick={() => navigate(`/Show-category/${id}`)} icon={<IoEye />} />
                  </Tooltip>
                </Space>
              </>
            ))}
          <Space>
            <Tooltip title={'View all'}>
              <BaseButton type="text" onClick={() => navigate('/product-categories')} icon={<TableOutlined />} />
            </Tooltip>
          </Space>
        </>
      }
    >
      <BaseButtonsForm
        {...formItemLayout}
        name="productCategoriesForm"
        onFinish={handleSubmit}
        form={form}
        loading={isLoading}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item
              name="name"
              label="Name"
              key="name"
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <BaseInput
                ref={nameInputRef}
                placeholder="Enter category name"
                disabled={!editable}
                onChange={handleNameChange}
              />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="slug" label="Slug" rules={[{ required: true, message: 'Slug is required' }]}>
              <BaseInput placeholder="Enter slug" disabled={!editable} value={slug} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="parent_id" label="Parent Category">
              <BaseSelect
                showSearch
                filterOption={(input, option) => option?.key?.toLowerCase()?.includes(input?.toLowerCase())}
                placeholder="Select parent category"
                disabled={!editable}
              >
                <Option key={null} value={null}>
                  {'--None--'}
                </Option>
                {categoryOptions?.map((option) => (
                  <Option key={option.category_chain} value={option.id}>
                    {option.category_chain}
                  </Option>
                ))}
              </BaseSelect>
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="description" label="Description">
              <TextArea rows={4} placeholder="Enter category description" disabled={!editable} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="display_type" label="Display Type">
              <BaseSelect placeholder="Select display type" disabled={!editable}>
                {Array.isArray(displayTypeOptions) &&
                  displayTypeOptions.map((option) => (
                    <Option key={option.name} value={option.key}>
                      {option.name}
                    </Option>
                  ))}
              </BaseSelect>
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="status" label="Status">
              <BaseSelect placeholder="Select status" disabled={!editable}>
                {Array.isArray(statusOptions) &&
                  statusOptions.map((option) => (
                    <Option key={option.name} value={option.key}>
                      {option.name}
                    </Option>
                  ))}
              </BaseSelect>
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="image" label="Thumbnail">
              <input type="file" accept="image/*" onChange={handleFileChange} hidden={!editable} />
              <br hidden={!editable} />
              {selectedImage && <img src={selectedImage} alt="thumbnail" style={{ width: '100px', height: '100px' }} />}

              <BaseImage src={imageUrl} style={{ width: '100px', height: '100px' }} hidden={!!selectedImage}>
                {/* {imageUrl && <img src={imageUrl} alt="thumbnail" style={{ width: '100px', height: '100px' }} />} */}
              </BaseImage>
            </BaseButtonsForm.Item>
          </BaseCol>
          <p style={{ fontStyle: 'italic', fontSize: '14px' }}>
            *Please make sure to upload an image with 450 x 450 dimensions
          </p>
        </BaseRow>

        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            Save
          </BaseButton>
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </BaseCard>
  );
};

export default CategoryForm;
