import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useNavigate } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';
import { Input, Tooltip, InputRef, Space, Select } from 'antd';
import {
  PostCategoryRequest,
  storePostCategory,
  showPostCategory,
  editPostCategory,
  createPostCategory,
  updatePostCategory,
  PostCategoryApiData,
  PostCategory,
  StatusOption,
} from '@app/api/postCategories.api';
import { IoEye } from 'react-icons/io5';
import { TableOutlined } from '@ant-design/icons';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface PostCategoryFormProps extends TypeOfForm {
  editable: boolean;
  id?: string;
}
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const PostCategoryForm: React.FC<PostCategoryFormProps> = ({ type, id, editable = false }) => {
  const postCategoryId = id ? parseInt(id) : null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [postCategory, setPostCategory] = useState<PostCategory>();
  const [slug, setSlug] = useState<string>('');
  const nameInputRef = useRef<InputRef>(null);
  const [pageTitle, setPageTitle] = useState<string>('');
  const handleErrorMessages = useApiErrorHandler();
  const [statusOptions, setStatusOptions] = useState<StatusOption[]>([]);

  const handleNameChange = (e: any) => {
    const a = nameInputRef?.current?.input?.value ?? '';
    const b = a.replace(/\s+/g, ' ').trim().replaceAll(' ', '_').toLowerCase();
    form.setFieldValue('slug', b);
  };

  const handleSubmit = async (values: PostCategoryRequest) => {
    setLoading(true);
    console.log(values);
    if (editable) {
      if (type === 'create') {
        storePostCategory(values)
          .then((res: any) => {
            setLoading(false);
            console.log(res);
            navigate(`/show-post-category/${res?.data?.data?.id}`);
          })
          .catch((err: any) => {
            // notificationController.error({ message: err.message });
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      } else if (type === 'edit' && postCategoryId) {
        console.log(postCategoryId, values);
        updatePostCategory(postCategoryId, values)
          .then(() => {
            setLoading(false);
            navigate(`/show-post-category/${postCategoryId}`);
          })
          .catch((err: any) => {
            // notificationController.error({ message: err.message });
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      }
    }
  };

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();

  const handleEditIconClick = () => {
    navigate(`/edit-post-category/${id}`);
  };

  const setApiData = (res: PostCategoryApiData) => {
    console.log(res);
    setPostCategory(res?.postCategory);
    setStatusOptions(res?.status_options);
    form.setFieldsValue({
      name: res?.postCategory?.name,
      slug: res?.postCategory?.slug,
      description: res?.postCategory?.description,
      status: res?.postCategory?.status,
    });
  };

  useEffect(() => {
    // Function to fetch data from the API
    setLoading(true);
    if (postCategoryId) {
      if (type === 'view') {
        showPostCategory(postCategoryId)
          .then((res: any) => {
            setPageTitle(`${res?.postCategory?.name}`);
            setApiData(res);
            setLoading(false);
            // setPostCategoryName(res?.postCategory?.name || '');
          })
          .catch((error) => handleErrorMessages(error));
      } else if (type === 'edit') {
        editPostCategory(postCategoryId)
          .then((res: any) => {
            setLoading(false);
            setPageTitle(`${res?.postCategory?.name}`);
            setApiData(res);
          })
          .catch((error) => handleErrorMessages(error));
      }
    } else {
      createPostCategory()
        .then((res: any) => {
          setLoading(false);
          setPageTitle(`New`);
          setApiData(res);
          // setPostCategoryName(res?.postCategory?.name || '');
        })
        .catch((error) => handleErrorMessages(error));
    }
  }, []); // The empty dependency array means this effect will only run once, equivalent to componentDidMount

  return (
    <BaseCard
      id="validation form"
      // title={pageTitle}
      title={`Post Category - ` + pageTitle || 'New'}
      padding="1.25rem"
      style={{ margin: '80px', marginTop: '0px' }}
      extra={
        <>
          {(type === 'view' && (
            <>
              <Space>
                <Tooltip title={t('common.edit')}>
                  <BaseButton type="text" onClick={() => navigate(`/edit-post-category/${id}`)} icon={<MdModeEdit />} />
                </Tooltip>
              </Space>
            </>
          )) ||
            (type === 'edit' && (
              <>
                <Space>
                  <Tooltip title={t('view')}>
                    <BaseButton type="text" onClick={() => navigate(`/show-post-category/${id}`)} icon={<IoEye />} />
                  </Tooltip>
                </Space>
              </>
            ))}
          <Space>
            <Tooltip title={'View all'}>
              <BaseButton type="text" onClick={() => navigate('/post-categories')} icon={<TableOutlined />} />
            </Tooltip>
          </Space>
        </>
      }
    >
      {form.isFieldTouched('type')}
      <BaseButtonsForm
        {...formItemLayout}
        name="postCategoryForm"
        onFinish={handleSubmit}
        form={form}
        loading={isLoading}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
        {/* {type === 'view' && showEditIcon && (
          <Tooltip title={t('common.edit')}>
            <MdModeEdit onClick={handleEditIconClick} />
          </Tooltip>
        )} */}

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
              <BaseInput
                ref={nameInputRef}
                placeholder="Enter post category name"
                disabled={!editable}
                onChange={handleNameChange}
              />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item
              name="slug"
              label="Slug"
              key="slug"
              rules={[{ required: true, message: 'Slug is required' }]}
            >
              <BaseInput placeholder="Enter slug" disabled={!editable} value={slug} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="description" label="Description">
              <TextArea rows={4} placeholder="Enter post category description" disabled={!editable} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="status" label="Status">
              <Select placeholder="Select status" disabled={!editable} options={statusOptions} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            Save Post Category
          </BaseButton>
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </BaseCard>
  );
};

export default PostCategoryForm;
