// ZoomLinks.tsx
import { MeetingTableRow } from '@app/api/meeting.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';
import Option from 'antd/lib/select';


interface ZoomLinksProps {
  allZoomLinks: any[]; 
  type: string;
  editable: boolean;
  form: any; 
  selectedZoomLinks: any[]; 
  setSelectedZoomLinks: React.Dispatch<React.SetStateAction<any[]>>;
}

const ZoomLinks: React.FC<ZoomLinksProps> = ({
  allZoomLinks,
  type,
  editable,
  form,
  selectedZoomLinks,
  setSelectedZoomLinks,
}) => {
    const columns: ColumnsType<MeetingTableRow> = [
        {
          title: 'Meeting ID',
          dataIndex: 'meeting_id',
          key: 'meeting_id',
        },
        {
          title: 'Topic',
          dataIndex: 'topic',
          key: 'topic',
        },
        {
          title: 'Date & Time',
          dataIndex: 'start_time',
          render(value, record, index) {
            return <div>{dayjs(record.start_time).format('MMMM D, YYYY  HH:mm a')}</div>;
          },
        },
        {
          title: 'Duration',
          dataIndex: 'duration',
          key: 'duration',
          render: (value, record, index) => `${record.duration} minutes`,
        },
    
        {
          title: 'Password',
          dataIndex: 'password',
          key: 'password',
        },
        {
          title: 'Join URL',
          dataIndex: 'join_url',
          key: 'join_url',
        },
      ];

  return (
    <BaseRow gutter={[16, 16]}>
      {/* Render Zoom link related UI */}
      <BaseCol xs={24} md={8} lg={12}>
        <BaseButtonsForm.Item name="meetings" label="Zoom Links" hidden={!editable}>
          <BaseSelect
            placeholder="Select links"
            disabled={!editable}
            mode="tags"
            allowClear
            onChange={(option) => {
              const values = allZoomLinks?.filter((e) => form.getFieldValue('meetings').includes(e.id));
              setSelectedZoomLinks(values);
            }}
          >
            {allZoomLinks?.map((option) => (
              <Option key={option.id} value={option.id}>
                {`[ ${option.meeting_id} ] ${option.topic}`}
              </Option>
            ))}
          </BaseSelect>
        </BaseButtonsForm.Item>
      </BaseCol>

      <BaseCol span={24}>
        <BaseTable
          bordered
          columns={columns}
          dataSource={allZoomLinks?.filter((e) => form.getFieldValue('meetings').includes(e.id))}
          pagination={false}
          scroll={{ x: true, scrollToFirstRowOnChange: true }}
        />
      </BaseCol>
    </BaseRow>
  );
};

export default ZoomLinks;
