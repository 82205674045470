import { PageTitle } from "@app/components/common/PageTitle/PageTitle";
import { AppointmentForm } from "@app/components/forms/AppointmentForm/AppointmentForm";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import React from 'react'

interface RouteParams {
    id: string;
    [key: string]: string | undefined;
  }
  
  const ViewAppointmentPage: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<RouteParams>();
  
    return (
      <>
        {/* <PageTitle>{t('View-appointment')}</PageTitle> */}
        <PageTitle>{"JRDC-Admin"}</PageTitle>
        <AppointmentForm type={"view"} id={id} editable = {false}/>
      </>
    );
  };
  
  export default ViewAppointmentPage;