import { get, post } from '@app/api/http.api';

export interface OrderRequest {
  status: number;
  customer_id: number;
  bill_first_name: string;
  bill_last_name: string;
  ref_no: null | string;
  ip: null | string;
  bill_company: string;
  bill_address_line1: string;
  bill_address_line2: string;
  bill_city: string;
  bill_postcode: string;
  bill_country: string;
  bill_state: string;
  bill_email: string;
  bill_phone_no: string;
  bill_payment_method: string;
  bill_transaction_id: string;
  total: null | number;
  items_total: null | number;
  total_items_qty: null | number;
  order_lines: any[];
  direct_transfer: DirectTransferData | null;
  order_notes: OrderLog[] | [];
  additional_note: string | null;
}

interface RefundRequest {
  refund_reason: string;
}


export interface Product {
  id: number;
  name: string;
  sale_price: null | number;
  type: string;
  is_appointable: boolean;
}

export interface OrderInvoice {
  id: number;
  name: string;
  invoice_url: string;
}

export interface Order {
  id: number;
  status: number;
  customer_id: number;
  bill_first_name: string;
  bill_last_name: string;
  ref_no: null | string;
  ip: null | string;
  bill_company: null | string;
  bill_address_line1: null | string;
  bill_address_line2: null | string;
  bill_city: null | string;
  bill_postcode: null | string;
  bill_country: null | string;
  bill_state: null | string;
  bill_email: null | string;
  bill_phone_no: null | string;
  bill_payment_method: null | string;
  bill_transaction_id: null | string;
  total: null | number;
  items_total: null | number;
  created_at: null | string;
  updated_at: null | string;
  direct_transfer: DirectTransfer;
  order_lines: OrderLineApi[] | [];
  invoice: OrderInvoice | null;
  order_notes: OrderLog[] | [];
  is_cancelled_order ?: boolean;
  additional_note: string | null;
  can_payment_verify: boolean;
}

export interface BillingAddress {
  bill_first_name: string;
  bill_last_name: string;
  bill_company: string;
  bill_address_line1: string;
  bill_address_line2: string;
  bill_city: string;
  bill_postcode: string;
  bill_country: string;
  bill_state: string;
  bill_email: string;
  bill_phone_no: string;
}



export interface OrderTableRow {
  bill_first_name: any;
  bill_last_name: any;
  id: number;
  orderedBy: string;
  created_at: string;
  status: string;
  total: string;
  order_products: string[];
  ip: string;
  bill_payment_method: string;
  taxTotal: string;
  order_invoice: number[];
  order_receipt_approval: any;
  order_status: {name:string,color:string}

}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface DirectTransferData {
  image: string | null;
  status: string;
  note: string;
}

export interface ProductOption {
  id?: number;
  name?: string;
  sale_price: number;
  type?: string;
  is_appointable: boolean;
  quantity?: number
}

export interface StaffOption {
  key?: string;
  name?: string;
}

export interface CustomerStatusOption {
  key?: string;
  name?: string;
}

export interface AppointmentStatusOption {
  key?: string;
  name?: string;
  disabled?: boolean;
}

export interface ReceiptStatusOption {
  key?: string;
  name?: string;
}

export interface OrderStatusOption {
  key?: string;
  name?: string;
  disabled?: boolean;
  color?:string
}

export interface OrderRelatedCustomerData {
  bill_first_name?: string;
  bill_last_name?: string;
  bill_company?: string;
  bill_address_line1?: string;
  bill_address_line2?: string;
  bill_city?: string;
  bill_postcode?: string;
  bill_country?: string;
  bill_state?: string;
  bill_email?: string;
  bill_phone_no?: string;
}

export interface Appointment {
  id: number | null;
  customer_status?: string;
  status_name?: string;
  status?: string;
  staff?: string;
  all_day?: boolean;
  customer_timezone?: string;
  start_date_time?: string;
  end_date_time?: string;
}

export interface OrderLine {
  id: number | null;
  unit_price: number;
  status: string;
  qty: number;
  total: number;
  product_id: number;
  appointment: Appointment | null;
  action: '1' | '2' | '3' | '4';
}
interface Paper {
  id?: number;
  name: string;
  abstract: string;
  file_url: string;
  price: string;
}

export interface OrderLineAction {
  id: number;
  action: '1' | '2' | '3' | '4';
}

// 'create','edit' , 'no change','delete',

export interface OrderLineApi {
  id: number;
  unit_price: string;
  status: string;
  qty: string;
  total: string;
  product: Product;
  appointment: Appointment | null;
  attachment: Paper | null;
}

export interface DirectTransfer {
  order_id?: number;
  image?: string;
  status?: string;
  note?: string;
  image_url?: string;
  created_at: string;
  updated_at: string;
}

export interface OrderApiData {
  receipt_status_options: ReceiptStatusOption[];
  order_status_options: OrderStatusOption[];
  customer_options: CustomerOption[];
  order: Order;
  products: Product[];
  customer_status_options: CustomerStatusOption[];
  appointment_status_options: AppointmentStatusOption[];
  staff_options: StaffOption[];
  wf_abilities?: WfAbilities;
  edit_abilities?: EditAbilities;
  configs?: OrderConfigs;
}

export interface OrderConfigs {
  detault_order_status: "1"|"2"|"3"|"4";
  default_appointment_status: "1"|"2"|"3";
  default_payment_method: "pre";
  confirmed_appointment_status: "1"|"2"|"3"|"4"|"5";
  rejection_statuses:string[];
  confirmation_statuses:string[];
  ol_total_reject_statuses: string[];
}
export interface WfAbilities {
  cancelled: boolean;
  refund: boolean;
  refunded: boolean;
}

export interface EditAbilities {
  order: boolean;
  receipt: boolean;
}
export interface CustomerOption {
  id: number;
  name: string;
}

export interface OrderLog {
  id?: number | null;
  order_id?: number;
  note: string;
  user?: string;
  note_for: string;
  created_at?: string;
  updated_at?: string;
}

export interface UnitPrice {
  price: number;
}

export interface DateFormat {
  start_date: string | null;
  end_date: string | null;
  start_time: string | null;
  end_time: string | null;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
  status?:string | null;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

export interface OrderTableData{
  orders:PaginatedResponse<Order>,
  status_options: OrderStatusOption[]
}



export const getOrderTableData = async (
  parameters:Parameters,
): Promise<{ orders: {data: OrderTableRow[]|any; pagination: Pagination},  status_options: OrderStatusOption[] }> => {
  try {
    const { data, status } = await get<OrderTableData>('orders', { params: parameters }, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return {
       orders:{
        data:data.orders.data, 
        pagination: {
          current: data.orders.current_page,
          pageSize: data.orders.per_page,
          total: data.orders.total,
        }
      } ,
      status_options:data.status_options
     };
  } catch (error: any) {
    console.error('Error fetching order table data:', error);
    // throw new Error('Failed to fetch order table data');
    throw error;
  }
};

export const calculateUnitPrice = async (
  productId: number,
  start_date_time: string | null,
  end_date_time: string | null,
): Promise<UnitPrice> => {
  try {
    const { data, status } = await get<UnitPrice>(
      `/orders/products/${productId}/calculate_price?start_date_time=${start_date_time}&end_date_time=${end_date_time}`,
      undefined,
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error('Failed to fetch orders');
    throw error;
  }
};

export const getOrders = async (): Promise<OrderTableRow[]> => {
  try {
    const { data, status } = await get<OrderTableRow[]>('orders', undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error('Failed to fetch orders');
    throw error;
  }
};

export const getOrderRelatedFromCustomer = async (customer_id: number): Promise<OrderRelatedCustomerData[]> => {
  try {
    const { data, status } = await get<OrderRelatedCustomerData[]>(`order_customer/${customer_id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error('Failed to fetch orders');
  }
};

export const storeOrder = async (storeOrderData: OrderRequest) => {
  try {
    const { data, status } = await post<OrderRequest>(`orders/create`, storeOrderData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const updateOrder = async (id: number, UpdateOrderData: OrderRequest) => {
  try {
    const { data, status } = await post<OrderRequest>(`orders/${id}/edit`, UpdateOrderData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const completedOrder = async (id: number) => {
  try {
    const { data, status } = await post<any>(`orders/${id}/completed`,[] , undefined, true);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const cancelledOrder = async (id: number) => {
  try {
    const { data, status } = await post<any>(`orders/${id}/cancelled`,[] , undefined, true);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const refundOrder = async (id: number, RefundData: RefundRequest) => {
  try {
    const { data, status } = await post<OrderRequest>(`orders/${id}/refund`, RefundData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const refundedOrder = async (id: number) => {
  try {
    const { data, status } = await post<any>(`orders/${id}/refunded`,[] , undefined, true);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createOrder = async () => {
  try {
    const { data, status } = await get<OrderApiData>('orders/create', undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showOrder = async (id: number) => {
  try {
    const { data, status } = await get<OrderApiData>(`orders/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editOrder = async (id: number) => {
  try {
    const { data, status } = await get<OrderApiData>(`orders/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const varifyPayment = async (id: number) => {
  try {
    const { data, status } = await get<OrderApiData>(`orders/${id}/varifypayment`, undefined, true);
    console.log(`Status: ${status}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const completepayment = async (id: number) => {
  try {
    const { data, status } = await post<any>(`orders/${id}/completepayment`,[] , undefined, true);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};


