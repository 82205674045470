import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { RolePermissionsForm } from '@app/components/forms/RolePermissionsForm/RolePermissionsForm';
import React from 'react'
import { useTranslation } from 'react-i18next';

export const CreateRolePermissionsPage:React.FC = () => {
 
    const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('Add-Role-Permissions')}</PageTitle>
      <RolePermissionsForm type={"create"}  editable = {true} pageTitle={`Add Role & Permissions`}/>
    </>
  );
  
}

