import React, { useCallback, useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import { useResponsive } from '@app/hooks/useResponsive';
import {
  TrendingActivity,
  TrendingCollections,
} from '@app/components/nft-dashboard/trending-collections/TrendingCollections';
import { TotalEarning } from '@app/components/nft-dashboard/totalEarning/TotalEarning';
import { ActivityStory } from '@app/components/nft-dashboard/activityStory/ActivityStory';
import { RecentActivity } from '@app/components/nft-dashboard/recentActivity/RecentActivity';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import MonthlyNetSales from '@app/components/nft-dashboard/monthlyNetSales/MonthlyNetSales';
import AtAGlance from '@app/components/nft-dashboard/atAGlance/AtAGlance';
import { Status } from '@app/components/nft-dashboard/status/Status';
import { get } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';

export interface DashboardData {
  orders_awaiting_for_processing: number;
  orders_on_hold: number;
  new_monthly_appointments: number;
  confirmation_required_appointments: number;
  posts: number;
  pages: number;
  total_monthly_net_sales: {
    monthly_net_sales: {
      x_axis: string;
      net_sales: number;
    }[];
    net_sales: number;
  };
  top_sellers_for_quarter: TrendingActivity[];
  top_seller_for_curent_month: TrendingActivity;
}

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [data, setData] = useState<DashboardData | null>(null);
  const [trendingData, setTrendingData] = useState<TrendingActivity[]>([]);
  const handleTrendingCollectionsData = useCallback((tdata: TrendingActivity[]) => {
    setTrendingData(tdata);
  }, []);

  const fetchData = async () => {
    await get<DashboardData>('dashboard', undefined, true)
      .then((response) => {
        setData(response?.data);
        console.log(response?.data);
        handleTrendingCollectionsData(response?.data?.top_sellers_for_quarter);
      })
      .catch((e) => {
        console.error(e);
        // notificationController.error({message:'Failed to fetch data!'})
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={24}>
            <Status data={data} />
          </BaseCol>
          <BaseCol span={24}>
            <TrendingCollections data={data?.top_sellers_for_quarter ?? []} />
          </BaseCol>

          {/* <BaseCol span={24}>
            <RecentActivity />
          </BaseCol> */}
        </BaseRow>
        <References />
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <MonthlyNetSales data={data} />
        <S.Space />
        <AtAGlance data={data} />
        <S.Space />
      </S.RightSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <MonthlyNetSales data={data} />
      </BaseCol>
      <BaseCol span={24}>
        <AtAGlance data={data} />
      </BaseCol>
      <BaseCol span={24}>
        <Status data={data} />
      </BaseCol>
      <BaseCol span={24}>
        <TrendingCollections data={data?.top_sellers_for_quarter ?? []} />
      </BaseCol>

      {/* <BaseCol span={24}>
        <RecentActivity />
      </BaseCol> */}
    </BaseRow>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;
