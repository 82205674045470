import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { notificationController } from '@app/controllers/notificationController';
import { Space, TimePicker } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';

interface FormField {
  name: string;
  inputType?: string;
  placeholder: string;
  label: string;
  colSpan: number;
  type: 'input' | 'datepicker' | 'timepicker' | 'select';
  dropdownOptions?: { label: any; value: any }[];
}

enum Action {
  CREATE = '1',
  EDIT ='2',
  NO_CHANGE = '3',
  DELETE = '4'
}

interface EditableSelectionTableProps {
  formName: string;
  formFields: FormField[];
  type: 'create' | 'edit' | 'view';
  columns: any;
  orginalItems: any[];
  title: string;
  updateOriginalItems: (value: any) => any;
  showEdit?: boolean;
  loading?:boolean;
}

export const EditableSelectionTable: React.FC<EditableSelectionTableProps> = ({
  formFields,
  formName,
  type,
  columns,
  orginalItems,
  updateOriginalItems,
  title,
  showEdit = true,
  loading,
}) => {
  const [editableForm] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [formFieldNames, setFormFieldNames] = useState<string[]>([]);
  const [currentIndexOnEdit, setCurrentIndexOnEdit] = useState<number | undefined>(undefined);

  const arrangeOrginalItem = (item:any) => {
    switch(item.action){
      case null:
      case undefined:
      case '3' :{
        return {...item, action: '3'}
      } 
      case '2':{
        return {...item, action: '2'}
      }
      case '1':{
        return {...item, action: '1'}
      }
      case '4':{
        return {id:item?.id, action: '4'}
      }
    }
  }

  const convertOriginalItems = (items: any[]) => {
    return items?.map((e) => arrangeOrginalItem(e));
  };

  const [dataSource, setDataSource] = useState<any[]>([]);

  useEffect(() => {
    setFormFieldNames(formFields.map((e) => e.name));
    if (orginalItems) {
      setDataSource(convertOriginalItems(orginalItems));
      updateOriginalItems(convertOriginalItems(orginalItems));
    }
  }, [orginalItems]);

  const handleEditAction = (recordIndex: any) => {
    const existingItem = dataSource.find((_, index) => index === recordIndex);
    editableForm.setFieldsValue(existingItem);
    setEditMode(true);
    setCurrentIndexOnEdit(recordIndex);
  };

  const handleDeleteAction = (recordIndex: any) => {
    const item = dataSource.find((_, index) => index === recordIndex);
    const deleteItem = { id: item?.id, action: Action.DELETE };
    const updatedItems = dataSource.filter((_, index) => index !== recordIndex);
    if (item?.id !== null || item?.id !== undefined) {
      setDataSource([...updatedItems]);
      updateOriginalItems([...updatedItems, deleteItem]);
      console.log([...updatedItems, deleteItem]);
    } else {
      setDataSource([...updatedItems]);
      updateOriginalItems([...updatedItems]);
      console.log([...updatedItems]);
    }
  };

  const actionColumn: ColumnsType<any> = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record, index) => {
        return (
          <BaseSpace>
          {showEdit && (
             <BaseButton
             type="ghost"
             size="small"
             icon={<MdModeEdit />}
             style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
             onClick={() => handleEditAction(index)}
           />
          )}
           
            <BaseButton
              type="ghost"
              size="small"
              danger
              icon={<MdDelete />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
              onClick={() => handleDeleteAction(index)}
            />
          </BaseSpace>
        );
      },
    },
  ];

  const onSave = () => {
    editableForm
      .validateFields(formFieldNames)
      .then(() => {
        if (editMode) {
          const item = dataSource.find((_, index) => index === currentIndexOnEdit);
          const newItem = { id: item?.id, action: item?.id !== undefined ? Action.EDIT : Action.CREATE };
          const arr = editableForm.getFieldsValue(formFieldNames);
          const merged = { ...arr, ...newItem };
          console.log(merged)
          const existingItems = dataSource.filter((_, index) => index !== currentIndexOnEdit);
          setDataSource([...existingItems, merged]);
          updateOriginalItems([...existingItems, merged]);
          setEditMode(false);
        } else {
          const arr = editableForm.getFieldsValue(formFieldNames);
          console.log(arr);
          const newItem = { id: null, action: Action.CREATE};
          const merged = { ...arr, ...newItem };
          setDataSource([...dataSource, merged]);
          updateOriginalItems([...dataSource, merged]);
        }
        console.log(dataSource);
        editableForm.resetFields();
      })
      .catch((error: any) => {
        notificationController.error({ message: 'Enter required fields!' });
      });
    console.log(dataSource);
  };

  return type !== 'view' ? (
    <>
      <BaseTypography.Title level={5}>{title}</BaseTypography.Title>
      <br />
      <BaseButtonsForm
        name={formName}
        form={editableForm}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
        <BaseRow gutter={[16, 16]}>
          {formFields?.map((field, index) => (
            <BaseCol key={index} span={field.colSpan}>
              {field.type === 'input' && (
                <BaseButtonsForm.Item
                  key={index}
                  label={field.label}
                  name={field.name}
                  rules={[{ required: true, message: `${field.label} is required!` }]}
                >
                  <BaseInput placeholder={field.placeholder} type={field.inputType} />
                </BaseButtonsForm.Item>
              )}
              {field.type === 'datepicker' && (
                <BaseButtonsForm.Item
                  key={index}
                  label={field.label}
                  name={field.name}
                  rules={[{ required: true, message: `${field.label} is required!` }]}
                >
                  <BaseDatePicker placeholder={field.placeholder} />
                </BaseButtonsForm.Item>
              )}
              {field.type === 'timepicker' && (
                <BaseButtonsForm.Item
                  key={index}
                  label={field.label}
                  name={field.name}
                  rules={[{ required: true, message: `${field.label} is required!` }]}
                >
                  <TimePicker placeholder={field.placeholder} format="HH:mm a" />
                </BaseButtonsForm.Item>
              )}
              {field.type === 'select' && (
                <BaseButtonsForm.Item
                  key={index}
                  label={field.label}
                  name={field.name}
                  rules={[{ required: true, message: `${field.label} is required!` }]}
                >
                  <BaseSelect placeholder={field.placeholder} options={field.dropdownOptions} />
                </BaseButtonsForm.Item>
              )}
            </BaseCol>
          ))}
        </BaseRow>
        <BaseRow gutter={[16, 16]}>
          <BaseCol span={24}>
            <BaseButton type="ghost" htmlType="button" onClick={() => onSave()}>
              Save
            </BaseButton>
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
      <br />
      <BaseTable
      loading={loading}
        bordered
        dataSource={dataSource.filter((item) => item.action !== Action.DELETE)}
        pagination={false}
        columns={[...columns, ...actionColumn]}
        scroll={{ x: true, scrollToFirstRowOnChange: true }}
      />
    </>
  ) : (
    <>
      <BaseTypography.Title level={5}>{title}</BaseTypography.Title>
      <br />
      <BaseTable
      loading={loading}
      bordered
        dataSource={[...dataSource.filter((item) => item.action !== Action.DELETE)]}
        pagination={false}
        columns={columns}
        scroll={{ x: true, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};
