import { DownloadOutlined, FilePdfOutlined, PaperClipOutlined } from '@ant-design/icons';
import { BaseBadge } from '@app/components/common/BaseBadge/BaseBadge';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Descriptions, Drawer, Space } from 'antd';
import React, { useEffect, useState } from 'react';

interface ViewPaperAttachmentSectionProps {
  open: boolean;
  setOpen: (value: any) => any;
  name: string;
  abstract: string;
  url: string;
  file: string;
  price: string;
}

export const ViewPaperAttachmentSection: React.FC<ViewPaperAttachmentSectionProps> = ({
  open,
  setOpen,
  name,
  abstract,
  file,
  url,
  price,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  return (
    <Drawer open={drawerOpen} size="large" onClose={() => setOpen(false)} title={'Paper Publication Attachment'}>
      <BaseRow gutter={[16, 24]}>
        <BaseCol span={24}>
          <BaseRow gutter={[16, 16]}>
            <BaseCol span={24}>
              <BaseTypography.Title level={5}>Name</BaseTypography.Title>
            </BaseCol>
            <BaseCol span={24}>
              <BaseTypography.Paragraph>{name}</BaseTypography.Paragraph>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol span={24}>
          <BaseRow gutter={[16, 16]}>
            <BaseCol span={24}>
              <BaseTypography.Title level={5}>Abstract</BaseTypography.Title>
            </BaseCol>
            <BaseCol span={24}>
              <BaseTypography.Paragraph>{abstract}</BaseTypography.Paragraph>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol span={24}>
          <BaseRow gutter={[16, 16]}>
            <BaseCol span={24}>
              <BaseTypography.Title level={5}>Attachments</BaseTypography.Title>
            </BaseCol>
            <BaseCol span={24}>
              <a href={url} target='_blank' rel="noreferrer" download={file}>
                <Space>
                <BaseButton type='dashed' size="middle" >
                <FilePdfOutlined style={{ color: 'red', fontSize: '2rem' }} />
                <BaseTypography.Text>{file}</BaseTypography.Text>
                <DownloadOutlined />
                </BaseButton>
                </Space>
              </a>
                
             
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol span={24}>
          <BaseRow gutter={[16, 16]}>
            <BaseCol span={24}>
              <BaseTypography.Title level={5}>Price</BaseTypography.Title>
            </BaseCol>
            <BaseCol span={24}>
              <BaseTypography.Paragraph>{`LKR ${price}`}</BaseTypography.Paragraph>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </Drawer>
  );
};
