import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Button, Input, InputRef, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { Tooltip } from 'antd';
import { Pagination, UserTableRow, getUsersTableData } from '@app/api/user.api';
import { useMounted } from '@app/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

interface UsersTableProps {
  searchText: string;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const UsersTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: UserTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const handleErrorMessages = useApiErrorHandler();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((prevData) => ({ ...prevData, loading: true }));
      getUsersTableData({...pagination, search:  form.getFieldValue('search')})
        .then((response) => {
          if (isMounted.current) {
            setTableData({ data: response.data, pagination: response.pagination, loading: false });
          }
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          setTableData((prevData) => ({ ...prevData, loading: false }));
          handleErrorMessages(error);
        });
    },
    [isMounted, handleErrorMessages],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, []);

  const handleTableChange = (pagination: Pagination) => {
    console.log('Table change with pagination:', pagination);
    fetch(pagination);
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Users`}</span>
    );
  };

  const handleDeleteRow = (id: number) => {
    setTableData((prevData) => ({
      ...prevData,
      data: prevData.data.filter((item) => item.id !== id),
      pagination: {
        ...prevData.pagination,
        total: prevData.pagination.total ? prevData.pagination.total - 1 : prevData.pagination.total,
      },
    }));
  };

  const handleEditRow = (id: number) => {
    navigate(`/edit-user/${id}`);
  };

  const handleViewRow = (id: number) => {
    navigate(`/view-user/${id}`);
  };

  const columns: ColumnsType<UserTableRow> = [
    {
      title: t('Username'),
      dataIndex: 'username',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
    },
    {
      title: t('Role'),
      dataIndex: 'role',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Button
              type="ghost"
              onClick={() => handleViewRow(record.id)}
              icon={<IoEye />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Tooltip title="Edit">
            <Button
              type="ghost"
              onClick={() => handleEditRow(record.id)}
              icon={<MdModeEdit />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Button
              type="default"
              danger
              onClick={() => handleDeleteRow(record.id)}
              icon={<MdDelete />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <BaseCard id="basic-table" title="Users" padding="1.25rem" 
    extra={
      <BaseButtonsForm
      form={form}
      layout="inline"
      isFieldsChanged={fieldsChanged}
      setFieldsChanged={setFieldsChanged}
    >

      <BaseButtonsForm.Item name={'search'}>
        <Input
          size="middle"
          suffix={<Button icon={<SearchOutlined  />} size='middle' type='text' onClick={()=>{fetch(initialPagination)}} />}
          placeholder={'Search Users'}
        />
      </BaseButtonsForm.Item>
      <Button type='ghost' size='middle' icon={<ReloadOutlined />} 
        onClick={()=>{
          form.resetFields(['search']);
          fetch(initialPagination);
          }}/>
    </BaseButtonsForm>
    }>
       <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        current: tableData.pagination.current,
        pageSize: tableData.pagination.pageSize,
        total: tableData.pagination.total,
        showTotal,
      }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: true, scrollToFirstRowOnChange: true }}
    />
    </BaseCard>
   
  );
};
