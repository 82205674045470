import { get, post } from '@app/api/http.api';
import { MeetingTableRow } from './meeting.api';
import { FileInfo } from './assets.api';

export interface ProductRequest {
  name: string;
  slug: string;
  sku: string | null;
  details?: null | string;
  description: null | string;
  regular_price: null | number;
  sale_price: null | number;
  quantity: null | number;
  status: string | null;
  category_id: null | number;
  type: string;
  image: null | string;
  label_instead_price: boolean;
  price_label: string | null;
  interval: number | null;
  lead_time: number | null;
  requires_confirmation: boolean;
  calendar_color: string | null;
  show_zoom_links: boolean;
  show_pricing_options: boolean;
  meetings: number[];

  pricing_option_type: string | null;
  display_type: string | null;
  title: string | null;
  format: string | null;
  hide_price_label: boolean;
  hide_duration: boolean;
  required: boolean;
  add_description: boolean;
  pricing_option_description: string | null;
  adjust_duration: boolean;
  duration_time: number | null;
  limit_quantity_range: boolean;
  min_qty_range: number | null;
  max_qty_range: number | null;
  adjust_price: boolean;
  adjust_price_type: string | null;
  price: number | null;
  pricing_options: any[];
  images: any[];
  from_date: string | null;
  to_date: string | null;
  from_time: string | null;
  to_time: string | null;
  min_reserve: number | null;
  max_reserve: number | null;
  reserve_measure: string | null;
  lead_time_measure : string | null;
  start_time_interval: number | null;
  show_quantity_field: boolean;
  quantity_label: string | null;
  amenities:string[] | null
}

export interface Pricing {
  id: number | null;
  type: string | null;
  display_type: string | null;
  title: string | null;
  format: string | null;
  options: PricingOption[];
  hide_price_label: boolean;
  hide_duration: boolean;
  required: boolean;
  add_description: boolean;
  description: string | null;
  adjust_duration: boolean;
  adjust_duration_type: string | null;
  duration: number | null;
  limit_quantity_range: boolean;
  min_qty_range: number | null;
  max_qty_range: number | null;
  adjust_price: boolean;
  adjust_price_type: string | null;
  price: number | null;
  pricing_options: PricingOption[];
}

export interface PricingOption {
  id: number | null;
  option: string;
  option_measure: string;
  option_price: number;
  product_id: number;
  action: string;
}

export interface Appointable {
  id: number;
  label_instead_price: boolean;
  price_label: string | null;
  interval: number | null;
  lead_time: number | null;
  requires_confirmation: boolean;
  calendar_color: any;
  from_date: string | null;
  to_date: string | null;
  from_time: string | null;
  to_time: string | null;
  min_reserve: number | null;
  max_reserve: number | null;
  reserve_measure: string | null;
  lead_time_measure : string | null;
  start_time_interval: number | null;
}
export interface Product {
  meetings?:  MeetingTableRow[];
  image_urls?:  [];
  images?:  any;
  selected_amenities: [];
  product_category_id: number; 
  id: number;
  name: string;
  slug: string;
  sku: string | null;
  details: null | string;
  description: null | string;
  regular_price: null | number;
  sale_price: null | number;
  quantity: number | null;
  created_at: string;
  updated_at: string;
  display_type: '1' | '2' | '3';
  status: string;
  type: string;
  image_url: string;
  show_zoom_links: boolean;
  show_pricing_options: boolean;
  image: string;
  category_names: string[];
  pricing_options: PricingOption[];
  show_quantity_field: boolean;
  quantity_label: string | null;
  amenities: string[];
  appointable: Appointable | null;
}

export interface AppointableProduct {
  id: number;
  label_instead_price: boolean;
  price_label: string | null;
  interval: number | null;
  lead_time: number | null;
  requires_confirmation: boolean;
  calendar_color: any;
  from_date: string | null;
  to_date: string | null;
  from_time: string | null;
  to_time: string | null;
  min_reserve: number | null;
  max_reserve: number | null;
  reserve_measure: string | null;
  lead_time_measure : string | null;
  start_time_interval: number | null;
}

export interface ProductTableRow {
  productImage: any;
  stock: any;
  price: any;
  categories: any;
  date: any;
  id: number;
  name: string;
  sku: string;
  description: null | string;
}

export interface Images {
  key?: string;
  image?: string;
  name?: string;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface ProductTableData {
  products: {
    data: ProductTableRow[];
    pagination: Pagination;
  }
  categories: CategoryOption[];
  status_options: StatusOption[];
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

export interface ProductApiData {
  product: Product;
  pricing: Pricing;
  appointable_product: AppointableProduct;
  category: CategoryOption;
  category_options: CategoryOption[];
  status_options: StatusOption[];
  type_options: TypeOption[];
  //meetings: number[];
  meetings: MeetingTableRow[];
  duration_unit_options: UnitOption[];
  interval_unit_options: UnitOption[];
  padding_time_unit_options: UnitOption[];
  lead_time_unit_options: UnitOption[];
  scheduling_window_unit_options: UnitOption[];
  pricing_option_measures: UnitOption[];
  zoom_links: MeetingTableRow[];
  pricing_options_types: TypeOption[];
  pricing_options_display_types: TypeOption[];
  pricing_options_formats: TypeOption[];
  pricing_options: TypeOption[];
  images: FileInfo[];
  amenities_options: AmenityOption[]
}

export interface UnitOption {
  key?: 'min' | 'hrs' | 'dys' | 'wks' | 'mon';
  name?: string;
}

export interface StatusOption {
  key?: 'ACTIVE' | 'INACTIVE';
  name?: string;
}

export interface TypeOption {
  key?: string;
  name?: string;
}

export interface AmenityOption {
  key?: string;
  name?: string;
}

export interface CategoryOption {
  id: number;
  category_chain: string;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
  status?:string | null;
  category?:number | null;
}

export interface ProductTableDataApi {
  products: PaginatedResponse<Product>
  categories: CategoryOption[];
  status_options: StatusOption[];
}

export const getProductTableData = async (parameters:Parameters): Promise<ProductTableData> => {
  try {
    const { data, status } = await get<ProductTableDataApi>('products', { params: parameters }, true);
    console.log(`Status: ${status}`, `Data: ${data}`);

    const result: ProductTableRow[] = data?.products.data.map((rawData: Product) => ({
      id: rawData.id,
      name: rawData.name,
      sku: rawData.sku ?? 'N/A',
      description:
        <number>rawData?.description?.length > 53
          ? `${rawData?.description?.substring(0, 50)}...`
          : rawData.description,
      productImage: rawData.image_url,
      stock: rawData.quantity ?? 'N/A',
      price: rawData.sale_price ?? 0,
      categories: rawData.category_names,
      date: rawData.updated_at,
      status:rawData.status
    }));

    return {
      products: 
        {
          data: result,
          pagination: {
            current: data.products.current_page,
            pageSize: data.products.per_page,
            total: data.products.total,
          },
        },
      
      categories:data.categories,
      status_options: data.status_options
    };
  } catch (error: any) {
    throw error;
  }
};

export const getProducts = async (): Promise<Product[] | any> => {
  try {
    const { data, status } = await get<Product[]>(`products`, undefined, true);
    // console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storeProducts = async (StoreProductData: ProductRequest) => {
  try {
    const { data, status } = await post<ProductRequest>(`products/create`, StoreProductData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error:  ${error.message}`);
    throw error;
  }
};

export const updateProduct = async (id: number, UpdateProductData: ProductRequest) => {
  try {
    const { data, status } = await post<ProductRequest>(`products/${id}/edit`, UpdateProductData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.message}`);
    throw error;
    // notificationController.error({ message: `Error: ${error.status} - ${error.message}`});
  }
};

export const createProduct = async () => {
  try {
    const { data, status } = await get<ProductApiData>(`products/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showProduct = async (id: number) => {
  try {
    const { data, status } = await get<ProductApiData>(`products/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editProduct = async (id: number) => {
  try {
    const { data, status } = await get<ProductApiData>(`products/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
