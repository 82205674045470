import { get, post } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';
import { StatusOption } from '@app/api/user.api';
export interface CustomerRequest {
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  image: string | null;
  password: string | null;
  phone: string | null;
  address_line1: string | null;
  address_line2: string | null;
  city: string | null;
  postcode: string | null;
  country: string | null;
  state: string | null;
  bill_email: string;
}

export interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  company: string;
  address_line1: string;
  address_line2: string;
  postcode: string;
  country: string;
  state: string;
  phone: string;
  image_url?: string;
  // biographicalInfo: string | null;
  profilePicture: string | null;
  city: string | null;
  created_at: string;
  updated_at: string;
  display_type: '1' | '2' | '3';
  user: User;
  bill_email: string;
}

export interface User {
  id: number;
  name: string;
  username: string;
  email: string;
  password?: string;
  customer: Customer;
  status: 'ACTIVE' | 'INACTIVE';
}

export interface CustomerApiData {
  customer: Customer | null;
  status_options: StatusOption[];
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

interface CustomerTableRow {
  id: number;
  name: string;
  // lastActive: string;
  // dateRegistered: string;
  created_at: string;
  email: string;
  orders: number;
  totalSpend: number;
  aov: number;
  country: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface CustomerTableData {
  data: CustomerTableRow[];
  pagination: Pagination;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
}

export const getCustomerTableData = async (parameters:Parameters): Promise<CustomerTableData> => {
  try {
    const { data, status } = await get<any>('customers', { params: parameters}, true);
    console.log(`Status: ${status}`, `Data: ${data}`);

    const customers: CustomerTableRow[] = data.customers.data.map((customerData: any) => ({
      id: customerData.id,
      name: customerData.name,
      email: customerData.user.email || '',
      city: customerData.city || '',
      created_at: customerData.created_at || '',
      orders: 0,
      totalSpend: 0,
      aov: 0,
      country: customerData.country || '',
      state: customerData.state || '',
      postalCode: customerData.postcode || '',
    }));

    return {
      data: customers,
      pagination: {
        current: data.customers.current_page,
        pageSize: data.customers.per_page,
        total: data.customers.total,
      },
    };
  } catch (error: any) {
    throw error;
  }
};

export const getCustomers = async (): Promise<{ customers?: Customer[] }> => {
  try {
    const { data, status } = await get<{ customers?: Customer[] }>(`customers`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    // console.error(`Error: ${error.status} - ${error.message}`);
    // return {};
    throw error;
  }
};

export const storeCustomers = async (StoreCustomerData: CustomerRequest) => {
  try {
    const { data, status } = await post<CustomerRequest>(`customers/create`, StoreCustomerData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    // console.log(error.status ,error.message);
    throw error;
  }
};

export const updateCustomer = async (id: number, UpdateCustomerData: CustomerRequest) => {
  try {
    const { data, status } = await post<CustomerRequest>(`customers/${id}/edit`, UpdateCustomerData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editCustomer = async (id: number) => {
  try {
    const { data, status } = await get<CustomerApiData>(`customers/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showCustomer = async (id: number) => {
  try {
    const { data, status } = await get<CustomerApiData>(`customers/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createCustomer = async () => {
  try {
    const { data, status } = await get<CustomerApiData>(`customers/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
