import React, { useState } from 'react';
import { NFTCard } from '../common/NFTCard/NFTCard';
import { Statistic } from 'antd';
import * as S from './MonthlyNetSales.styles';
import { MonthlyNetSalesChart } from './MonthlyNetSalesChart/MonthlyNetSalesChart';
import { BloodTestResult, results } from '@app/constants/bloodTestResults';
import { DashboardData } from '@app/pages/DashboardPages/NftDashboardPage';

interface MonthlyNetSalesProps{
  data : DashboardData | null;
}

const MonthlyNetSales:React.FC<MonthlyNetSalesProps> = ({data}) => {

    const [activeItem, setActiveItem] = useState<BloodTestResult>(results[0]);

  return (
    <NFTCard>
        <MonthlyNetSalesChart data={data?.total_monthly_net_sales?.monthly_net_sales ?? []}/>
        <S.Title>{"Monthly Net Sales (LKR)"}</S.Title>
        <Statistic  value={data?.total_monthly_net_sales?.net_sales} />
        <S.Title>{"Monthly Top Seller"}</S.Title>
        <Statistic  value={data?.top_seller_for_curent_month?.name ?? 'None'} />
        { data?.top_seller_for_curent_month?.order_count && (<S.Text>{`${data?.top_seller_for_curent_month?.order_count} orders`}</S.Text>)}
    </NFTCard>
  );
}

export default MonthlyNetSales;
