import React ,  {useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { UserForm } from '@app/components/forms/UserForm/UserForm';
import { useTranslation } from 'react-i18next';
import{ authUserService } from '@app/services/authUser.service';

interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const ViewUserPage: React.FC = () => {
  const { t } = useTranslation();
  const id = authUserService.getUser()?.id?.toString();

  return (
    <>
      <PageTitle>{t('User')}</PageTitle>
      <UserForm type={"view"} id={id} editable={false} isMyProfile={true}  />
    </>
  );
};

export default ViewUserPage;

