import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostForm } from '@app/components/forms/PostsForm/PostsForm';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { showPost} from '@app/api/post.api';

interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const EditPostPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  // const [PostTitle, setPostTitle] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(true);

  

  return (
    <>
      {/* <PageTitle>{t('common.login')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <PostForm type={"edit"} id={id} editable = {true}/>
    </>
  );
};

export default EditPostPage;
