import React, {useEffect, useState,} from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { Select } from 'antd';
import { OrderLog } from '@app/api/order.api';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';

interface OrderNotesSectionProps {
  orderNotes: OrderLog[];
  setOrderNotes: React.Dispatch<React.SetStateAction<OrderLog[]>>;
  userNotes: OrderLog[];
  setUserNotes: React.Dispatch<React.SetStateAction<OrderLog[]>>;
  editable: boolean;
}

export const OrderNotesSection: React.FC<OrderNotesSectionProps> = ({ orderNotes, setOrderNotes, userNotes, setUserNotes, editable=false}) => {
  const [userNoteForm] = BaseButtonsForm.useForm();
  const [isUserNoteFieldsChanged, setUserNoteFieldsChanged] = useState(false);
  const user = useAppSelector((state) => state.user.user);


  const getPopupContainer = (triggerNode: HTMLElement | null) => {
    return triggerNode || document.body;
  };

  function handleDeleteNote(index: number) {
       // const updatedOrderNotes = [...orderNotes];
       // const updatedUserNotes = [...userNotes];
        const updatedUserNotes = userNotes.filter((_, i) => i !== index);
        const updatedOrderNotes = orderNotes.filter((_, i) => i !== index);
        setUserNotes(updatedUserNotes);
        setOrderNotes(updatedOrderNotes);
  }

  function handleAddUserNote() {
    //console.log(orderNotes,userNotes);
    const note = userNoteForm.getFieldValue('note');
    const note_for = userNoteForm.getFieldValue('note_for') || '1';
    if(note){
      setUserNotes([...userNotes, { note: note, note_for: note_for,user: user?.username }]);  
      setOrderNotes([...orderNotes, { note: note, note_for: note_for,user: user?.username }]);  
    }
    else{
      notificationController.error({
        message:'Error:',
        description:'Note is required.'
      });
    }
    userNoteForm.resetFields(); 
    
  }

  function formatDateTime(dateTime: string): string {
    const date = new Date(dateTime);
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
    return date.toLocaleDateString('en-US', options);
  }
  return (
    <>
      <div style={{ maxHeight: '500px', overflowY: 'auto', width: '100%' }}>
        {orderNotes?.map((note, index) => (
          <div
            key={index}
            style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <p
              style={{
                background: '#e6f7ff',
                padding: '8px',
                borderRadius: '8px',
                maxWidth: '90%', // chat box width
              }}
            >
              {note.note}
            </p>
            <div style={{ marginTop: '4px', marginBottom: '10px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
              {note?.updated_at?formatDateTime(note?.updated_at):''} by {note.user}
              <span style={{ marginLeft: '8px', color: 'red', cursor: 'pointer' }} onClick={() => handleDeleteNote(index)}>
                Delete note
              </span>
            </div>
          </div>
        ))}
      </div>
      <br />
      <hr />
      <br />

      <BaseButtonsForm
             // {...formItemLayout}
              name="userNoteForm"
              form={userNoteForm}
              isFieldsChanged={isUserNoteFieldsChanged}
              setFieldsChanged={setUserNoteFieldsChanged}
            >

      <BaseButtonsForm.Item
        tooltip="Add a note for your reference, or add a customer note (the user will be notified)."
        label="Add Note"
        name="note"
        rules={[{ required: true, message: 'Note is required' }]}
      >
        <BaseInput.TextArea disabled={!editable} />
      </BaseButtonsForm.Item>

      <BaseButtonsForm.Item name="note_for">
        <BaseSelect getPopupContainer={getPopupContainer} defaultValue="1" disabled={!editable} >
          <Select.Option value="1">Private Note</Select.Option>
          <Select.Option value="2">Note To Customer</Select.Option>
        </BaseSelect>
      </BaseButtonsForm.Item> 
      {editable  && 
      <BaseButtonsForm.Item>
        <BaseButton type="primary" onClick={handleAddUserNote} style={{ marginLeft: '8px' }}>
          Add Note
        </BaseButton>
      </BaseButtonsForm.Item>
      }
      </BaseButtonsForm>
    </>
  );
};

export default OrderNotesSection;
