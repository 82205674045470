import React, { useState } from 'react';
import { PostCategoryTable } from '@app/components/tables/BasicTable/PostCategoryTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { Card, Input, Space } from 'antd';

const PostCategoriesTablePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlebtnClick = () => {
    navigate('/add-post-category');
  };

 

  return (
    <>
      {/* <PageTitle>{t('Post Categories')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <Space style={{ marginBottom: '16px', justifyContent: 'space-between', width: '100%' }}>
        <BaseButton icon={<PlusOutlined />} type="primary" onClick={handlebtnClick}>
          {t('Add Post Category')}
        </BaseButton>
      </Space>


        <PostCategoryTable />
    
    </>
  );
};

export default PostCategoriesTablePage;
