import { get, post, ApiError, remove } from '@app/api/http.api';

export type { ApiError };

export interface EventRequest {
  title: string;
  start_date: null | string;
  end_date: null | string;
  image: null | string;
  description: null | string;
  slug: string;
  location: null | string;
  tickets: any[];
  type: TypeOfEvent;
}

export interface Paper {
  id: number | null;
  name: string;
}

export interface Ticket {
  id: number | null;
  name: string;
  sale_price: number;
  quantity: number;
}

export interface Event {
  image_url: string;
  id: number;
  title: string;
  start_date: null | string;
  end_date: null | string;
  image: null | string;
  description: null | string;
  slug: string;
  location: string;
  tickets: Ticket[];
  papers: Paper[];
  type: 'EVENT' | 'CONFERENCE' | 'OTHER';
}

export interface EventTableRow {
  id: number;
  title: string;
  start_date: null | string;
  end_date: null | string;
  description: null | string;
  slug: string;
  location: string;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

export interface EventTableData {
  data: EventTableRow[];
  pagination: Pagination;
}

export interface EventApiData {
  event: Event;
  paper_options: Paper[];
}
export enum TypeOfEvent {
  EVENT = 'EVENT',
  CONFERENCE = 'CONFERENCE',
  OTHER = 'OTHER',
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
}

export const getEventTableData = async (parameters:Parameters, type?: TypeOfEvent): Promise<EventTableData> => {
  try {
    const { data, status } = await get<PaginatedResponse<EventTableRow>>(
      'events',
      { params: { ...parameters, type } },
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);

    const result: EventTableRow[] = data?.data.map((rawData: EventTableRow) => ({
      id: rawData.id,
      title: rawData.title,
      slug: rawData.slug,
      location: rawData.location,
      start_date: rawData.start_date,
      end_date: rawData.end_date,
      description:
        <number>rawData?.description?.length > 53
          ? `${rawData?.description?.substring(0, 50)}...`
          : rawData.description,
    }));

    return {
      data: result,
      pagination: {
        current: data.current_page,
        pageSize: data.per_page,
        total: data.total,
      },
    };
  } catch (error: any) {
    throw error;
  }
};

export const getEvents = async (type?: TypeOfEvent) => {
  const bindings: string = type !== undefined ? `?type=${type}` : '';
  try {
    const { data, status } = await get<Event>('events' + bindings, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storeEvent = async (StoreEventData: EventRequest) => {
  try {
    const { data, status } = await post<EventRequest>('/events/create', StoreEventData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return { data, message: 'Event created successfully' };
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // return { message: 'Failed to create event' };
    throw error;
  }
};

export const updateEvent = async (id: number, UpdateEventData: EventRequest) => {
  try {
    const { data, status } = await post<EventRequest>(`events/${id}/edit`, UpdateEventData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createEvent = async () => {
  try {
    const { data, status } = await get<EventApiData>(`events/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showEvent = async (eventType: TypeOfEvent, id: number) => {
  try {
    const { data, status } = await get<EventApiData>(`events/${id}?type=` + eventType, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editEvent = async (eventType: TypeOfEvent, id: number) => {
  try {
    const { data, status } = await get<EventApiData>(`events/${id}/edit?type=` + eventType, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const deleteEvent = async (id: number) => {
  try {
    const { data, status } = await remove(`events/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
