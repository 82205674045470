import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  logout,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
} from '@app/api/auth.api';
import { setUser } from '@app/store/slices/userSlice';
//import { deleteToken, deleteUser } from '@app/services/localStorage.service';
import{ tokenService } from '@app/services/token.service';
import{ abilityService } from '@app/services/ability.service';
import{ settingService } from '@app/services/setting.service';
//import{ authUserService } from '@app/services/authUser.service';

export interface AuthSlice {
  token: string | null;
  abilities: any
}

const initialState: AuthSlice = {
  token: tokenService.getToken(),
  abilities: null
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    tokenService.setToken(res.token);
    abilityService.setAbilities(res.abilities);
    settingService.setSettings(res.settings);
    dispatch(setUser(res.user));
    return {token:res.token , abilities:res.abilities};
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  logout().then((res) => {
    tokenService.deleteToken();
    abilityService.deleteAbilities();
    settingService.deleteSettings();
    // authUserService.deleteUser();
    dispatch(setUser(null));
})
});

export const doResetSession = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
    tokenService.deleteToken();
    abilityService.deleteAbilities();
    settingService.deleteSettings();
    // authUserService.deleteUser();
    dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.abilities = action.payload.abilities;
      console.log("fdsffdsf",action.payload);
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
      state.abilities = null;
    });
  },
});

export default authSlice.reducer;
