// import { PostCategory } from './postCategories.api';
import { post, get, remove } from '@app/api/http.api';
import { FileInfo } from './assets.api';

export interface PostCategory {
  id: number;
  name: string;
}
export interface PostCategoryTableRow {
  key: React.Key;
  id: number;
  postCategory_id: string;
  name: string;
}

export interface PostRequest {
  data: any;
  id: any;
  title: string;
  slug: string;
  body: string;
  image: null | string;
  published_at: string;
  display_at: string;
  category_ids: number[];
  video_links: Video[];
  files: any[];
  images: any[];
}

interface Video {
  id: number | null;
  video_link: string;
  action: string;
}

export interface VideoLink {
  key: string;
  videoLink: string;
}

export interface Post {
  id: number;
  user_id: number | null;
  title: string;
  slug: string;
  body: string;
  image: string | null;
  published_at: string;
  display_at: string;
  category_ids: number[];
  category_names: string[];
  videos: any[];
  image_url: string;
  files: any[];
  images: any[];
}

export interface PostTableRow {
  id: number;
  user_id: number | null;
  title: string;
  slug: string;
  body: string;
  image: string | null;
  published_at: string;
  display_at: string;
  category_ids: number[];
  category_names: string[];
  videos: any[];
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PostTableData {
  posts:{
    data: PostTableRow[];
    pagination: Pagination;
  }
  post_categories: PostCategory[];
  
}

export interface PostApiData {
  files: FileInfo[];
  images: FileInfo[];
  post: Post;
  category_options: PostCategory[];
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
  category?:string | null;
}

interface PostTableApi{
  posts:PaginatedResponse<PostTableRow>;
  post_categories: PostCategory[];
}

export const getPostTableData = async (parameters:Parameters): Promise<PostTableData> => {
  try {
    const { data, status } = await get<PostTableApi>('posts', { params: parameters }, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return {
      posts:{
      data: data.posts.data, // The actual post data array
      pagination: {
        current: data.posts.current_page,
        pageSize: data.posts.per_page,
        total: data.posts.total,
      }},
      post_categories:data.post_categories
    };
  } catch (error: any) {
    console.error('Error fetching post table data:', error);
    throw error;
  }
};

export const getPosts = async (): Promise<Post[] | any> => {
  try {
    const { data, status } = await get<Post>(`posts`, undefined, true);
    // console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storePosts = async (StorePostData: PostRequest) => {
  try {
    const { data, status } = await post<PostRequest>(`posts/create`, StorePostData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const updatePost = async (id: number, UpdatePostData: PostRequest) => {
  try {
    const { data, status } = await post<PostRequest>(`posts/${id}/edit`, UpdatePostData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
    // notificationController.error({ message: `Error: ${error.status} - ${error.message}`});
  }
};

export const uploadPostFile = async (UpdatePostData: any) => {
  try {
    const { data, status } = await post(`posts/upload`, UpdatePostData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
    // notificationController.error({ message: `Error: ${error.status} - ${error.message}`});
  }
};

export const createPost = async () => {
  try {
    const { data, status } = await get<PostApiData>(`posts/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showPost = async (id: number) => {
  try {
    const { data, status } = await get<PostApiData>(`posts/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editPost = async (id: number) => {
  try {
    const { data, status } = await get<PostApiData>(`posts/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const deletePost = async (id: number) => {
  try {
    const { status, data } = await remove<PostRequest>(`posts/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
