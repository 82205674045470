import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import OrdersForm from '@app/components/forms/OrderForm/OrderForm';

const OrderViewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  return (
    <>
      {/* <PageTitle>{t('View Order')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <OrdersForm type={'view'} id={id} editable={false} />
    </>
  );
};

export default OrderViewPage;
