import { PageTitle } from "@app/components/common/PageTitle/PageTitle";
import { AppointmentForm } from "@app/components/forms/AppointmentForm/AppointmentForm";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import React from 'react'

interface RouteParams {
    id: string;
    [key: string]: string | undefined;
  }
  
  const EditAppointmentPage: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<RouteParams>();
  
    return (
      <>
        {/* <PageTitle>{t('Edit-Appointment')}</PageTitle> */}
        <PageTitle>{"JRDC-Admin"}</PageTitle>
        <AppointmentForm type={"edit"} id={id} editable = {true}/>
      </>
    );
  };
  
  export default EditAppointmentPage;