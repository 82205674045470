import { PageTitle } from "@app/components/common/PageTitle/PageTitle";
import { MeetingForm } from "@app/components/forms/MeetingForm/MeetingForm";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import React , {useEffect, useState } from 'react'
import { showMeeting } from "@app/api/meeting.api";


interface RouteParams {
    id: string;
    [key: string]: string | undefined;
  }
  
  const EditMeetingPage: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<RouteParams>();
    // const [topic, setTopic] = useState<string>('');
    // const [loading, setLoading] = useState<boolean>(true);

    // useEffect(() => {
      
    //   if (id) {
    //     showMeeting(parseInt(id)).then((res: any) => {
    //       setTopic(res?.topic || '');
    //       setLoading(false);
    //     });
    //   }
    // }, [id]);

    // if (loading) {
    //   return <div>Loading...</div>; 
    // }
  
    return (
      <>
        {/* <PageTitle>{t('Edit-Meeting')}</PageTitle> */}
        <PageTitle>{"JRDC-Admin"}</PageTitle>
        <MeetingForm type={"edit"} id={id} editable = {true}/>
      </>
    );
  };
  
  export default EditMeetingPage;
  