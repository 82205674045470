import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Space, Button, Popconfirm, Input, InputRef } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventTableRow, Pagination, getEventTableData, TypeOfEvent, deleteEvent } from '@app/api/event.api';
import { useMounted } from '@app/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { Tooltip } from 'antd';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { notificationController } from '@app/controllers/notificationController';
import { QuestionCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};
interface EventTableProps {
  eventType?: 'EVENT' | 'CONFERENCE' | 'OTHER';
}

export const EventTable: React.FC<EventTableProps> = ({ eventType = 'EVENT' }) => {
  const [tableData, setTableData] = useState<{ data: EventTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const handleErrorMessages = useApiErrorHandler();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();

  const fetch = useCallback(
    (pagination: Pagination) => {
      let eventTypeParam: TypeOfEvent | undefined;
      if (eventType !== undefined) {
        eventTypeParam = eventType as TypeOfEvent;
      }
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getEventTableData({...pagination,search: form.getFieldValue('search')}, eventTypeParam)
        .then((res) => {
          if (isMounted.current) {
            setTableData({ data: res.data, pagination: res.pagination, loading: false });
          }
        })
        .catch((error) => {
          handleErrorMessages(error);
        });
    },
    [isMounted, eventType],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    deleteEvent(rowId).then((res: any) => {
      fetch(initialPagination);
      notificationController.success({ message: res?.message });
    });
  };

  const navigate = useNavigate();

  const handleEditRow = (id: number) => {
    navigate(eventType !== undefined && eventType == 'CONFERENCE' ? `/edit-conference/${id}` : `/edit-event/${id}`);
  };

  const handleViewRow = (id: number) => {
    navigate(eventType !== undefined && eventType == 'CONFERENCE' ? `/view-conference/${id}` : `/view-event/${id}`);
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    const eventLabel = eventType === 'CONFERENCE' ? 'Conferences' : 'Events';
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} ${eventLabel}`}</span>
    );
  };

  const columns: ColumnsType<EventTableRow> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    // Add more columns as needed
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Button
              type="ghost"
              onClick={() => handleViewRow(record.id)}
              icon={<IoEye />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Tooltip title="Edit">
            <Button
              type="ghost"
              onClick={() => handleEditRow(record.id)}
              icon={<MdModeEdit />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Popconfirm
            title="Are you sure to delete this event?"
            onConfirm={() => handleDeleteRow(record.id)}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Tooltip title="Delete">
              <Button
                type="default"
                danger
                icon={<MdDelete />}
                style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <BaseCard
      id="basic-table"
      title={eventType == 'EVENT' ? 'Events':'Conferences'}
      padding="1.25rem"
      extra={
        <BaseButtonsForm
        form={form}
        layout="inline"
        isFieldsChanged={fieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
  
        <BaseButtonsForm.Item name={'search'}>
          <Input
            size="middle"
            suffix={<Button icon={<SearchOutlined  />} size='middle' type='text' onClick={()=>{fetch(initialPagination)}} />}
            placeholder={`Search ${eventType == 'EVENT' ? 'Events' : 'Conferences'}`}
          />
        </BaseButtonsForm.Item>
        <Button type='ghost' size='middle' icon={<ReloadOutlined />} 
          onClick={()=>{
            form.resetFields(['search']);
            fetch(initialPagination);
            }}/>
      </BaseButtonsForm>
      }
    >
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={{
          current: tableData.pagination.current,
          pageSize: tableData.pagination.pageSize,
          total: tableData.pagination.total,
          showTotal,
        }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: true, scrollToFirstRowOnChange: true }}
      />
    </BaseCard>
  );
};
