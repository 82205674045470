import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostForm } from '@app/components/forms/PostsForm/PostsForm'; 

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { showPost } from '@app/api/post.api';

interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const ViewPostPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

 

  return (
    <>
        {/* <PageTitle>{t('View-Post')}</PageTitle> */}
        <PageTitle>{"JRDC-Admin"}</PageTitle>
        <PostForm type={"view"} id={id} editable={false} />
    </>
  );
};

export default ViewPostPage;
