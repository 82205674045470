import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { Select, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { TypeOption, UnitOption } from '@app/api/product.api';
import { EditableSelectionTable } from '@app/components/tables/EditableSelectionTable/EditableSelectionTable';

interface PricingOptionsRow {
  id: number | null;
  option: string;
  price: number;
  action: 'create' | 'edit' | 'no change' | 'delete';
}

interface PricingOptionsProps {
  type: 'create' | 'edit' | 'view';
  productPricingOptions: any[];
  setProductPricingOptions: (value: any) => any;
  pricingOptionMeasures: UnitOption[];
}

export const PricingOptionsSection: React.FC<PricingOptionsProps> = ({
  type,
  pricingOptionMeasures,
  productPricingOptions,
  setProductPricingOptions,
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [optionMeasures, setOptionMeasures] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    setOptions(productPricingOptions);

    const unitOptions: { value: string; label: string }[] = pricingOptionMeasures.map((e) => {
      return { value: e.key ?? 'hrs', label: e.name ?? 'Hour(s)' };
    });
    setOptionMeasures(unitOptions);
  }, []);

  const updateOriginalItems = (values: any) => {
    setOptions(values);
    setProductPricingOptions(values);
  };

  const columns: ColumnsType<PricingOptionsRow> = [
    {
      title: 'Option',
      dataIndex: 'option',
    },
    {
      title: 'Unit',
      dataIndex: 'option_measure',
      render(value, record, index) {
        return pricingOptionMeasures.find((e) => e.key === value)?.name;
      },
    },
    {
      title: 'Price',
      dataIndex: 'option_price',
    },
  ];
  

  return (
    <EditableSelectionTable
      formName={'pricingOptionsForm'}
      formFields={[
        { name: 'option', inputType: 'text', placeholder: 'Enter option', label: 'Option', colSpan: 8, type: 'input' },
        {
          name: 'option_measure',
          placeholder: 'Select unit',
          label: 'Unit',
          colSpan: 8,
          type: 'select',
          dropdownOptions: optionMeasures,
        },
        {
          name: 'option_price',
          inputType: 'number',
          placeholder: 'Enter price',
          label: 'Price',
          colSpan: 8,
          type: 'input',
        },
      ]}
      type={type}
      columns={columns}
      orginalItems={options}
      updateOriginalItems={updateOriginalItems}
      title={''}
    />
  );
};
