import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventForm } from '@app/components/forms/EventForm/EventForm';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { showEvent } from '@app/api/event.api';
import {TypeOfEvent} from '@app/api/event.api';

interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const EditEventPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  // const [postEventTitle, setEventTitle] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      {/* <PageTitle>{t('common.login')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <EventForm type={"edit"} id={id} editable = {true} eventType={TypeOfEvent.CONFERENCE}/>
    </>
  );
};

export default EditEventPage;
