import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import moment, { Moment } from 'moment';
import { Row, Col, Select, DatePicker } from 'antd';

interface CustomerChartProps {
  period: 'years' | 'year' | 'lastMonth' | 'thisMonth' | 'last7Days' | 'custom';
  xAxisData: string[];
  customerOrdersData: number[];
  guestOrdersData: number[] ;
  totalGuestOrders: number;
  totalCustomerOrders: number
}

const CustomerChart: React.FC<CustomerChartProps> = ({ period, xAxisData,
  customerOrdersData,
  guestOrdersData,totalGuestOrders,
  totalCustomerOrders}) => {
  const [seriesData, setSeriesData] = useState({
     customerData : totalCustomerOrders,
     guestData: totalGuestOrders
  });

  
  const renderControls = () => {

    const placeholders = [
      { period: 'years', placeholder:'Select Year'},
      { period: 'year', placeholder:'Select Month'},
      { period: 'thisMonth', placeholder:'Select Day'},
      { period: 'lastMonth', placeholder:'Select Day'},
      { period: 'last7Days', placeholder:'Select Day'},
     ]
     return (
       <Select style={{ width: '100%' }} 
       onChange={(e: any) => {
          setSeriesData({
           customerData : customerOrdersData[e],
           guestData: guestOrdersData[e]
        }) 
       }} 
       placeholder={placeholders?.find(e=>e.period === period)?.placeholder}>
         {xAxisData.map((e,index)=>(
           <Select.Option key={e} value={index}>{e}</Select.Option>
         ))}
       </Select>
     );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        {period !== 'custom' && (renderControls())}
        <ReactECharts
          option={{
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)',
            },
            legend: {
              data: ['Customer Orders', 'Guest Orders'],
            },
            series: {
              name: 'Orders',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data:[
                {
                  name: 'Customer Orders',
                  value: seriesData.customerData, 
                },
                {
                  name: 'Guest Orders',
                  value: seriesData.guestData, 
                },
              ]
            },
          }}
          style={{ height: '300px', marginTop: '16px' }}
        />
      </Col>

      <Col span={18}>
        <ReactECharts
          option={{
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: [
                { name: 'Customer Orders', icon: 'circle', textStyle: { color: '#1890ff' } },
                { name: 'Guest Orders', icon: 'circle', textStyle: { color: '#2fc25b' } },
              ],
              bottom: 10,
              formatter: (name: string) => {
                const color = name === 'Customer Orders' ? '#1890ff' : name === 'Guest Orders' ? '#2fc25b' : '';
                return `${name}`;
              },
            },
            xAxis: {
              type: 'category',
              data: xAxisData || [],
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                name: 'Customer Orders',
                type: 'line',
                data: customerOrdersData || [],
              },
              {
                name: 'Guest Orders',
                type: 'line',
                data: guestOrdersData || [],
              },
            ],
          }}
        />
      </Col>
    </Row>
  );
};

export default CustomerChart;
