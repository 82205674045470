import React from 'react';
import { CategoryTable } from '@app/components/tables/BasicTable/CategoryTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { Card, Space } from 'antd';

const CategoriesTablePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handlebtnClick = () => {
    navigate('/add-category');
  };

  return (
    <>
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <Space style={{ marginBottom: '16px', justifyContent: 'space-between', width: '100%' }}>
        <BaseButton icon={<PlusOutlined />} type="primary" onClick={handlebtnClick}>
          {t('Add New Category')}
        </BaseButton>
        {/* <BaseInput
          prefix={<SearchOutlined />}
          placeholder={t('Search Users')}
          value={searchValue}
          onChange={(e) => handleSearchText(e.target.value)}
        /> */}
      </Space>

        <CategoryTable />
    </>
  );
};

export default CategoriesTablePage;
