import { get, post } from '@app/api/http.api';

export interface PostCategoryRequest {
  title: string;
  name: string;
  description: null | string;
  slug: string;
  status: string | null;
}

export interface PostCategory {
  id: number;
  name: string;
  description: null | string;
  slug: string;
  status: string;
}

export interface PostCategoryTableRow {
  id: number;
  name: string;
  description: string;
  slug: string;
  status:string;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface StatusOption {
  key?: 'ACTIVE' | 'INACTIVE';
  name?: string;
}

export interface PostCategoryTableData {
  data: PostCategoryTableRow[];
  pagination: Pagination;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

export interface PostCategoryApiData {
  // category_options: CategoryOption[];
  postCategory: PostCategory;
  status_options: StatusOption[];
  // display_type_options: DisplayTypeOption[];
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
  status?:string | null;
}

export const getPostCategoryTableData = async (parameters:Parameters): Promise<PostCategoryTableData> => {
  try {
    const { data, status } = await get<PaginatedResponse<PostCategoryTableRow>>(
      `post_categories`,
      { params: parameters },
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);

    const result = data.data.map((rawData: PostCategoryTableRow) => ({
      id: rawData.id,
      name: rawData.name,
      slug: rawData.slug,
      status: rawData.status,
      description:
        rawData.description && rawData.description.length > 53
          ? `${rawData.description.substring(0, 50)}...`
          : (rawData.description ?? '-'),
    }));

    return {
      data: result,
      pagination: {
        current: data.current_page,
        pageSize: data.per_page,
        total: data.total,
      },
    };
  } catch (error: any) {
    throw error;
  }
};

export const getPostCategories = async (search = '') => {
  try {
    const { data, status } = await get<PostCategory>(`post_categories?search=${search}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storePostCategory = async (StorePostCategoryData: PostCategoryRequest) => {
  try {
    const { data, status } = await post<PostCategoryRequest>(
      '/post_categories/create',
      StorePostCategoryData,
      undefined,
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return { data, message: 'Post category created successfully' };
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // return { message: 'Failed to create post category' };
    throw error;
  }
};

export const updatePostCategory = async (id: number, UpdatePostCategoryData: PostCategoryRequest) => {
  try {
    const { data, status } = await post<PostCategoryRequest>(
      `post_categories/${id}/edit`,
      UpdatePostCategoryData,
      undefined,
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createPostCategory = async () => {
  try {
    const { data, status } = await get<PostCategoryApiData>(`post_categories/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showPostCategory = async (id: number) => {
  try {
    const { data, status } = await get<PostCategoryApiData>(`post_categories/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editPostCategory = async (id: number) => {
  try {
    const { data, status } = await get<PostCategoryApiData>(`post_categories/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
