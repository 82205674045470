import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import AuthorizeRoute from '@app/components/router/AuthorizeRoute';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import CategoriesTablePage from '@app/pages/CategoriesPage/CategoriesTable';
import AddProductPage from '@app/pages/ProductsPage/ProductCreate';
import EditProductPage from '@app/pages/ProductsPage/ProductEdit';
import AddUserPage from '@app/pages/UsersPages/AddUserPage';
import AllUsers from '@app/pages/UsersPages/AllUsers';
import AddCategoryPage from '@app/pages/CategoriesPage/CategoryCreate';
import EditCategoryPage from '@app/pages/CategoriesPage/CategoryEdit';
import ViewCategoryPage from '@app/pages/CategoriesPage/CategoryView';
import ViewProductPage from '@app/pages/ProductsPage/ProductView';
import OrderViewPage from '@app/pages/OrdersPage/OrderView';
import OrderCreatePage from '@app/pages/OrdersPage/OrderCreate';
import OrderEditPage from '@app/pages/OrdersPage/OrderEdit';
import CustomerPage from '@app/pages/CustomerPage/CreateCustomerPage';
import CustomerTablePage from '@app/pages/CustomerPage/Customertable';
import EditUserPage from '@app/pages/UsersPages/EditUserPage';
import ViewUserPage from '@app/pages/UsersPages/ViewUserPage';
import { CreateMeetingPage } from '@app/pages/ERoomPages/CreateMeetingPage';
import AllMeetingsPage from '@app/pages/ERoomPages/AllMeetingsPage';
import EditMeetingPage from '@app/pages/ERoomPages/EditMeetingPage';
import ViewMeetingPage from '@app/pages/ERoomPages/ViewMeetingPage';
import AllAppointmentsPage from '@app/pages/AppointmentPages/AllAppointmentsPage';
import { CreateAppointmentPage } from '@app/pages/AppointmentPages/CreateAppointmentPage';
import EditAppointmentPage from '@app/pages/AppointmentPages/EditAppointmentPage';
import ViewAppointmentPage from '@app/pages/AppointmentPages/ViewAppointmentPage';
import AppointmentsCalendar from '@app/pages/AppointmentPages/CalenderPage';

import StockPage from '@app/pages/ReportPages/StockPage';
import TaxesPage from '@app/pages/ReportPages/TaxesPage';
import CustomerChartPage from '@app/pages/ReportPages/CustomerChartPage';
import OrderChartPage from '@app/pages/ReportPages/OrderChartPage';
import { CreateRolePermissionsPage } from '@app/pages/RolesPermissionPages/CreateRolesPermissionPage';
import { EditRolePermissionsPage } from '@app/pages/RolesPermissionPages/EditRolePermissionsPage';
import { ViewRolePermissionsPage } from '@app/pages/RolesPermissionPages/ViewRolePermissionsPage';
import { RolePermissionsTablePage } from '@app/pages/RolesPermissionPages/RolePermissionsTablePage';
import AddPostCategoryPage from '@app/pages/PostCategoryForm/PostCategoryCreate';
import PostCategoriesTablePage from '@app/pages/PostCategoryForm/PostCategoriesTable';
import ViewPostCategoryPage from '@app/pages/PostCategoryForm/PostCategoryView';
import EditPostCategoryPage from '@app/pages/PostCategoryForm/PostCategoeyEdit';
import AddPostPage from '@app/pages/PostsPages/PostCreate';
import PostTablePage from '@app/pages/PostsPages/PostsTable';
import ViewPostPage from '@app/pages/PostsPages/PostView';
import EditPostPage from '@app/pages/PostsPages/PostEdit';
import EventsTablePage from '@app/pages/Eventpages/EventsTable';
import EditEventPage from '@app/pages/Eventpages/EventEdit';
import AddEventPage from '@app/pages/Eventpages/EventCreate';
import ViewEventPage from '@app/pages/Eventpages/EventView';
import ConferenceTablePage from '@app/pages/ConferencePages/ConferenceTable';
import EditConferencePage from '@app/pages/ConferencePages/ConferenceEdit';
import AddConferencePage from '@app/pages/ConferencePages/ConferenceCreate';
import ViewConferencePage from '@app/pages/ConferencePages/ConferenceView';

import { PageCreatePage } from '@app/pages/PagesPage/PageCreate';
import { PageViewPage } from '@app/pages/PagesPage/PageViewPage';
import { PageEditPage } from '@app/pages/PagesPage/PageEditPage';
import PageTablePage from '@app/pages/PagesPage/PagesTable';

import{ settingService } from '@app/services/setting.service';
import EditCustomerPage from '@app/pages/CustomerPage/EditCustomerPage';
import ViewCustomerPage from '@app/pages/CustomerPage/ViewCustomerPage';

import EditMyProfilePage from '@app/pages/MyProfilePage/EditMyProfilePage';
import ViewMyProfilePage from '@app/pages/MyProfilePage/ViewMyProfilePage';


const OrdersPage = React.lazy(() => import('@app/pages/OrdersPage/OrdersPage'));
const ProductsPage = React.lazy(() => import('@app/pages/ProductsPage/ProductsTable'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const ErrorPage = React.lazy(() => import('@app/pages/ErrorPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const Logout = React.lazy(() => import('./Logout'));
const ResetSession = React.lazy(() => import('./ResetSession'));


export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';
export const PRODUCT_CATEGORIES_PATH = '/product-categories';

const NftDashboard = withLoading(NftDashboardPage);
const Orders = withLoading(OrdersPage);
const Products = withLoading(ProductsPage);
const ServerError = withLoading(ServerErrorPage);
const Error = withLoading(ErrorPage);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const ResetSessionFallback = withLoading(ResetSession);
import ErrorBoundary from '@app/components/Error/ErrorBoundary';


export const AppRouter: React.FC = () => {
  const auth = settingService.getSettings()?.check_ability;
  const protectedLayout = (
    <RequireAuth>
        <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
    
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<NftDashboard />} />
          <Route path="orders" element={<Orders />} />
          <Route path="show-order/:id" element={<OrderViewPage />} />
          <Route path="create-order" element={<OrderCreatePage />} />
          <Route path="edit-order/:id" element={<OrderEditPage />} />

          <Route path="all-products" element={<Products />} />

          <Route path="customers" element={<CustomerPage />} />
          <Route path="all-customers" element={<CustomerTablePage />} />
          <Route path="edit-customer/:id" element={<EditCustomerPage />} />
          <Route path="view-customer/:id" element={<ViewCustomerPage />} />
          <Route path="show-customer/:id" element={<ViewCustomerPage />} />

          <Route path="add-meeting" element={<CreateMeetingPage />} />
          <Route path="all-meetings" element={<AllMeetingsPage />} />
          <Route path="edit-meeting/:id" element={<EditMeetingPage />} />
          <Route path="view-meeting/:id" element={<ViewMeetingPage />} />

          <Route path="add-appointment" element={<CreateAppointmentPage />} />
          <Route path="all-appointments" element={<AllAppointmentsPage />} />
          <Route path="edit-appointment/:id" element={<EditAppointmentPage />} />
          <Route path="view-appointment/:id" element={<ViewAppointmentPage />} />
          <Route path="appointment-calendar" element={<AppointmentsCalendar />} />

          <Route path="apps">RR</Route>
          <Route path="forms"></Route>

          <Route
            path={'/product-categories'}
            element={ <AuthorizeRoute model={'ProductCategory'} policy={'viewAny'}  auth={auth}> <CategoriesTablePage /> </AuthorizeRoute>}
          />
          <Route 
            path="add-category" 
            element={ <AuthorizeRoute model={'ProductCategory'} policy={'create'} auth={auth}> <AddCategoryPage /> </AuthorizeRoute>}
          />
          <Route path="edit-category/:id"
            element={ <AuthorizeRoute model={'ProductCategory'} policy={'edit'} auth={auth}> <EditCategoryPage /> </AuthorizeRoute>}
          />
          <Route path="show-category/:id"
            element={ <AuthorizeRoute model={'ProductCategory'} policy={'view'} auth={auth}> <ViewCategoryPage /> </AuthorizeRoute>}
          />

          <Route path="add-product" element={<AddProductPage />} />
          <Route path="edit-product/:id" element={<EditProductPage />} />
          <Route path="view-product/:id" element={<ViewProductPage />} />

          <Route path="add-user" element={<AddUserPage />} />
          <Route path="all-users" element={<AllUsers />} />
          <Route path="edit-user/:id" element={<EditUserPage />} />
          <Route path="view-user/:id" element={<ViewUserPage />} />

          <Route path="edit-profile" element={<EditMyProfilePage />} />
          <Route path="profile" element={<ViewMyProfilePage />} />

          <Route path="/report/stock" element={<StockPage />} />
          <Route path="/report/tax" element={<TaxesPage />} />
          <Route path="/report/customer" element={<CustomerChartPage/>} />
          <Route path="/report/order" element={<OrderChartPage/>} />

          <Route path="add-post-category" element={<AddPostCategoryPage />} />
          <Route path="post-categories" element={<PostCategoriesTablePage />} />
          <Route path="view-post-category/:id" element={<ViewPostCategoryPage />} />
          <Route path="edit-post-category/:id" element={<EditPostCategoryPage />} />
          <Route path="show-post-category/:id" element={<ViewPostCategoryPage />} />

          <Route path="add-post" element={<AddPostPage />} />
          <Route path="post" element={<PostTablePage/>} />
          <Route path="view-post/:id" element={<ViewPostPage />} />
          <Route path="edit-post/:id" element={<EditPostPage />} />
          <Route path="show-post/:id" element={<ViewPostPage />} />
            <Route path = "all-events" element={ <EventsTablePage />} />
            <Route path="add-event" element={<AddEventPage />} />
            <Route path="view-event/:id" element={<ViewEventPage />}/>
            <Route path="edit-event/:id" element={<EditEventPage />} />
            <Route path="show-event/:id" element={<ViewEventPage />} />
       
          <Route path = "all-conferences" element={<ConferenceTablePage />} />
          <Route path="add-conference" element={<AddConferencePage />} />
          <Route path="view-conference/:id" element={<ViewConferencePage />} />
          <Route path="edit-conference/:id" element={<EditConferencePage />} />

          <Route path = "all-pages" element={<PageTablePage />} />
          <Route path="add-page" element={<PageCreatePage />} />
          <Route path="view-page/:id" element={<PageViewPage />} />
          <Route path="edit-page/:id" element={<PageEditPage />} />
          <Route path="show-page/:id" element={<PageViewPage />} />

          <Route path="create-role-permissions" element={<CreateRolePermissionsPage/>}/>
          <Route path="edit-role-permissions/:id" element={<EditRolePermissionsPage/>}/>
          <Route path="view-role-permissions/:id" element={<ViewRolePermissionsPage/>}/>
          <Route path="role-permissions" element={<RolePermissionsTablePage/>}/>

          <Route path="/stock" element={<StockPage />} />
          <Route path="/tax" element={<TaxesPage />} />
          <Route path="/report/customer" element={<CustomerChartPage />} />
          <Route path="/report/order" element={<OrderChartPage />} />
          
          <Route path="create-role-permissions" element={<CreateRolePermissionsPage />} />
          <Route path="edit-role-permissions/:id" element={<EditRolePermissionsPage />} />
          <Route path="view-role-permissions/:id" element={<ViewRolePermissionsPage />} />
          <Route path="role-permissions" element={<RolePermissionsTablePage />} />

          <Route path="server-error" element={<ServerError />} />
          <Route path="error" element={<Error />} />
          
          {/* <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} /><ViewMyProfilePage />
          </Route> */}
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />

          <Route path="forgot-password" element={<ForgotPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="/reset-session" element={<ResetSessionFallback />} />
      </Routes>
     
    </BrowserRouter>
  );
};
