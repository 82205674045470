import { get, post } from '@app/api/http.api';

export interface PostCategory {
  id: number;
  name: string;
}

export interface PostCategoryTableRow {
  key: React.Key;
  id: number;
  name: string;
}

export interface PageRequest {
  id: number | null;
  name: string;
  multi_section: boolean | null;
  categories: number[];
}

// export interface PageName {
//   key?: '1' | '2' | '3';
//   name?: string;
// }
export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PageTableData {
  pages:{
    data: PageTableRow[];
  pagination: Pagination;
  };
  post_categories: PostCategory[];
  
}

export interface PageApiData {
  page: Page;
  category_options: PostCategory[];
  // display_name: PageName[];
}

export interface Page {
  id: number | null;
  name: string;
  multi_section: boolean | null;
  category_ids: number[];
}

export interface PageTableRow {
  id: number;
  name: string;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
  category?:string | null;
}

interface PageTableApi{
  pages:PaginatedResponse<PageTableRow>;
  post_categories: PostCategory[];
}

export const getPageTableData = async (parameters:Parameters): Promise<PageTableData> => {
  try {
    const { data, status } = await get<PageTableApi>('pages', { params: parameters }, true);
    console.log(`Status: ${status}`, `Data: ${data}`);

    const result = data.pages.data.map((rawData: PageTableRow) => ({
      id: rawData.id,
      name: rawData.name,
    }));

    return {
      pages:{
      data: result,
      pagination: {
        current: data.pages.current_page,
        pageSize: data.pages.per_page,
        total: data.pages.total,
      },
    },
    post_categories: data.post_categories
  };
  } catch (error: any) {
    throw error;
  }
};

export const getPages = async (): Promise<Page[] | any> => {
  try {
    const { data, status } = await get<Page>(`pages`, undefined, true);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storePages = async (StorePageData: PageRequest) => {
  try {
    const { data, status } = await post<PageRequest>(`pages/create`, StorePageData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const updatePage = async (id: number, UpdatePageData: PageRequest) => {
  try {
    const { data, status } = await post<PageRequest>(`pages/${id}/edit`, UpdatePageData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createPage = async () => {
  try {
    const { data, status } = await get<PageApiData>(`pages/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showPage = async (id: number) => {
  try {
    const { data, status } = await get<PageApiData>(`pages/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editPage = async (id: number) => {
  try {
    const { data, status } = await get<PageApiData>(`pages/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
