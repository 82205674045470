import React, { useEffect, useState, useCallback } from 'react';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Input, Space, Card } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { UsersTable } from '@app/components/tables/BasicTable/UserTable';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';



const AllUsers: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handlebtnClick = () => {
    navigate('/add-user');
  };

  const handleSearchText = (e:any) => {
    setSearchValue(e);
  }


  return (
    <>
    <PageTitle>{('Users')}</PageTitle>
      <Space style={{ marginBottom: '16px', justifyContent: 'space-between', width: '100%' }}>
        <BaseButton icon={<PlusOutlined />} type="primary" onClick={handlebtnClick}>
          {t('Add New User')}
        </BaseButton>

      </Space>

      
        <UsersTable />        
      
    </>
  );
};

export default AllUsers;
