import { BaseTypography } from "@app/components/common/BaseTypography/BaseTypography";
import { FONT_SIZE, FONT_FAMILY, media } from "@app/styles/themes/constants";
import styled from "styled-components";

export const Text = styled(BaseTypography.Text)`
  display: block;

  font-size: ${FONT_SIZE.xxs};

  color: var(--text-nft-light-color);

  font-family: ${FONT_FAMILY.secondary};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xs};
  }
`;