import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Space, Button, Tag, Input, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PostCategoryTableRow, Pagination, getPostCategoryTableData } from '@app/api/postCategories.api';
import { useMounted } from '@app/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { Tooltip } from 'antd';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import type { InputRef, RefSelectProps, TableColumnsType, TableColumnType } from 'antd';
import Highlighter from 'react-highlight-words';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

const initialSearchPagination: Pagination = {
  current: 1,
  pageSize: 5,
  total:5,
};
type DataIndex = keyof PostCategoryTableRow;

export const PostCategoryTable: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: PostCategoryTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const handleErrorMessages = useApiErrorHandler();
  const searchTextRef = useRef<InputRef>(null);
  // const [status,setStatus] = useState('ACTIVE');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getPostCategoryTableData({ ...pagination, search: form.getFieldValue('search'), status: form.getFieldValue('status') })
        .then((res) => {
          if (isMounted.current) {
            setTableData({ data: res.data, pagination: res.pagination, loading: false });
          }
        })
        .catch((error) => {
          handleErrorMessages(error);
        });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    console.log(pagination);
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.id !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const navigate = useNavigate();

  const handleEditRow = (id: number) => {
    navigate(`/edit-post-category/${id}`);
  };

  const handleViewRow = (id: number) => {
    navigate(`/view-post-category/${id}`);
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Post Categories`}</span>
    );
  };

  const columns: ColumnsType<PostCategoryTableRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <>
          <Tag color={value == 'ACTIVE' ? 'blue' : 'red'} key={value}>
            {value}
          </Tag>
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Button
              type="ghost"
              onClick={() => handleViewRow(record.id)}
              icon={<IoEye />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Tooltip title="Edit">
            <Button
              type="ghost"
              onClick={() => handleEditRow(record.id)}
              icon={<MdModeEdit />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          {/* <Tooltip title="Delete">
            <Button
              type="default"
              danger
              onClick={() => handleDeleteRow(record.id)}
              icon={<MdDelete />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

 
  return (
    <BaseCard id="basic-table" title="All Post Categories" padding="1.25rem"
    extra={ <BaseButtonsForm
      form={form}
      layout="inline"
      isFieldsChanged={fieldsChanged}
      setFieldsChanged={setFieldsChanged}
    >
      <BaseButtonsForm.Item name={'search'}>
        <Input
          size="middle"
          suffix={<Button icon={<SearchOutlined />} type='text' onClick={()=>{fetch(initialPagination)}} />}
          ref={searchTextRef}
          placeholder={t('Search Post Categories')}
        />
      </BaseButtonsForm.Item>

      <BaseButtonsForm.Item name={'status'}>
        <Select
          placeholder={'Select Status'}
          options={[
            { value: 'ACTIVE', label: 'Active' },
            { value: 'INACTIVE', label: 'Inactive' },
          ]}
          onChange={()=>{fetch(initialPagination)}}
        />
      </BaseButtonsForm.Item>
      <Button type='ghost' icon={<ReloadOutlined />} 
      onClick={()=>{
        form.resetFields(['status','search']);
        fetch(initialPagination);
        }}/>
    </BaseButtonsForm>}>
      
          <BaseTable
            columns={columns}
            dataSource={tableData.data}
            pagination={{
              current: tableData.pagination.current,
              pageSize: tableData.pagination.pageSize,
              total: tableData.pagination.total,
              showTotal,
            }}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: true, scrollToFirstRowOnChange: true }}
          />
        
    </BaseCard>
  );
};
