import React from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

interface TaxTableRow {
  id: number;
  tax: string;
  rate: number;
  numberOfOrder: number;
  taxAmount: number;
  shippingTaxAmount: number;
  totalTax: number;
}

interface TaxesTableProps {
  data: TaxTableRow[];
}

const TaxesTable: React.FC<TaxesTableProps> = ({ data }) => {
  const columns = [
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Number of Order',
      dataIndex: 'numberOfOrder',
      key: 'numberOfOrder',
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
    },
    {
      title: 'Shipping Tax Amount',
      dataIndex: 'shippingTaxAmount',
      key: 'shippingTaxAmount',
    },
    {
      title: 'Total Tax',
      dataIndex: 'totalTax',
      key: 'totalTax',
    },
  ];

  return (
    // <BaseCard id="basic-table" title="Taxes" padding="1.25rem">
    //   <BaseTable columns={columns} dataSource={data} scroll={{ x: true, scrollToFirstRowOnChange: true }} />
    // </BaseCard>
    <BaseTable columns={columns} dataSource={data} scroll={{ x: true, scrollToFirstRowOnChange: true }} />
  );
};

export default TaxesTable;
