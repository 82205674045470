import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Space, Button, Tag, Popconfirm, Tooltip, Input, InputRef, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useState, useEffect, useCallback, useRef } from 'react';
import { AppointmentTableRow, Pagination, deleteAppointment, getAppointmentTableData } from '@app/api/appointment.api';
import { useMounted } from '@app/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { IoEye } from 'react-icons/io5';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

enum Staff {
  NotApplicable = 'N/A',
  IntermediateAdmin = 'Intermediate Admin',
  JrdcAdmin = 'JRDC Admin',
}

enum AppointmentStatus {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
  PendingConfirmation = 'Pending Confirmation',
  Confirmed = 'Confirmed',
  Complete = 'Complete',
  InCart = 'In Cart',
  Cancelled = 'Cancelled',
}

export const getAppointmentStatusOption = (key: string) => {
  switch (key) {
    case '1': {
      return AppointmentStatus.Unpaid;
    }
    case '2': {
      return AppointmentStatus.Paid;
    }
    case '3': {
      return AppointmentStatus.PendingConfirmation;
    }
    case '4': {
      return AppointmentStatus.Confirmed;
    }
    case '5': {
      return AppointmentStatus.Complete;
    }
    case '6': {
      return AppointmentStatus.InCart;
    }
    case '7': {
      return AppointmentStatus.Cancelled;
    }
    default: {
      return AppointmentStatus.Unpaid;
    }
  }
};

interface AppointmentsTableProps {
  searchText: string;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const AppointmentsTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: AppointmentTableRow[]; pagination: Pagination; loading: boolean }>(
    {
      data: [],
      pagination: initialPagination,
      loading: true,
    },
  );

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const handleErrorMessages = useApiErrorHandler();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const [statuses, setStatuses] = useState<any[]>([])

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getAppointmentTableData({...pagination, search: form.getFieldValue('search'),status:form.getFieldValue('status')})
        .then((res) => {
          setStatuses(res.status_options?.map(e=> ({value:e.key,label:e.name})))
          setTableData({ data: res.appointments.data, pagination: res.appointments.pagination, loading: false });
        })
        .catch((error) => {
          handleErrorMessages(error);
          setTableData((tableData) => ({ ...tableData, loading: false }));
        });
    },
    [handleErrorMessages],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, []);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setLoading(true);
    deleteAppointment(rowId)
      .then(() => {
        setLoading(false);
        fetch(initialPagination);
        notificationController.info({ message: `Only an unpaid or cancelled appointment can be deleted.` });
      })
      .catch((err: any) => {
        setLoading(false);
        handleErrorMessages(err);
        console.error(err);
      });
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Appointments`}</span>
    );
  };

  const getStaffOption = (key: string) => {
    switch (key) {
      case '1': {
        return Staff.NotApplicable;
      }
      case '2': {
        return Staff.IntermediateAdmin;
      }
      case '3': {
        return Staff.JrdcAdmin;
      }
      default: {
        return Staff.NotApplicable;
      }
    }
  };

  const handleEditRow = (id: number) => {
    navigate(`/edit-appointment/${id}`);
  };

  const handleViewRow = (id: number) => {
    navigate(`/view-appointment/${id}`);
  };

  const columns: ColumnsType<AppointmentTableRow> = [
    {
      title: t('Appointment'),
      dataIndex: 'appointment',
      render(_, record) {
        return (
          <>
            <div>{`Order #${record.order_id}`}</div>
            <div>{`${record.customer_name}`}</div>
            <div style={{ marginTop: '5px' }}>
              <Tag color="orange">{record.appointment_status}</Tag>
            </div>
          </>
        );
      },
    },
    {
      title: t('Product'),
      dataIndex: 'product',
      render(_, record) {
        return (
          <>
            <div>{`${record.product_name} (#${record.product_id})`}</div>
            <div>{`x${record.qty}`}</div>
          </>
        );
      },
    },
    {
      title: t('Start Date & Time'),
      dataIndex: 'start_date',
      render(_, record) {
        return <div>{dayjs(record.start_date_time).format('LLL') ?? 'N/A'}</div>;
      },
    },
    {
      title: t('End Date & Time'),
      dataIndex: 'end_date',
      render(_, record) {
        return <div>{dayjs(record.end_date_time).format('LLL') ?? 'N/A'}</div>;
      },
    },
    {
      title: t('Staff'),
      dataIndex: 'staff',
      key: 'staff',
      render(_, record) {
        return <div>{getStaffOption(record.staff)}</div>;
      },
    },
    {
      title: t('All day'),
      dataIndex: 'all_day',
      key: 'all_day',
      render(_, record) {
        return <div>{`${record.all_day}`}</div>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Button
              type="ghost"
              onClick={() => handleViewRow(record.id)}
              icon={<IoEye />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            ></Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="ghost"
              onClick={() => handleEditRow(record.id)}
              icon={<MdModeEdit />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            ></Button>
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this appointment?"
            onConfirm={() => handleDeleteRow(record.id)}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            {/* <Tooltip title="Delete">
              <Button
                type="ghost"
                danger
                icon={<MdDelete />}
                style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
              ></Button>
            </Tooltip> */}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <BaseCard id="basic-table" title="All Appointments" padding="1.25rem" 
    extra={
      <BaseButtonsForm
      form={form}
      layout="inline"
      isFieldsChanged={fieldsChanged}
      setFieldsChanged={setFieldsChanged}
    >
      <BaseButtonsForm.Item name={'status'}>
      <Select size='middle'
          placeholder={'Select Status'}
          options={statuses}
          onChange={()=>{fetch(initialPagination)}}
          dropdownMatchSelectWidth style={{minWidth:'260px'}}
        />
      </BaseButtonsForm.Item>
       
      <BaseButtonsForm.Item name={'search'}>
        <Input
          size='middle'
          suffix={<Button icon={<SearchOutlined  />} size='middle' type='text' onClick={()=>{fetch(initialPagination)}} />}
          placeholder={t('Search Appointments')}
        />
      </BaseButtonsForm.Item>
     
      
      <Button type='ghost' size='middle' icon={<ReloadOutlined />} 
      onClick={()=>{
        form.resetFields(['status','search']);
        fetch(initialPagination);
        }}/>
    </BaseButtonsForm>
    }>
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        current: tableData.pagination.current,
        pageSize: tableData.pagination.pageSize,
        total: tableData.pagination.total,
        showTotal,
      }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: true, scrollToFirstRowOnChange: true }}
    />
    </BaseCard>
  );
};
