import { TableOutlined } from '@ant-design/icons';
import {
  Page,
  PageApiData,
  // PageName,
  PageRequest,
  Pagination,
  PostCategory,
  PostCategoryTableRow,
  createPage,
  editPage,
  showPage,
  storePages,
  updatePage,
} from '@app/api/page.api';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import handleErrorMessages from '@app/components/Error/ErrorMsgHandling';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Checkbox, Select, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoEye } from 'react-icons/io5';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface PagesFormProps extends TypeOfForm {
  editable: boolean;
  id?: string;
  // showEditIcon?: boolean;
  // pageTitle: string;
}

const pagination: Pagination = {
  pageSize: 20,
};

export const PagesForm: React.FC<PagesFormProps> = ({ editable = false, id, type }) => {
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState<Page>();
  const [pageTitle, setPageTitle] = useState<string>('');

  //mutiple drop down
  const [categories, setCategories] = useState<PostCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<PostCategory[]>([]);

  const { t } = useTranslation();
  const pageId = id ? parseInt(id) : null;
  const navigate = useNavigate();

  const handleErrorMessages = useApiErrorHandler(); 

  const setApiData = (res: PageApiData) => {
    console.log(res);
    setCategories(res?.category_options);

    form.setFieldsValue({
      name: res?.page?.name,
      category_ids: res?.page?.category_ids ?? [],
      multi_section:res?.page?.multi_section ?? false,
    });
  };

  useEffect(() => {
    setLoading(true)
    if (pageId) {
      if (type === 'view') {
        showPage(pageId).then((res: any) => {
          setPageTitle(`${res?.page?.name}`);
          setApiData(res);
          setLoading(false);
        })
        .catch((error) =>  handleErrorMessages(error));
      } else if (type === 'edit') {
        editPage(pageId).then((res: any) => {
          setLoading(false);
          console.log(res);
          setPageTitle(`${res?.page?.name}`);
          setApiData(res);
        })
        .catch((error) =>  handleErrorMessages(error));
      }
    } else {
      createPage().then((res: any) => {
        setLoading(false);
        setApiData(res);
        setPageTitle(`New`);
      })
      .catch((error) =>   handleErrorMessages(error));
    }
  }, []);

  const handleSubmit = (values: PageRequest) => {
    setLoading(true);
    if (editable) {
      if (type === 'create') {
        storePages(values)
          .then((res: any) => {
            setLoading(false);
            console.log(res);
            navigate(`/view-page/${res?.data?.id}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      } else if (type === 'edit' && pageId) {
        updatePage(pageId, values)
          .then((res:any) => {
            setLoading(false);
            console.log(res);
            navigate(`/view-page/${pageId}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      }
    }
  };

  const handleEditRow = (id: number) => {
    navigate(`/edit-page/${id}`);
  };

  console.log(selectedCategories)

  return (
    <BaseCard
      id="validation form"
      title={`Page - `+  pageTitle || 'New'}
      padding="1.25rem"
      style={{ margin: '20px', marginTop: '0px', width: '98%' }}
      extra={
        <>
        { 
           type === 'view' && (
             <>
               <Space>
                 <Tooltip title={t('common.edit')}>
                   <BaseButton type="text" onClick={() => navigate(`/edit-page/${id}`)} icon={<MdModeEdit />} />
                 </Tooltip>
               </Space>
             </>
           )
         ||
           type === 'edit' && (
             <>
               <Space>
                 <Tooltip title={t('view')}>
                   <BaseButton type="text" onClick={() => navigate(`/view-page/${id}`)} icon={<IoEye />} />
                 </Tooltip>
               </Space>
             </>
           )
         }
         <Space>
           <Tooltip title={'View all'}>
             <BaseButton type="text" onClick={() => navigate('/all-pages')} icon={<TableOutlined />} />
           </Tooltip>
         </Space>
       </> 
         
       }
    >
      <BaseButtonsForm
        name="pagesForm"
        onFinish={handleSubmit}
        form={form}
        loading={isLoading}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item
              name="name"
              label="Page Name"
              rules={[{ required: true, message: 'Page Name is required' }]}
            >
              <BaseInput placeholder="Enter page name" disabled={!editable} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={24} lg={24}>
              <BaseButtonsForm.Item
                name="category_ids"
                label="Page Categories"
                rules={[{ required: true, message: 'Page Categories are required' }]}
              >
                <BaseSelect
                  placeholder="Select Page Categories"
                  disabled={!editable}
                  allowClear
                  mode="multiple"
                  onChange={(option: any) => {
                    const values = categories.filter((e) => option?.includes(e.id));
                    setSelectedCategories([...values]);
                  }}
                  value={selectedCategories ? selectedCategories.map((category) => category.id) : []} 
                >
                  {categories?.map((option) => (
                    <>
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    </>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
        </BaseRow>
          <>
            <BaseButton
              type="ghost"
              size="small"
              htmlType="button"
              loading={isLoading}
              disabled={!editable}
              onClick={() => {
                form.setFieldValue('category_ids', categories.map((e) => e.id));
                setSelectedCategories(categories);
              }}  
            >
              select all
            </BaseButton>
          </>

        <br />
        <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={24}>
              <BaseButtonsForm.Item name="multi_section" valuePropName="checked" label="Section">
                <Checkbox>
                  <p style={{ fontSize: 13 }}>Check this box if there are multiple sections.</p>
                </Checkbox>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        <br />
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading} disabled={!editable}>
            {t('Save Page')}
          </BaseButton>
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </BaseCard>
  );
};

export default PagesForm;
