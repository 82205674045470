import React from 'react';
import { NFTCard } from '../common/NFTCard/NFTCard';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Divider, Space, Statistic } from 'antd';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import * as S from './AtAGlance.style';
import { DashboardData } from '@app/pages/DashboardPages/NftDashboardPage';

interface AtAGlanceProps{
  data : DashboardData | null;
}

  const AtAGlance:React.FC<AtAGlanceProps> = ({data}) => {
  return (
    <NFTCard>
      <BaseRow justify={'space-evenly'}>
        <BaseCol span={8}>
          <Statistic value={0}></Statistic>
          <S.Text>Comments</S.Text>
        </BaseCol>
        <BaseCol span={8}>
          <Statistic value={data?.posts}></Statistic>
          <S.Text>Posts</S.Text>
        </BaseCol>
        <BaseCol span={8}>
          <Statistic value={data?.pages}></Statistic>
          <S.Text>Pages</S.Text>
        </BaseCol>
      </BaseRow>
    </NFTCard>
  );
};

export default AtAGlance;