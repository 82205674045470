import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventForm } from '@app/components/forms/EventForm/EventForm'; 
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import {TypeOfEvent} from '@app/api/event.api';

const AddEventPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <PageTitle>{t('Add-Event')}</PageTitle>  */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <EventForm type={"create"} editable={true}  eventType={TypeOfEvent.EVENT}/>
    </>
  );
};

export default AddEventPage;
