import React from 'react';
import {
  ScheduleOutlined,
  ShopOutlined,
  AppstoreOutlined,
  TeamOutlined,
  UserOutlined,
  VideoCameraOutlined,
  ClockCircleOutlined,
  SettingOutlined,
  LineChartOutlined,
  FileTextOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  CopyOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';
import { PRODUCT_CATEGORIES_PATH } from '@app/components/router/AppRouter';

export interface SidebarNavigationItem {
  model?: string;
  policy?: string;
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    policy: 'dashboard',
    model: 'Menu',
    title: 'Dashboard',
    key: 'dashboard',
    // TODO use path variable
    url: '/',
    icon: <AppstoreOutlined />,
  },
  {
    policy: 'ecom',
    model: 'Menu',
    title: 'Ecom',
    key: 'ecom',
    icon: <ClockCircleOutlined />,
    children: [
      {
        policy: 'viewAny',
        model: 'Appointment',
        title: 'Appointments',
        key: 'all-appointments',
        url: '/all-appointments',
        icon: <ClockCircleOutlined />,
      },
      {
        policy: 'viewAny',
        model: 'Appointment',
        title: 'Calendar',
        key: 'appointment-calendar',
        url: '/appointment-calendar',
        icon: <ClockCircleOutlined />,
      },
      {
        model: 'Order',
        policy: 'viewAny',
        title: 'Orders',
        key: 'all-orders',
        url: '/orders',
        icon: <ScheduleOutlined />,
      },
      {
        policy: 'viewAny',
        title: 'Categories',
        model: 'ProductCategory',
        key: 'Categories',
        url: '/product-categories',
        icon: <ShopOutlined />,
      },
      {
        policy: 'viewAny',
        model: 'Product',
        title: 'Products',
        key: 'all-products',
        url: '/all-products',
        icon: <ShopOutlined />,
      },
      {
        model: 'Meeting',
        policy: 'viewAny',
        title: 'eZoom Meetings',
        key: 'Meetings',
        url: 'all-meetings',
        icon: <VideoCameraOutlined />,
      },
      {
        model: 'Event',
        policy: 'viewAny',
        title: 'Events',
        key: 'All events',
        url: '/all-events',
        icon: <CalendarOutlined />,
      },
      {
        model: 'Event',
        policy: 'viewAny',
        title: 'Conferences',
        key: 'All conferences',
        url: '/all-conferences',
        icon: <CalendarOutlined />,
      },
      {
        model: 'Customer',
        policy: 'viewAny',
        title: 'Customers',
        key: 'Custmers',
        url: 'all-customers',
        icon: <UserOutlined />,
      },
    ],
  },
  {
    model: 'Menu',
    policy: 'setting',
    title: 'Settings',
    key: 'settings',
    icon: <SettingOutlined />,
    children: [
      {
        model: 'User',
        policy: 'viewAny',
        title: 'Profile',
        key: 'profile',
        url: '/profile',
        icon: <UserOutlined />,
      },
      {
        model: 'User',
        policy: 'viewAny',
        title: 'Users',
        key: 'All Users',
        url: 'all-users',
        icon: <TeamOutlined />,
      },
      {
        model: 'Role',
        policy: 'viewAny',
        title: 'Roles',
        key: 'role-permissions',
        url: 'role-permissions',
        icon: <SettingOutlined />,
      },
    ],
  },
  {
    model: 'Menu',
    policy: 'report',
    title: 'Reports',
    key: 'Report',
    icon: <LineChartOutlined />,
    children: [
      {
        model: 'Report',
        policy: 'order',
        title: 'Orders',
        key: 'Orders',
        url: '/report/order',
        icon: <LineChartOutlined />,
      },
      {
        model: 'Report',
        policy: 'customer',
        title: 'Customers',
        key: 'Customers',
        url: '/report/customer',
        icon: <LineChartOutlined />,
      },
      {
        model: 'Report',
        policy: 'stock',
        title: 'Stock',
        key: 'Stock',
        url: '/report/stock',
        icon: <LineChartOutlined />,
      },
      {
        model: 'Report',
        policy: 'tax',
        title: 'Taxes',
        key: 'Taxes',
        url: '/report/tax',
        icon: <LineChartOutlined />,
      },
    ],
  },
  {
    model: 'Menu',
    policy: 'web',
    title: 'JRDC Web',
    key: 'Posts',
    icon: <FileTextOutlined />,
    children: [
      {
        model: 'PostCategory',
        policy: 'viewAny',
        title: 'Post Categories',
        key: 'Post Categories',
        url: '/post-categories',
        icon: <FileTextOutlined />,
      },
      {
        model: 'Post',
        policy: 'viewAny',
        title: 'Posts',
        key: 'All Posts',
        url: '/post',
        icon: <FileTextOutlined />,
      },
      {
        model: 'Page',
        policy: 'viewAny',
        title: 'Pages',
        key: 'pages',
        url: '/all-pages',
        icon: <CopyOutlined />,
      },
    ],
  },
];
