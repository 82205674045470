import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PagesForm } from '@app/components/forms/PagesForm/PagesForm';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const PageEditPage:React.FC = () => {
  const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

  return (
    <>
      {/* <PageTitle>{t('Edit-Page')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <PagesForm type={"edit"}  editable = {true} id={id}/>
    </>
  );
  
}