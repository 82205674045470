import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getAppointmentTableData, AppointmentTableRow } from '@app/api/appointment.api';
import { Button, Avatar, Modal } from 'antd';
import { UserOutlined, PhoneOutlined, MailOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const getStatusColor = (status: string) => {
  switch (status) {
    case '1':
      return 'rgba(255, 0, 0, 0.5)'; // Red // Unpaid
    case '2':
      return 'rgba(0, 128, 0, 0.5)'; // Green // Paid
    case '3':
      return 'rgb(100, 149, 237)'; // Light blue // Pending Confirmation
    case '4':
      return 'rgba(0, 0, 255, 0.5)'; // Blue // Confirmed
    case '5':
      return 'rgba(128, 0, 128, 0.5)'; // Purple // Complete
    case '6':
      return 'rgba(255, 165, 0, 0.5)'; // Orange // In Cart
    case '7':
      return 'rgba(128, 128, 128, 0.5)'; // Grey // Cancelled
    default:
      return 'rgba(0, 0, 0, 0.5)'; // Default color (black)
  }
};

const AppointmentsCalendar = () => {
  const [appointmentsData, setAppointmentsData] = useState<AppointmentTableRow[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentTableRow | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAppointmentTableData({ current: 1, pageSize: 5 })
      .then((res) => {
        setAppointmentsData(res.appointments.data);
      })
      .catch((error) => {
        console.error('Error fetching appointments:', error);
      });
  }, []);

  const handleAppointmentClick = (appointmentData: AppointmentTableRow) => {
    console.log('Clicked Appointment ID:', appointmentData.id);
    console.log('Clicked Appointment:', appointmentData);

    setSelectedAppointment(appointmentData);
    setModalVisible(true);
  };

  const handlePopupClose = () => {
    setSelectedAppointment(null);
    setModalVisible(false);
  };

  const handleEditAppointment = () => {
    if (selectedAppointment) {
      navigate(`/edit-appointment/${selectedAppointment.id}`);
    }
  };

  enum AppointmentStatus {
    Unpaid = 'Unpaid',
    Paid = 'Paid',
    PendingConfirmation = 'Pending Confirmation',
    Confirmed = 'Confirmed',
    Complete = 'Complete',
    InCart = 'In Cart',
    Cancelled = 'Cancelled',
  }

  const getAppointmentStatusOption = (key: string) => {
    switch (key) {
      case '1': {
        return AppointmentStatus.Unpaid;
      }
      case '2': {
        return AppointmentStatus.Paid;
      }
      case '3': {
        return AppointmentStatus.PendingConfirmation;
      }
      case '4': {
        return AppointmentStatus.Confirmed;
      }
      case '5': {
        return AppointmentStatus.Complete;
      }
      case '6': {
        return AppointmentStatus.InCart;
      }
      case '7': {
        return AppointmentStatus.Cancelled;
      }
      default: {
        return AppointmentStatus.Unpaid;
      }
    }
  };

  enum Customer_Status {
    NoShow = 'No Show',
    Arrived = 'Arrived',
  }

  const getCustomerStatus = (key: string) => {
    if (key === '1') {
      return Customer_Status.NoShow;
    } else {
      return Customer_Status.Arrived;
    }
  };

  const calculateDuration = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffInMs = endDate.getTime() - startDate.getTime();
    if (diffInMs < 0) return '';
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    if (diffInDays === 0) {
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
      return `${diffInHours} hours`;
    } else {
      return `${diffInDays} days`;
    }
  };

  const formatDateTime = (dateTimeString: string | undefined) => {
    if (!dateTimeString) return '';
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
    return formattedDate;
  };

  const LabelValuePair = ({ label, value }: { label: string; value: string }) => (
    <tr>
      <td style={{ textAlign: 'left', verticalAlign: 'top', lineHeight: '2.2' }}>
        <b style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}>{label}:</b>
      </td>
      <td style={{ paddingLeft: '10px', color: '#778', verticalAlign: 'top', lineHeight: '2.2' }}>
        <span style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}>{value}</span>
      </td>
    </tr>
  );

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        selectable={true}
        weekends={true}
        events={appointmentsData.map((appointment) => ({
          title: `${appointment.bill_first_name} ${appointment.bill_last_name}`,
          start: appointment.start_date_time,
          end: appointment.end_date_time,
          allDay: appointment.all_day,
          backgroundColor: getStatusColor(appointment.status),
          appointmentData: appointment,
        }))}
        eventClick={(info) => handleAppointmentClick(info.event.extendedProps.appointmentData)}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear',
        }}
      />
      <Modal
        visible={modalVisible}
        onCancel={handlePopupClose}
        footer={[
          <Button key="edit" type="primary" onClick={handleEditAppointment}>
            <EditOutlined /> Edit
          </Button>,
        ]}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar size={64} icon={<UserOutlined />} />
              <div style={{ marginLeft: '10px' }}>
                <div>{`${selectedAppointment?.bill_first_name} ${selectedAppointment?.bill_last_name}`}</div>
                <div style={{ fontSize: '12px', color: 'gray' }}>
                  <i>{getCustomerStatus(selectedAppointment?.customer_status || '')}</i>
                </div>
              </div>
            </div>
            <div>
              <Button type="link" icon={<PhoneOutlined />} href={`tel:${selectedAppointment?.bill_phone_no}`}>
                {selectedAppointment?.bill_phone_no}
              </Button>
              <Button type="link" icon={<MailOutlined />} href={`mailto:${selectedAppointment?.bill_email}`}>
                {selectedAppointment?.bill_email}
              </Button>
            </div>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <LabelValuePair
                  label="Product"
                  value={`${selectedAppointment?.qty} * ${selectedAppointment?.product_name}`}
                />
                <LabelValuePair
                  label="When"
                  value={`${formatDateTime(selectedAppointment?.start_date_time)} - ${formatDateTime(
                    selectedAppointment?.end_date_time,
                  )}`}
                />
                <LabelValuePair
                  label="Duration"
                  value={calculateDuration(
                    selectedAppointment?.start_date_time || '',
                    selectedAppointment?.end_date_time || '',
                  )}
                />
                <LabelValuePair label="Status" value={getAppointmentStatusOption(selectedAppointment?.status || '')} />
                <LabelValuePair
                  label="Cost"
                  value={`Rs. ${selectedAppointment ? selectedAppointment.sale_price * selectedAppointment.qty : 0}`}
                />
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AppointmentsCalendar;
