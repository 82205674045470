import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

interface Props {
  image: string | null;
  setImage: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ProfileImageItem: React.FC<Props> = ({ image, setImage }) => {
  const { t } = useTranslation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <BaseButtonsForm.Item name="image" label={t('Profile Picture')}>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <br />
      {image && <img src={image} alt="Profile" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />}
      
    </BaseButtonsForm.Item>
  );
};
