import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'antd';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import {Loading} from '@app/components/common/Loading/Loading'; 
import {
  completepayment,
  varifyPayment
  } from '@app/api/order.api';

interface PaymentVerifyProps {
  orderId: number;
  setStatusTriggeredTo: () => void;
}

const PaymentVerify: React.FC<PaymentVerifyProps> = ({ orderId,setStatusTriggeredTo }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [transactionData, setTransactionData] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [isCompleting, setCompleting] = useState(false);

  const handleErrorMessages = useApiErrorHandler();

  const fetchTransactionData = async () => {
    setLoading(true);
    varifyPayment(orderId)
          .then((res: any) => {
            console.log('wefefe', res);
            setTransactionData(res)
            setLoading(false);
          })
          // .catch((err: any) => console.error(err.message));
          .catch((error) => handleErrorMessages(error));
          setLoading(false);
  };

  const handlePaymentClick = () => {
    setOpen(true);
    fetchTransactionData();
  };

  const handleCompleteClick = () => {
    setCompleting(true);
    completepayment(orderId)
        .then((response: any) => {
          setCompleting(false);
          setOpen(false); 
          setTransactionData(null);
          setStatusTriggeredTo();
        })
        .catch((err: any) => {
          setCompleting(false);
          handleErrorMessages(err);
        });
    
   };

  return (
    <>
      {orderId && (
        <>
          <Button
            type="default"
            loading={isLoading}
            htmlType="button"
            onClick={handlePaymentClick}
          >
            Payment Verify
          </Button>
          <Modal  open={open} title="Payment Transaction" footer={null} closable={true} onCancel={() => setOpen(false)}>
            {transactionData ? (
              <div>
                <p>Status: {transactionData.status}</p>
                {transactionData.status == 'SUCCESS' && (
                  <>
                  <p>Amount: {transactionData?.transaction?.transaction?.currency} {transactionData?.transaction?.transaction?.amount}</p>
                  <p>Transaction Reference: {transactionData?.transaction?.transaction?.reference}</p>
                  <p>Transaction ID: {transactionData?.transaction?.transaction?.receipt}</p>
                  <p>Ip: {transactionData?.transaction?.device?.ipAddress}</p>

                  <p>Order Id: {transactionData?.transaction?.order?.id}</p>
                  <p>Order Reference: {transactionData?.transaction?.order?.reference}</p>
                  <p>Order Customer Reference: {transactionData?.transaction?.order?.customerReference}</p>
                  <p>Order Customer Date: {transactionData?.transaction?.order?.customerOrderDate}</p>

                  <p>Last Updated At: {transactionData?.transaction?.timeOfLastUpdate}</p>
                </>
                )}
              </div>
            ) : (
              <Loading size='4em'/>
            )}
            <Row gutter={[16, 16]} justify="end">
              {transactionData && transactionData.status == 'SUCCESS' &&
              <>
              <Col>
                <Button type="default" loading={isCompleting} htmlType="button" onClick={handleCompleteClick}>
                  Complete
                </Button>
              </Col>
              </>
              }
              <Col>
                <Button type="primary" htmlType="button" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </>
  );
};

export default PaymentVerify;
