import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BaseCarousel } from '@app/components/common/BaseCarousel/Carousel';
import { ViewAll } from '@app/components/nft-dashboard/common/ViewAll/ViewAll';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { TrendingCollection } from '@app/components/nft-dashboard/trending-collections/collection/TrendingCollection';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './TrendingCollections.styles';
import * as T from './collection/TrendingCollection.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { DashboardData } from '@app/pages/DashboardPages/NftDashboardPage';

interface TrendingCollectionsProps{
  data : TrendingActivity[];
}

export interface TrendingActivity{
  key:number;
  qty_sum: number,
      order_count: number,
      net_sales: number,
      product_id: number,
      name: string,
      image_url: string,
}

export const TrendingCollections: React.FC<TrendingCollectionsProps> = ({data}) => {
  

  const { mobileOnly, isTablet: isTabletOrHigher } = useResponsive();

  // useEffect(()=>{
  //   setTrending(data)
  // },[trending])

  const { t } = useTranslation();

  // const trendingList = useMemo(() => {
  //   console.log(trending)
  //   return {
  //     mobile: data?.map((item, index) => <TrendingCollection  {...item} key={item?.key}/>).slice(0, 3),
  //     tablet: data?.map((item, index) => (
  //       <div key={index}>
  //         <S.CardWrapper>
  //           <TrendingCollection {...item} />
  //         </S.CardWrapper>
  //       </div>
  //     )),
  //   };
  // }, [data]);

  const sliderRef = useRef<Slider>(null);

   

  return (
    <>
      <NFTCardHeader title={'Trending products'}>
        {/* {isTabletOrHigher && ( */}
          <BaseRow align="middle">
            <BaseCol>
              <ViewAll bordered={false} />
            </BaseCol>

            <BaseCol>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
                <LeftOutlined />
              </S.ArrowBtn>
            </BaseCol>

            <BaseCol>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickNext()}>
                <RightOutlined />
              </S.ArrowBtn>
            </BaseCol>
          </BaseRow>
        {/* )} */}
      </NFTCardHeader>

      <S.SectionWrapper>
        {/* {mobileOnly && (data?.map((item, index) => <TrendingCollection  {...item} key={item?.key}/>).slice(0, 3))} */}

        { data.length > 0 && (
          <BaseCarousel
            ref={sliderRef}
            slidesToShow={3}
            responsive={[
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: 2,
                },
              }
            ]}
          >
            {data?.map((item, index) => (
        <div key={index}>
          <S.CardWrapper>
          <T.Card padding={0} style={{minWidth:'276px'}}
     $img={item.image_url}
    >
      <T.CollectionImage src={item.image_url !== ''? item.image_url : 'error'} alt="nft" /> 
      {/* <S.BidButton type="ghost">{`${qty_sum} items`}</S.BidButton> */}
      <T.NftCollectionInfo>
        {/* <S.AuthorAvatarWrapper>
          <BaseAvatar shape="circle" size={64}>{``}</BaseAvatar>
        </S.AuthorAvatarWrapper> */}
        <T.InfoRow>
          <T.Title level={5}>{item.name}</T.Title>
        </T.InfoRow>
        <T.InfoRow>
          <T.OwnerText>
             {item.order_count + ' orders'}
          </T.OwnerText>
          <T.USDText>{`LKR ${item.net_sales.toFixed(2)}`}</T.USDText>
        </T.InfoRow>
      </T.NftCollectionInfo>
    </T.Card>
          </S.CardWrapper>
        </div>
      ))}
          </BaseCarousel>
        )}
      </S.SectionWrapper>

      {/* {mobileOnly && (
        <S.ViewAllWrapper>
          <ViewAll />
        </S.ViewAllWrapper>
      )} */}
    </>
  );
};
