import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryForm } from '@app/components/forms/CategoryForm/CategoryForm';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { showCategory } from '@app/api/category.api';

interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const ViewCategoryPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  return (
    <>
        <PageTitle>{"JRDC-Admin"}</PageTitle>

        <CategoryForm type={"view"} id={id} editable={false}  />
    
    </>
  );
};

export default ViewCategoryPage;
