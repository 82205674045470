import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PagesForm } from '@app/components/forms/PagesForm/PagesForm';
import React from 'react'
import { useTranslation } from 'react-i18next';

export const PageCreatePage:React.FC = () => {
 
    const { t } = useTranslation();

  return (
    <>
      {/* <PageTitle>{t('Add-Page')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <PagesForm type={"create"}  editable = {true}/>
    </>
  );
  
}