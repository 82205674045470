import { PageTitle } from '@app/components/common/PageTitle/PageTitle'
import { MeetingForm } from '@app/components/forms/MeetingForm/MeetingForm';
import React from 'react'
import { useTranslation } from 'react-i18next';

export const CreateMeetingPage:React.FC = () => {
    const { t } = useTranslation();
    
  return (
    <>
      <PageTitle>{t('Add-Meeting')}</PageTitle>
      <MeetingForm type={"create"}  editable = {true}/>
    </>
  )
}
