import React from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate,useLocation  } from 'react-router-dom';
import ErrorBoundary from '@app/components/Error/ErrorBoundary';
import error404 from '@app/assets/images/error404.svg';
import error403 from '@app/assets/images/error403.svg';
import error500 from '@app/assets/images/error500.svg';

const useApiErrorHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorUrl = window.location.href;
  
  const handleErrorMessages = (error: any) => {
    switch (error.status) {
      case 401: {
        notificationController.error({
          message:'Error:',
          description:`${error.message}`
        });
        navigate('/reset-session');
        break;
      }
      case 403: {
        navigate('/Error',{ replace: true, state: { status: '403' ,img:error403, errorUrl: errorUrl,errorTitle:'Forbidden' , errorMessage:"You don't have permission to access this resource' is displayed when a web server recognizes a user's request but is unable to allow additional access"} });
        break;
      }
      case 404: {
        navigate('/Error',{ replace: true, state: { status: '404' ,img:error404, errorUrl: errorUrl,errorTitle:'Page Not Found' , errorMessage:' The server cannot find the requested resource. Links that lead to a 404 page are often called broken or dead links and can be subject to link rot. A 404 status code only indicates that the resource is missing: not whether the absence is temporary or permanent'} });
        break;
      }
      
      case 422: {
        notificationController.error({
          message:'Error:',
          description:`${error.message}`
        });
        break;
      }

      case 500: {
        navigate('/Error',{ replace: true, state: { status: '500' , img: error500,errorUrl: errorUrl,errorTitle:'Internal Server Error' , errorMessage:'The server has encountered an unexpected condition or configuration problem that prevents it from fulfilling the request made by the browser or client'} });
        break;
      }

      default:{
        navigate('/Error',{ replace: true, state: { status: error.status , errorUrl: errorUrl,errorTitle:'oops!', errorMessage:error?.message || 'Something went wrong'} });
        break;
      }
    }
   
  };
  return handleErrorMessages;
};

export default useApiErrorHandler;
