import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Select, Space, Tooltip, message, notification } from 'antd';
import { ShoppingCartOutlined, ProfileOutlined, TableOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseCol } from '../../../components/common/BaseCol/BaseCol';
import { BaseRow } from '../../../components/common/BaseRow/BaseRow';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '../../../components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import {
  OrderRequest,
  storeOrder,
  updateOrder,
  showOrder,
  createOrder,
  OrderApiData,
  Order,
  editOrder,
  DirectTransferData,
  CustomerOption,
  OrderStatusOption,
  ReceiptStatusOption,
  DirectTransfer,
  Product,
  OrderLog,
  getOrderRelatedFromCustomer,
  OrderInvoice,
  cancelledOrder,
  refundedOrder,
  WfAbilities,
  refundOrder,
  EditAbilities,
  OrderConfigs,
} from '@app/api/order.api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { OrderItemsTable } from '@app/components/tables/editableTable/OrderItemsTable';
import { CustomerStatusOption, AppointmentStatusOption, StaffOption } from '@app/api/appointment.api';
import dayjs from 'dayjs';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import * as c from 'countries-list';
import { UploadReceiptSection } from './UploadReceiptSection';
import { PrinctInvoiceSection } from './PrinctInvoiceSection';
import OrderNotesSection from './OrderNotesSection';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import styled from 'styled-components';
import  PaymentVerify  from './PaymentVerify';

export const WarnButton = styled(Button)`
  background-color: var(--warning-color);
  border: 1px solid var(--warning-color);

  &:hover {
    background-color: #ffb15595;
    border: 1px solid #ffb15595;
    color: white;
  }
`;

export const SuccessButton = styled(Button)`
  background-color: var(--success-color);
  border: 1px solid var(--success-color);

  &:hover {
    background-color: #30af5b95;
    border: 1px solid #30af5b95;
    color: white;
  }
`;

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface OrderFormProps extends TypeOfForm {
  editable: boolean;
  id?: string;
}

interface Appointment {
  id?: number;
  customer_status: string;
  appointment_status: string;
  status: string;
  start_date_time: string;
  end_date_time: string;
  all_day: boolean;
  customer_timezone: string;
  staff: string;
}

interface Attachment {
  id?: number;
  name: string;
  abstract: string;
  url: string;
  file: string;
}

export interface OrderLineItem {
  id: number | null;
  qty: number;
  cost: number;
  total: number;
  tax: number;
  product_id: number | null;
  isSummaryLine: boolean;
  summaryLine?: string;
  appointment: Appointment | null;
  //attachment : Attachment | null;
  product: Product | null;
  action: '1' | '2' | '3' | '4';
}

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const OrdersForm: React.FC<OrderFormProps> = ({ type, id, editable = false }) => {
  const countryOptions = Object.entries(c.countries).map(([code, country]) => ({
    code: code,
    name: country.name,
  }));
  const filterOption = (input: string, option: any): boolean => {
    const optionChildren = option?.children?.toString().toLowerCase() || '';
    return optionChildren.indexOf(input.toLowerCase()) >= 0;
  };
  const { t } = useTranslation();
  const orderId = id ? parseInt(id) : null;
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([]);
  const navigate = useNavigate();
  const [form] = BaseButtonsForm.useForm();
  const [directTransferForm] = BaseButtonsForm.useForm();
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [orderStatuses, setOrderStatuses] = useState<OrderStatusOption[]>([]);
  const [receiptStatuses, setReceiptStatuses] = useState<ReceiptStatusOption[]>([]);
  const [lastStatusChange, setLastStatusChange] = useState<moment.Moment | null>(null);
  const [receiptStatuschange, setReceiptStatuschange] = useState<string | null>(null);
  const [selectedReceiptImage, setSelectedReceiptImage] = useState<string | null>(null);
  const [receiptImageUrl, setReceiptImageUrl] = useState<string>('');
  const [customerStatusOptions, setCustomerStatusOptions] = useState<CustomerStatusOption[]>([]);
  const [appointmentStatusOptions, setAppointmentStatusOptions] = useState<AppointmentStatusOption[]>([]);
  const [staffOptions, setStaffOptions] = useState<StaffOption[]>([]);
  const [orderNotes, setOrderNotes] = useState<OrderLog[]>([]);
  const [userNotes, setUserNotes] = useState<OrderLog[]>([]);
  const [orderInvoice, setOrderInvoice] = useState<OrderInvoice | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [createdDate, setCreatedDate] = useState<string>('');
  const [createdTime, setCreatedTime] = useState<string>('');
  const [isRefundFieldsChanged, setRefundFieldsChanged] = useState(false);
  const [refundform] = BaseButtonsForm.useForm();
  const [open, setOpen] = useState(false);
  const [isCancelledOrder, setIsCancelledOrder] = useState(false);
  const handleErrorMessages = useApiErrorHandler();
  const [wfAbilities, setWfAbilities] = useState<WfAbilities>({
    cancelled: false,
    refund: false,
    refunded: false,
  });
  const [orderConfigs, setOrderConfigs] = useState<OrderConfigs>({
    detault_order_status: "1",
    default_appointment_status: "1",
    default_payment_method: "pre",
    confirmed_appointment_status: "4",
    rejection_statuses:[],
    confirmation_statuses: [],
    ol_total_reject_statuses: ["3"]
  });
  const [canPaymentVerify, setCanPaymentVerify] = useState(false);
  const [EditAbilities, setEditAbilities] = useState<EditAbilities>({
    order: false,
    receipt: false,
  });
  const [statusTriggeredTo, setStatusTriggeredTo] = useState('');
  const handleSubmit = async () => {
    setLoading(true);

    const values = await form.validateFields();

    console.log(orderItems);

    const orderLinesData: any[] = orderItems
      ?.filter((item) => item?.action !== '3')
      .map((item) => {
        switch (item.action) {
          case '1':
          case '2': {
            if (item?.product?.is_appointable) {
              return {
                id: item?.id,
                product_id: item?.product_id,
                unit_price: item?.cost,
                qty: item?.qty,
                total: item?.total,
                action: item?.action,
                appointment: {
                  id: item?.appointment?.id ?? null,
                  customer_status: item?.appointment?.customer_status,
                  status: item?.appointment?.status,
                  staff: item?.appointment?.staff,
                  all_day: item?.appointment?.all_day,
                  customer_timezone: item?.appointment?.customer_timezone,
                  start_date_time: dayjs(item?.appointment?.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
                  end_date_time: dayjs(item?.appointment?.end_date_time).format('YYYY-MM-DD HH:mm:ss'),
                },
              };
            } else {
              return {
                id: item?.id,
                product_id: item?.product_id,
                unit_price: item?.cost,
                qty: item?.qty,
                total: item?.total,
                action: item?.action,
                appointment: null,
              };
            }
          }
          case '4': {
            return item;
          }
        }
      });

    console.log(orderLinesData);

    const directTransferValues = await directTransferForm.validateFields();

    const directTransferData: DirectTransferData = {
      image: selectedReceiptImage,
      status: directTransferValues.receipt_status,
      note: directTransferValues.note,
    };

    const orderData: OrderRequest = {
      status: values?.status,
      customer_id: values?.customer_id,
      bill_first_name: values?.bill_first_name,
      bill_last_name: values?.bill_last_name,
      ref_no: values.ref_no || null,
      ip: values?.ip || null,
      bill_company: values?.bill_company,
      bill_address_line1: values?.bill_address_line1,
      bill_address_line2: values?.bill_address_line2,
      bill_city: values?.bill_city,
      bill_postcode: values?.bill_postcode,
      bill_country: values?.bill_country,
      bill_state: values?.bill_state,
      bill_email: values?.bill_email,
      bill_phone_no: values?.bill_phone_no,
      bill_payment_method: values?.bill_payment_method ?? 'pre',
      bill_transaction_id: values?.bill_transaction_id,
      total:
        orderItems?.reduce((sum, row) => sum + parseFloat(row.total), 0) +
        orderItems?.reduce((sum, row) => sum + parseFloat(row.tax), 0),
      items_total: orderItems?.reduce((sum, row) => sum + parseFloat(row.total), 0),
      total_items_qty: orderItems?.reduce((sum, row) => sum + row.qty, 0),
      order_lines: orderLinesData,
      direct_transfer: directTransferData,
      order_notes: userNotes,
      additional_note: values?.additional_note ?? null
    };

    if (type === 'create') {
      console.log('Order Data:', orderData);
      storeOrder(orderData)
        .then((response: any) => {
          setLoading(false);
          navigate(`/show-order/${response?.data?.id}`);
        })
        .catch((err: any) => {
          setLoading(false);
          handleErrorMessages(err);
        });
    } else if (type === 'edit' && orderId) {
      console.log('Order Data:', orderData);
      updateOrder(orderId, orderData)
        .then((response) => {
          setLoading(false);
          navigate(`/show-order/${orderId}`);
        })
        .catch((err: any) => {
          setLoading(false);
          handleErrorMessages(err);
        });
    }
  };

  const setApiData = (res: OrderApiData) => {
    setCanPaymentVerify(res?.order?.can_payment_verify || false);
    setProducts(res?.products || []);
    setCustomerStatusOptions(res?.customer_status_options || []);
    setAppointmentStatusOptions(res?.appointment_status_options || []);
    setStaffOptions(res?.staff_options || []);
    setCustomerOptions(res?.customer_options || []);
    setOrderStatuses(res?.order_status_options || []);
    setReceiptStatuses(res?.receipt_status_options ?? []);
    setReceiptImageUrl(res?.order?.direct_transfer?.image_url || '');
    setLastStatusChange(moment(res?.order?.direct_transfer?.updated_at));
    setOrderNotes(res.order?.order_notes || []);
    setOrderInvoice(res.order?.invoice || null);
    setOrderConfigs(res?.configs || orderConfigs);
    setEditAbilities({
      order: (res?.edit_abilities?.order ?? false) && editable,
      receipt: (res?.edit_abilities?.receipt ?? false) && editable
    });
    setIsCancelledOrder(res.order?.is_cancelled_order ?? false);
    const orderLineItems: OrderLineItem[] =
      res?.order?.order_lines?.map((orderline: any) => ({
        id: orderline?.id,
        product_id: orderline?.product?.id,
        product: orderline?.product,
        qty: orderline?.qty,
        cost: orderline?.unit_price,
        total: orderline?.total,
        tax: 0,
        isSummaryLine: false,
        action: '3',
        paper: orderline?.paper ?? null,
        appointment: orderline?.appointment
          ? {
              id: orderline?.appointment?.id,
              customer_status: orderline?.appointment?.customer_status ?? '1',
              status: orderline?.appointment?.status ?? orderConfigs.default_appointment_status,
              start_date_time:
                type === 'view'
                  ? orderline?.appointment?.start_date_time ?? 'N/A'
                  : dayjs(orderline?.appointment?.start_date_time).toString(),
              end_date_time:
                type === 'view'
                  ? orderline?.appointment?.start_date_time ?? 'N/A'
                  : dayjs(orderline?.appointment?.end_date_time).toString(),
              all_day: orderline?.appointment?.all_day ?? false,
              customer_timezone: orderline?.appointment?.customer_timezone ?? '',
              staff: orderline?.appointment?.staff ?? '1',
              appointment_status:orderline?.appointment?.appointment_status
            }
          : null,
        attachment: orderline?.attachment
          ? {
              id: orderline.attachment?.id,
              file: orderline.attachment?.file,
              url: orderline.attachment?.url,
              name: orderline.attachment?.name,
              abstract: orderline.attachment?.abstract,
            }
          : null,
      })) ?? [];

    setOrderItems(orderLineItems);

    directTransferForm.setFieldsValue({
      image: res?.order?.direct_transfer?.image,
      receipt_status: res?.order?.direct_transfer?.status ?? 'N/A',
      note: res?.order?.direct_transfer?.note,
    });

    setCreatedDate(dayjs(res?.order?.created_at).format('YYYY-MM-DD'));
    setCreatedTime(dayjs(res?.order?.created_at).format('HH:mm a'));
    form.setFieldsValue({
      created_date: dayjs(res?.order?.created_at),
      created_time: dayjs(res?.order?.created_at),
      status: res.order?.status,
      customer_id: res.order?.customer_id || null,
      bill_first_name: res.order?.bill_first_name,
      bill_last_name: res.order?.bill_last_name,
      bill_company: res.order?.bill_company,
      bill_address_line1: res.order?.bill_address_line1,
      bill_address_line2: res.order?.bill_address_line2,
      bill_city: res.order?.bill_city,
      bill_postcode: res.order?.bill_postcode,
      bill_country: res.order?.bill_country,
      bill_state: res.order?.bill_state,
      bill_email: res.order?.bill_email,
      bill_phone_no: res.order?.bill_phone_no,
      bill_payment_method: res.order?.bill_payment_method ?? orderConfigs.default_payment_method,
      bill_transaction_id: res.order?.bill_transaction_id || null,
      additional_note: res.order?.additional_note
    });

    setWfAbilities({
      cancelled: res?.wf_abilities?.cancelled ?? false,
      refund: res?.wf_abilities?.refund ?? false,
      refunded: res?.wf_abilities?.refunded ?? false,
    });
  };

  useEffect(() => {
    console.log('statusTriggeredTo', statusTriggeredTo);
    setLoading(true);
    if (orderId) {
      if (type === 'view') {
        showOrder(orderId)
          .then((res: any) => {
            console.log('wefefe', res);
            setApiData(res);
            setPageTitle(`${res.order?.id}`);
            setLoading(false);
          })
          // .catch((err: any) => console.error(err.message));
          .catch((error) => handleErrorMessages(error));
      } else if (type === 'edit') {
        editOrder(orderId)
          .then((res: any) => {
            console.log(res);
            setApiData(res);
            setPageTitle(`${res.order?.id}`);
            setLoading(false);
          })
          // .catch((err: any) => console.error(err.message));
          .catch((error) => handleErrorMessages(error));
      }
    } else {
      createOrder()
        .then((res: any) => {
          setApiData(res);
          setLoading(false);
          setPageTitle(`New`);
        })
        // .catch((err: any) => console.error(err.message));
        .catch((error) => handleErrorMessages(error));
    }
  }, [orderId, statusTriggeredTo, type, form]);

  const handleCustomerChange = (customerId: number) => {
    getOrderRelatedFromCustomer(customerId)
      .then((res: any) => {
        console.log(res);
        form.setFieldsValue(res?.order_customer);
      })
      .catch((err: any) => console.error(err.message));
  };

  const getPopupContainer = (triggerNode: HTMLElement | null) => {
    return triggerNode || document.body;
  };

  const cancel = async () => {
    if (orderId) {
      cancelledOrder(orderId)
        .then((response: any) => {
          setStatusTriggeredTo('cancelled');
        })
        .catch((err: any) => {
          setLoading(false);
          handleErrorMessages(err);
        });
    }
  };

  const refundSubmit = (values: { refund_reason: string }) => {
    if (orderId) {
      refundOrder(orderId,values)
        .then((response: any) => {
          setStatusTriggeredTo('refund');
          setOpen(false);
        })
        .catch((err: any) => {
          setLoading(false);
          handleErrorMessages(err);
        });
    }

    
   // processRefund(values);
  };

  const refunded = async () => {
    if (orderId) {
      refundedOrder(orderId)
        .then((response: any) => {
          setStatusTriggeredTo('refunded');
        })
        .catch((err: any) => {
          setLoading(false);
          handleErrorMessages(err);
        });
    }
  };

  return (
    <>
      <BaseRow gutter={[16, 16]}>
        <BaseCol xs={24} md={24} lg={16}>
          <BaseCard
            id="orderForm"
            // title={t('Order Form')}
            title={`Order - ` + pageTitle || 'New'}
            padding="1.25rem"
            style={{ margin: '20px 0 0 20px', width: '100%' }}
            extra={
              <>
                {(type === 'view' && (
                  <>
                    <Space>
                      <Tooltip title={t('common.edit')}>
                        <BaseButton type="text" onClick={() => navigate(`/edit-order/${id}`)} icon={<MdModeEdit />} />
                      </Tooltip>
                    </Space>
                  </>
                )) ||
                  (type === 'edit' && (
                    <>
                      <Space>
                        <Tooltip title={t('view')}>
                          <BaseButton type="text" onClick={() => navigate(`/show-order/${id}`)} icon={<IoEye />} />
                        </Tooltip>
                      </Space>
                    </>
                  ))}
                <Space>
                  <Tooltip title={'View all'}>
                    <BaseButton type="text" onClick={() => navigate('/orders')} icon={<TableOutlined />} />
                  </Tooltip>
                </Space>
              </>
            }
          >
            <BaseButtonsForm
              {...formItemLayout}
              name="ordersForm"
              form={form}
              loading={isLoading}
              isFieldsChanged={isFieldsChanged}
              setFieldsChanged={setFieldsChanged}
              onFinish={() => handleSubmit()}
              labelWrap
            >
              <BaseRow gutter={[16, 16]}>
                {type === 'view' && (
                  <BaseCol>
                    <Space>
                      {canPaymentVerify && orderId && (
                        <>
                          <PaymentVerify
                            setStatusTriggeredTo = {() => setStatusTriggeredTo('completed')}
                            orderId = {orderId}
                          />
                        </>
                      )}
                      {wfAbilities.cancelled && (
                        <Button type="primary" danger loading={isLoading} onClick={cancel}>
                          Cancel Order
                        </Button>
                      )}
                      {wfAbilities.refund && (
                        <>
                          <Button
                            type="primary"
                            loading={isLoading}
                            htmlType="button"
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            Processing Refund
                          </Button>
                          {/* <ProcessingRefundDialog openDialog={open} /> */}
                          <Modal open={open} title="Refund Payment" footer={null} closable={true}>
                            <BaseButtonsForm
                              form={refundform}
                              isFieldsChanged={isRefundFieldsChanged}
                              setFieldsChanged={setRefundFieldsChanged}
                              onFinish={refundSubmit}
                            >
                              <BaseButtonsForm.Item
                                name="refund_reason"
                                label="Provide a reason for a request to refund the payment"
                                rules={[{ required: true, message: 'This is a required field!' }]}
                              >
                                <BaseInput.TextArea />
                              </BaseButtonsForm.Item>
                              <Row gutter={[16, 16]} justify="end">
                                <Col>
                                  <Button
                                    type="default"
                                    onClick={() => {
                                      setOpen(false);
                                      refundform.resetFields(['refund_reason']);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col>
                                  <Button type="primary" htmlType="submit">
                                    Proceed
                                  </Button>
                                </Col>
                              </Row>
                            </BaseButtonsForm>
                          </Modal>
                        </>
                      )}
                      {wfAbilities.refunded && (
                        <SuccessButton type="primary" loading={isLoading} onClick={refunded}>
                          Refunded
                        </SuccessButton>
                      )}
                    </Space>
                  </BaseCol>
                )}
                {/* <BaseCol flex="auto"></BaseCol> */}
                {type !== 'view' && (EditAbilities.order || EditAbilities.receipt) && (
                  <BaseCol>
                    <Space>
                      <Button type="primary" htmlType="submit" disabled={!editable} loading={isLoading}>
                        Save Order
                      </Button>
                    </Space>
                  </BaseCol>
                )}
              </BaseRow>
              <br />
              <BaseRow gutter={[16, 16]}>
                <BaseCol>
                  <p>Please note that the payment process will no longer continue if the total is zero.</p>
                </BaseCol>
              </BaseRow>
              <section>
                <h4>
                  <b>General</b>
                </h4>
                <br />
                <BaseRow gutter={[16, 16]}>
                  <BaseCol xs={24} md={12} lg={12}>
                    <BaseButtonsForm.Item label="Created Date" name="created_date">
                      <BaseTypography.Text strong>{createdDate}</BaseTypography.Text>
                      {/* <DatePicker
                        style={{ display: 'flex', flexGrow: 1 }}
                        getPopupContainer={getPopupContainer}
                        disabled={!editable}
                      /> */}
                    </BaseButtonsForm.Item>
                  </BaseCol>

                  <BaseCol xs={24} md={12} lg={12}>
                    <BaseButtonsForm.Item label="Created Time" name="created_time">
                      <BaseTypography.Text strong>{createdTime}</BaseTypography.Text>
                      {/* <TimePicker
                        style={{ display: 'flex', flexGrow: 1 }}
                        getPopupContainer={getPopupContainer}
                        disabled={!editable}
                        format="HH:mm a"
                        use12Hours
                      /> */}
                    </BaseButtonsForm.Item>
                  </BaseCol>
                </BaseRow>

                <BaseRow gutter={[16, 16]}>
                  <BaseCol xs={24} md={12}>
                    <BaseButtonsForm.Item
                      label="Status"
                      name="status"
                      rules={[{ required: true, message: 'Status is required' }]}
                    >
                      <Select getPopupContainer={getPopupContainer} disabled={!(EditAbilities.order || EditAbilities.receipt) }>
                        {orderStatuses.map((status) => (
                          <Option value={status.key} key={status.key} disabled={status.disabled}>
                            {status.name}
                          </Option>
                        ))}
                      </Select>
                    </BaseButtonsForm.Item>
                    {/* <Button style={{ marginTop: '10px' }}>Customer Payment Page</Button> */}
                  </BaseCol>

                  <BaseCol xs={24} md={12}>
                    <BaseButtonsForm.Item
                      label="Customer"
                      name="customer_id"
                      rules={[{ required: true, message: 'Customer is required' }]}
                    >
                      <Select
                        showSearch
                        getPopupContainer={getPopupContainer}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          ((option?.children as unknown as string) || '').toLowerCase().indexOf(input.toLowerCase()) >=
                          0
                        }
                        onChange={handleCustomerChange}
                        disabled={!EditAbilities.order}
                      >
                        {customerOptions.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {`[#${option.id}] - ${option.name}`}
                          </Option>
                        ))}
                      </Select>
                    </BaseButtonsForm.Item>
                    {/* <Button style={{ marginTop: '10px' }} icon={<ProfileOutlined />}>
                      Profile
                    </Button>
                    <Button style={{ marginLeft: '8px', marginTop: '10px' }} icon={<ShoppingCartOutlined />}>
                      View Other Orders
                    </Button> */}
                  </BaseCol>
                </BaseRow>
              </section>
              <br />

              <section>
                <h4>
                  <b>Billing Address</b>
                </h4>
                <br />
                <BaseRow gutter={[16, 16]}>
                  <>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="First Name" name="bill_first_name">
                        <BaseInput placeholder="Enter First Name" disabled={!EditAbilities.order} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Last Name" name="bill_last_name">
                        <BaseInput placeholder="Enter Last Name" disabled={!EditAbilities.order} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Company" name="bill_company">
                        <BaseInput placeholder="Enter Company" disabled={!EditAbilities.order} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Address Line 1" name="bill_address_line1">
                        <BaseInput placeholder="Enter Address Line 1" disabled={!EditAbilities.order} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Address Line 2" name="bill_address_line2">
                        <BaseInput placeholder="Enter Address Line 2" disabled={!EditAbilities.order} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="City" name="bill_city">
                        <BaseInput placeholder="Enter City" disabled={!EditAbilities.order} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Postcode/ZIP" name="bill_postcode">
                        <BaseInput placeholder="Enter Postcode/ZIP" disabled={!EditAbilities.order} type="number" />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Country/Region" name="bill_country">
                        <Select placeholder="Select Country" getPopupContainer={getPopupContainer} disabled={!EditAbilities.order}>
                          {countryOptions.map((option) => (
                            <Option key={option.name} value={option.code}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="State/County" name="bill_state">
                        <BaseInput placeholder="Enter State/County" disabled={!EditAbilities.order} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item
                        label="Email"
                        name="bill_email"
                        rules={[{ required: true, message: 'Email is required' }]}
                      >
                        <BaseInput placeholder="Enter Email" disabled={!EditAbilities.order} type="email" />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Phone No" name="bill_phone_no">
                        <BaseInput placeholder="Enter Phone No" disabled={!EditAbilities.order} type="tel" />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Payment Method" name="bill_payment_method" initialValue={'pre'}>
                        <Select
                          placeholder="Select Payment Method"
                          getPopupContainer={getPopupContainer}
                          disabled={!EditAbilities.order}
                        >
                          <Option value="n/a">N/A</Option>
                          <Option value="dbt">Direct Bank Transfer</Option>
                          <Option value="pre">Pre Order</Option>
                          <Option value="chp">Cypherpay</Option>
                          <Option value="oth">Other</Option>
                        </Select>
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={12}>
                      <BaseButtonsForm.Item label="Transaction ID" name="bill_transaction_id">
                        <BaseInput placeholder="Enter Transaction ID" disabled={!(EditAbilities.order || EditAbilities.receipt)} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                    <BaseCol xs={24} md={24}>
                      <BaseButtonsForm.Item label="Additional Note" name="additional_note">
                        <BaseInput.TextArea  autoSize disabled={true} />
                      </BaseButtonsForm.Item>
                    </BaseCol>
                  </>
                </BaseRow>
              </section>
              <br />
              <hr />
              <br />

              <OrderItemsTable
                onOrderItemStateChange={setOrderItems}
                type={type}
                orderItems={orderItems}
                editable={EditAbilities.order}
                products={products}
                customerStatusOptions={customerStatusOptions}
                appointmentStatusOptions={appointmentStatusOptions}
                staffOptions={staffOptions}
                orderConfigs={orderConfigs}
                isCancelledOrder={isCancelledOrder}
              />
              <br />
              <br />
            </BaseButtonsForm>
          </BaseCard>
        </BaseCol>

        {/* Side Cards */}

        <BaseCol xs={24} lg={6}>
          <BaseCard
            title={t('Upload Receipt')}
            padding={'1.25rem'}
            style={{
              width: '100%',
              height: 'fit-content',
              margin: '20px',
            }}
          >
            <BaseButtonsForm
              labelWrap
              isFieldsChanged={false}
              layout="vertical"
              name="directTransferForm"
              form={directTransferForm}
              loading={isLoading}
            >
              <UploadReceiptSection
                editable={EditAbilities.receipt}
                setLastStatusChange={setLastStatusChange}
                setReceiptStatuschange={setReceiptStatuschange}
                setSelectedReceiptImage={setSelectedReceiptImage}
                selectedReceiptImage={selectedReceiptImage}
                receiptStatuschange={receiptStatuschange}
                receiptStatuses={receiptStatuses}
                lastStatusChange={lastStatusChange}
                imageUrl={receiptImageUrl}
              />
            </BaseButtonsForm>
          </BaseCard>

          <PrinctInvoiceSection invoiceUrl={orderInvoice?.invoice_url} name={orderInvoice?.name} />

          <BaseCard
            title={t('Order Notes')}
            padding="1.25rem"
            style={{
              width: '100%',
              height: 'fit-content',
              margin: '20px',
            }}
          >
            <OrderNotesSection
              orderNotes={orderNotes}
              setOrderNotes={setOrderNotes}
              userNotes={userNotes}
              setUserNotes={setUserNotes}
              editable={EditAbilities.order || EditAbilities.receipt}
            />
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default OrdersForm;
