import { BaseChart } from '@app/components/common/charts/BaseChart';
import { Dates } from '@app/constants/Dates';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { themeObject } from '@app/styles/themes/themeVariables';
import { EChartsInstance } from 'echarts-for-react';
import React, { useMemo } from 'react'


interface MonthlyNetSalesChartsProps {
    data: {
      x_axis: string,
      net_sales: number
  }[];
  }

export const MonthlyNetSalesChart: React.FC<MonthlyNetSalesChartsProps> = ({data}) => {
    const theme = useAppSelector((state) => state.theme.theme);
    const { isTablet, isDesktop, isMobile } = useResponsive();
    const months = Dates.getMonths();
  
    const option = {
      grid: {
        top: 27,
        bottom: 0,
        left: -20,
        right: -20,
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (label: EChartsInstance) {
          return `${label[0].axisValue}`;
        },
        axisPointer: {
          show: false,
          lineStyle: {
            width: 0,
          },
        },
      },
      xAxis: {
        type: 'category',
        data: data?.map(e=>e.x_axis),
        show: false,
        lineStyle: {
          width: 0,
        },
      },
      yAxis: {
        type: 'value',
        show: false,
        lineStyle: {
          width: 0,
        },
      },
      series: [
        {
          data: data?.map(e=>e.net_sales),
          type: 'line',
          smooth: true,
          showSymbol: false,
          symbol: 'circle',
          itemStyle: {
            color: themeObject[theme].chartColor5,
            borderColor: themeObject[theme].background,
            borderWidth: 5,
            shadowColor: themeObject[theme].shadow,
            shadowOffsetX: 0,
            shadowOffsetY: 5,
            opacity: 1,
          },
          symbolSize: 18,
          areaStyle: {
            color: themeObject[theme].chartSecondaryGradient,
          },
          lineStyle: {
            width: 2,
            color: themeObject[theme].error,
          },
        },
      ],
    };
  
    const chartHeight = useMemo(
      () => (isDesktop && 100) || (isTablet && 200) || (isMobile && 100) || 100,
      [isTablet, isDesktop, isMobile],
    );
  
    return <BaseChart option={option} height={chartHeight} />;
}
