import{ abilityService } from '@app/services/ability.service';
import { Navigate,useNavigate } from 'react-router-dom';
import React from 'react';
import error403 from '@app/assets/images/error403.svg';

interface AuthorizeRouteProps{
  model?: string;
  policy?: string;
  children: React.ReactNode;
  auth?:boolean;
}

const AuthorizeRoute: React.FC< AuthorizeRouteProps> = ({model,policy, children, auth=true}) => {
  const navigate = useNavigate();
  const abilities = abilityService.getAbilities();
  console.log(abilities,auth);

  if (auth && (!abilities || !model || !policy || !abilities.hasOwnProperty(model) || !abilities[model].includes(policy))) {
    navigate('/Error', {
      replace: true,
      state: {
        status: '403',
        img: error403,
        errorUrl: window.location.href,
        errorTitle: 'Forbidden',
        errorMessage: "You don't have permission to access this resource"
      }
    });
    return null;
  }

  return <>{children}</>;};

export default AuthorizeRoute;