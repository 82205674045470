import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { tokenService } from '@app/services/token.service';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Create an Axios instance with a custom configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000, // Timeout in milliseconds
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin':'*'
    // You can add other headers as needed
  },
  
});

// Define a custom type for your API response data
interface ApiResponse<T> {
  data: T;
  status: number;
  message?: string;
}

// Define a custom error type for API errors
export interface ApiError {
  status: number;
  message: string;
}

// Define a custom type for the authorization configuration
interface AuthorizationConfig {
  enabled: boolean;
  token?: string;
}

// Define a function for handling API errors
const handleApiError = (error: AxiosError) => {
  let e :ApiError;
  if (error.response) {
    if(error.response?.status === 401 || error.response?.data?.status === 401){
      console.log(error);
      e = {
        status: 401,
        message: error.response?.data?.error || 'Unauthenticated.',
      };
   
      //navigateCallback = () => {
       // window.location.href = '/reset-session';
      //};
      
    }
    else if(error.response?.status === 403 || error.response?.data?.status === 403){
      e = {
        status: 403,
        message: 'Forbidden',
      };
    }
    else if(error.response?.status === 404 || error.response?.data?.status === 404){
      e = {
        status: 404,
        message: 'Page Not Found',
      };
    }
    else if(error.response?.status === 422 || error.response?.data?.status === 422){
      const firstErrorMessages = Object.values(error.response?.data?.errors).flatMap(messages => {
          if (Array.isArray(messages) && messages.length > 0) {
              return messages[0] as string[];
          }
          return []; 
      });
      e = {
        status: error.response.status,
        message: firstErrorMessages.length > 0 ? firstErrorMessages[0] : 'An error occurred',
      };
    }

    else{
      e = {
        status: error.response.status,
        message: error.response.data.message || 'An error occurred',
      };
    }
   
  } else if (error.request) {
    // The request was made, but no response was received
    e = {
      status: 500,
      message: 'No response received from the server',
    };
  } 
  else {
    // Something happened in setting up the request that triggered an Error
    e = {
      status: 500,
      message: 'Error setting up the request',
    };
  }
  return e;
};

// Define a function for making a GET request

export const get = async <T>(url: string, config?: AxiosRequestConfig, authorize = true): Promise<ApiResponse<T>> => {
  try {
    // Check if authorization is required
    if (authorize) {
      // Get the token from the token service
      const token = tokenService.getToken();
      // Check if a token is available and add it to the headers
      if (token) {
        config = { ...config, headers: { ...config?.headers, Authorization: `Bearer ${token}` } };
      } else {
        throw new Error('Authorization token not available.');
      }
    }

    const response = await api.get<T>(url, config);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error:any)
  {
    throw handleApiError(error);
  }

};

// Define a function for making a POST request
export const post = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  authorize = true,
): Promise<ApiResponse<T>> => {
  try {
    // Check if authorization is required
    //config = { ...config, headers: { ...config?.headers, 'Content-Type': 'multipart/form-data' } };
    if (authorize) {
      // Get the token from the token service
      const token = tokenService.getToken();

      // Check if a token is available and add it to the headers
      if (token) {
        config = { ...config, headers: { ...config?.headers, Authorization: `Bearer ${token}` } };
      } else {
        throw new Error('Authorization token not available.');
      }
    }
    const response = await api.post<T>(url, data, config);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error: any) {
    throw handleApiError(error);
  }
};

// Define a function for making a DELETE request
export const remove = async <T>(
  url: string,
  config?: AxiosRequestConfig,
  authorize = true,
): Promise<ApiResponse<T>> => {
  try {
    // Check if authorization is required
    if (authorize) {
      // Get the token from the token service
      const token = tokenService.getToken();

      // Check if a token is available and add it to the headers
      if (token) {
        config = { ...config, headers: { ...config?.headers, Authorization: `Bearer ${token}` } };
      } else {
        throw new Error('Authorization token not available.');
      }
    }
    const response = await api.delete<T>(url, config);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error: any) {
    throw handleApiError(error);
  }
};
export default api;


// Define a function for making a PUT request
export const put = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  authorize = true,
): Promise<ApiResponse<T>> => {
  try {
    // Check if authorization is required
    if (authorize) {
      // Get the token from the token service
      const token = tokenService.getToken();

      // Check if a token is available and add it to the headers
      if (token) {
        config = { ...config, headers: { ...config?.headers, Authorization: `Bearer ${token}` } };
      } else {
        throw new Error('Authorization token not available.');
      }
    }
    const response = await api.put<T>(url, data, config);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error: any) {
    throw handleApiError(error);
  }
};


