import { PageTitle } from "@app/components/common/PageTitle/PageTitle";
import { AppointmentForm } from "@app/components/forms/AppointmentForm/AppointmentForm";
import { useTranslation } from "react-i18next";
import React from 'react'

export const CreateAppointmentPage:React.FC = () => {
    const { t } = useTranslation();
    
  return (
    <>
      {/* <PageTitle>{t('Add-Appointment')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <AppointmentForm type={"create"}  editable = {true} />
    </>
  )
}