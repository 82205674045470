import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { BaseButton } from "@app/components/common/BaseButton/BaseButton";
import { BaseCard } from "@app/components/common/BaseCard/BaseCard";
import { PageTitle } from "@app/components/common/PageTitle/PageTitle";
import { MeetingsTable } from "@app/components/tables/BasicTable/MeetingsTable";
import { Space, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const MeetingsTablePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>('');
  
    const handlebtnClick = () => {
      navigate('/add-meeting');
    };
  
    const handleSearch = (value: string) => {
      // Update the searchText state when the user performs a search
      setSearchText(value);
    };
  
    return (
      <>
      {/* <PageTitle>{t('Meetings')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
        <Space style={{ marginBottom: '16px', justifyContent: 'space-between', width: '100%' }}>
          <BaseButton icon={<PlusOutlined />} type="primary" onClick={handlebtnClick}>
            {t('Add New Meeting')}
          </BaseButton>
        </Space>
          <MeetingsTable searchText={searchText} />
      </>
    );
  };
  
  export default MeetingsTablePage;
  