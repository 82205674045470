import { get, post, remove } from '@app/api/http.api';

export interface MeetingFormData {
  topic: string;
  agenda: string | null;
  start_time: Date;
  duration: number;
  password: string;
  waiting_room: boolean;
  join_before_host: boolean;
  host_video: boolean;
  participant_video: boolean;
  mute_upon_entry: boolean;
}

interface Settings {
  waiting_room: boolean;
  join_before_host: boolean;
  host_video: boolean;
  participant_video: boolean;
  mute_upon_entry: boolean;
}

export interface MeetingRequest {
  topic?: string;
  agenda?: string;
  start_time?: string;
  duration?: number;
  password?: string;
  settings?: Settings;
}

interface MeetingApiSetting {
  waiting_room: boolean;
  join_before_host: boolean;
  host_video: boolean;
  participant_video: boolean;
  mute_upon_entry: boolean;
}

export interface MeetingApiData {
  join_url: string;
  id: number;
  topic: string;
  agenda: null | string;
  start_time: string;
  duration: number;
  password: string;
  settings: MeetingApiSetting;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

export interface MeetingTableRow {
  meeting_id?: string;
  join_url: string | null;
  id: number;
  topic: string;
  duration: number;
  start_time: null | string;
  agenda: null | string;
  password: null | string;
}

export interface MeetingTableData {
  data: MeetingTableRow[];
  pagination: Pagination;
}

export interface SearchMeetings {
  results: MeetingTableRow[];
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
}

export const searchByMeetingIds = async (keyword: string) => {
  try {
    const { data, status } = await get<SearchMeetings>(`meetings/search/${keyword}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error(`Error: ${error.status} - ${error.message}`);
  }
};

export const getMeetingTableData = async (parameters:Parameters): Promise<MeetingTableData> => {
  try {
    const { data, status } = await get<PaginatedResponse<MeetingApiData>>('meetings', { params: parameters }, true);
    console.log(`Status: ${status}`, `Data: ${data}`);

    const result: MeetingTableRow[] = data?.data.map((rawData: MeetingApiData) => ({
      id: rawData.id,
      topic: rawData.topic,
      start_time: rawData.start_time,
      agenda: rawData.agenda,
      join_url: rawData.join_url,
      password: rawData.password,
      duration: rawData.duration,
    }));
    return {
      data: result,
      pagination: {
        current: data.current_page,
        pageSize: data.per_page,
        total: data.total,
      },
    };
  } catch (error: any) {
    throw error;
  }
};

export const getMeetings = async () => {
  try {
    const { data, status } = await get<MeetingApiData[]>(`meetings`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storeMeetings = async (StoreMeetingData: MeetingRequest) => {
  try {
    const { data, status } = await post<MeetingRequest>(`meetings/create`, StoreMeetingData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const updateMeeting = async (id: number, UpdateMeetingData: MeetingRequest) => {
  try {
    const { data, status } = await post<MeetingRequest>(`meetings/${id}/edit`, UpdateMeetingData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const deleteMeeting = async (id: number) => {
  try {
    const { status, data } = await remove<MeetingRequest>(`meetings/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createMeeting = async () => {
  try {
    const { data, status } = await get<MeetingApiData>(`meetings/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showMeeting = async (id: number) => {
  try {
    const { data, status } = await get<MeetingApiData>(`meetings/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const editMeeting = async (id: number) => {
  try {
    const { data, status } = await get<MeetingApiData>(`meetings/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
