import React from 'react';
import { saveAs } from 'file-saver';
import { DownloadOutlined, FilePdfOutlined, PaperClipOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Space } from 'antd';

interface Props {
    pdfUrl: string;
    name: string;
}

class PDFDownloader extends React.Component<Props> {
    
    handleLinkClick = (event:any) => {
        event.preventDefault();
        window.open(this.props.pdfUrl, '_blank');
    }

    // downloadPDF = async () => {
    //     const { pdfUrl} = this.props;
    //     try {

            // const response = await fetch(pdfUrl, { mode: 'no-cors' ,headers: {
            //         'Content-Type': 'application/pdf'
            //       }});
            // const blob = await response.blob();
            // saveAs(blob, name);

            // const response = await fetch(pdfUrl, {
            //     headers: {
            //         'Content-Type': 'application/pdf'
            //     }
            // });
            // const blob = await response.blob();
            // const url = URL.createObjectURL(blob);
            // const a = document.createElement('a');
            // a.href = url;
            // a.download = name || 'download.pdf';
            // document.body.appendChild(a);
            // a.click();
            // document.body.removeChild(a);
            // URL.revokeObjectURL(url);

    //     } catch (error) {
    //         console.error('Error downloading PDF:', error);
    //     }
    // };

    render() {
        return (
            // <div>
            //     {this.props.name}
            //     {/* <button onClick={this.downloadPDF}>Download</button> */}
            //      <button onClick={this.handleLinkClick}>Download</button>

            // </div>
            <div>
            <a href={this.props.pdfUrl} target='_blank' rel="noreferrer" download={this.props.name}>
                <Space>
                <BaseButton type='dashed' size="middle" >
                <FilePdfOutlined style={{ color: 'red', fontSize: '2rem' }} />
                <BaseTypography.Text>{this.props.name}</BaseTypography.Text>
                <DownloadOutlined />
                </BaseButton>
                </Space>
            </a>
            </div>
        );
    }
}

export default PDFDownloader;
