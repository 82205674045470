import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostForm } from '@app/components/forms/PostsForm/PostsForm';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const AddPostPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <PageTitle>{t('Add-Post')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <PostForm type={"create"}  editable = {true}/>
    </>
  );
};

export default AddPostPage;
