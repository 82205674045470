import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getCategoryTableData, CategoryTableRow, Pagination } from 'api/category.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { Tooltip, Button, Tag, Input, InputRef, Select } from 'antd';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const CategoryTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: CategoryTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const handleErrorMessages = useApiErrorHandler();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const [statuses,setStatuses] = useState<any[]>([])

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getCategoryTableData({...pagination,  search: form.getFieldValue('search'),status:form.getFieldValue('status')})
        .then((res) => {
          setStatuses(res.status_options.map(e => ({value:e.key,label:e.name})))
          if (isMounted.current) {
            setTableData({ data: res.categories.data, pagination: res.categories.pagination, loading: false });
          }
        })
        .catch((error) => {
          handleErrorMessages(error);
        });
    },
    [isMounted],
  );

  useEffect(() => {
    
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Categories`}</span>
    );
  };
  const handleDeleteRow = (rowId: number) => {
    const newData = tableData.data.filter((item) => item.id !== rowId);
    const newTotal = tableData.pagination.total ? tableData.pagination.total - 1 : 0;

    setTableData({
      data: newData,
      pagination: {
        ...tableData.pagination,
        total: newTotal,
      },
      loading: false,
    });
  };

  const navigate = useNavigate();

  const handlebtnClickEdit = (id: number) => {
    navigate(`/edit-category/${id}`);
  };

  const handlebtnClickView = (id: number) => {
    navigate(`/show-category/${id}`);
  };

  const columns: ColumnsType<CategoryTableRow> = [
    {
      title: t('category.table.name'),
      dataIndex: 'name',
      render: (text: string) => <span>{text}</span>,
      filterMode: 'tree',
      filterSearch: true,
      // filters: [
      //   {
      //     text: t('common.firstName'),
      //     value: 'firstName',
      //     children: [
      //       {
      //         text: 'Joe',
      //         value: 'Joe',
      //       },
      //       {
      //         text: 'Pavel',
      //         value: 'Pavel',
      //       },
      //       {
      //         text: 'Jim',
      //         value: 'Jim',
      //       },
      //       {
      //         text: 'Josh',
      //         value: 'Josh',
      //       },
      //     ],
      //   },
      //   {
      //     text: t('common.lastName'),
      //     value: 'lastName',
      //     children: [
      //       {
      //         text: 'Green',
      //         value: 'Green',
      //       },
      //       {
      //         text: 'Black',
      //         value: 'Black',
      //       },
      //       {
      //         text: 'Brown',
      //         value: 'Brown',
      //       },
      //     ],
      //   },
      // ],
      onFilter: (value: string | number | boolean, record: CategoryTableRow) => record.name.includes(value.toString()),
    },
    {
      title: t('category.table.slug'),
      dataIndex: 'slug',
    },
    {
      title: t('category.table.description'),
      dataIndex: 'description',
    },
    {
      title: t('category.table.parent'),
      dataIndex: 'parent_category_chain',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <>
          <Tag color={value == 'ACTIVE' ? 'blue' : 'red'} key={value}>
            {value}
          </Tag>
        </>
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (_, record) => {
        return (
          <BaseSpace>
            <Tooltip title={t('view')}>
              <Button type="text" onClick={() => handlebtnClickView(record.id)} icon={<IoEye />} />
            </Tooltip>

            <Tooltip title={t('common.edit')}>
              <Button type="text" onClick={() => handlebtnClickEdit(record.id)} icon={<MdModeEdit />} />
            </Tooltip>

            {/* <Tooltip title={t('tables.delete')}>
              <Button type="text" danger onClick={() => handleDeleteRow(record.id)} icon={<MdDelete />} disabled />
            </Tooltip> */}
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseCard id="basic-table" title="Product Categories" padding="1.25rem" 
    extra={
      <BaseButtonsForm
      form={form}
      layout="inline"
      isFieldsChanged={fieldsChanged}
      setFieldsChanged={setFieldsChanged}
    >
      <BaseButtonsForm.Item name={'status'}>
      <Select size='middle'
          placeholder={'Select Status'}
          options={statuses}
          onChange={()=>{fetch(initialPagination)}}
        />
      </BaseButtonsForm.Item>
       
      <BaseButtonsForm.Item name={'search'}>
        <Input
          size='middle'
          suffix={<Button icon={<SearchOutlined  />} size='middle' type='text' onClick={()=>{fetch(initialPagination)}} />}
          placeholder={'Search Categories'}
        />
      </BaseButtonsForm.Item>
     
      
      <Button type='ghost' size='middle' icon={<ReloadOutlined />} 
      onClick={()=>{
        form.resetFields(['status','search']);
        fetch(initialPagination);
        }}/>
    </BaseButtonsForm>
    }>
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        current: tableData.pagination.current,
        pageSize: tableData.pagination.pageSize,
        total: tableData.pagination.total,
        showTotal,
      }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: true, scrollToFirstRowOnChange: true }}
    />
    </BaseCard>
  );
};
