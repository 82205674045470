import { get, post } from '@app/api/http.api';


export interface ReportData{
    x_axis:string;
    gross_sales: number|null;
    net_sales: number|null;
    orders_placed: number|null;
    items_purchased: number|null;
    avg_gross_sales: number|null;
    avg_net_sales: number|null;
    no_customer_orders: number|null;
    no_guest_orders: number|null;
}

export interface SummaryStatistic{
    gross_sales: number | null;
    net_sales: number| null;
    orders_placed: number| null;
    items_purchased: number| null;
    no_customer_orders: number| null;
    no_guest_orders: number| null;
}

export interface ReportDetails{
    period: string;
    stats: ReportData[];
    summary_stats: SummaryStatistic;
}

export const getReport = async (name: string) => {
    try {
      const { data, status } = await get<ReportDetails[]>(`reports/${name}`, undefined, true);
      console.log(`Status: ${status}`, `Data: ${data}`);
      return data;
    } catch (error: any) {
      console.error(`Error: ${error.status} - ${error.message}`);
      throw new Error(`Error: ${error.status} - ${error.message}`);
    }
  };

export const getCustomReportData = async (dates: [string,string],name: string)=>{
    const requestData = {
        start_date : dates[0],
        end_date : dates[1]
    }
    try {
        const { data, status } = await post<ReportDetails>(`reports/${name}/custom`,requestData, undefined, true);
        console.log(`Status: ${status}`, `Data: ${data}`);
        return data;
      } catch (error: any) {
        console.error(`Error: ${error.status} - ${error.message}`);
        throw new Error(`Error: ${error.status} - ${error.message}`);
      }
}