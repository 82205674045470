import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { UserForm } from '@app/components/forms/UserForm/UserForm';
import { useTranslation } from 'react-i18next';
import{ authUserService } from '@app/services/authUser.service';

interface RouteParams {
    id: string;
    [key: string]: string | undefined;
  }

const EditUserPage: React.FC = () => {

  const { t } = useTranslation();
  const id = authUserService.getUser()?.id?.toString();

  return (
    <>
      <PageTitle>{t('User')}</PageTitle>
      <UserForm type={"edit"} id={id} editable={true} isMyProfile={true} />
    </>
  );
}

export default EditUserPage