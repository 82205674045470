import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Space, Button, Popconfirm, Input, InputRef } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MeetingTableRow, Pagination, getMeetingTableData, deleteMeeting } from '@app/api/meeting.api';
import { useMounted } from '@app/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { Tooltip } from 'antd';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { notificationController } from '@app/controllers/notificationController';
import { QuestionCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

interface MeetingsTableProps {
  searchText: string;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const MeetingsTable: React.FC<MeetingsTableProps> = ({ searchText }) => {
  const [tableData, setTableData] = useState<{ data: MeetingTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const searchRef = useRef<InputRef>(null);
  const handleErrorMessages = useApiErrorHandler();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();

  const fetch = useCallback((pagination: Pagination) => {
    setTableData((prevData) => ({ ...prevData, loading: true }));
    getMeetingTableData({...pagination, search: form.getFieldValue('search')})
      .then((res) => {
        if (isMounted.current) {
          setTableData({ data: res.data, pagination: res.pagination, loading: false });
        }
      })
      .catch((error) => {
        handleErrorMessages(error);
      });
  }, []);

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  // const handleDeleteRow = (rowId: number) => {
  //   setTableData({
  //     ...tableData,
  //     data: tableData.data.filter((item) => item.id !== rowId),
  //     pagination: {
  //       ...tableData.pagination,
  //       total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
  //     },
  //   });
  // };

  const handleDeleteRow = (rowId: number) => {
    setLoading(true);
    deleteMeeting(rowId)
      .then(() => {
        setLoading(false);
        fetch(initialPagination);
        notificationController.info({ message: `Meeting is deleted.` });
      })
      .catch((err: any) => {
        setLoading(false);
        handleErrorMessages(err);
        console.error(err);
      });
  };

  const handleEditRow = (id: number) => {
    navigate(`/edit-meeting/${id}`);
  };

  const handleViewRow = (id: number) => {
    navigate(`/view-meeting/${id}`);
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Meetings`}</span>
    );
  };

  const columns: ColumnsType<MeetingTableRow> = [
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Date & Time',
      dataIndex: 'start_time',
      render(value, record, index) {
        return <div>{dayjs(record.start_time).format('MMMM D, YYYY  HH:mm a')}</div>;
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (value, record, index) => `${record.duration} minutes`,
    },

    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Jion URL',
      dataIndex: 'join_url',
      key: 'join_url',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Button
              type="ghost"
              onClick={() => handleViewRow(record.id)}
              icon={<IoEye />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Tooltip title="Edit">
            <Button
              type="ghost"
              onClick={() => handleEditRow(record.id)}
              icon={<MdModeEdit />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>
          <Popconfirm
            title="Do you want to delete this meeting?"
            onConfirm={() => handleDeleteRow(record.id)}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Tooltip title="Delete">
              <Button
                type="default"
                danger
                // onClick={() => handleDeleteRow(record.id)}
                icon={<MdDelete />}
                style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <BaseCard
      id="basic-table"
      title={'All Meetings'}
      padding="1.25rem"
      extra={
        <BaseButtonsForm
        form={form}
        layout="inline"
        isFieldsChanged={fieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
  
        <BaseButtonsForm.Item name={'search'}>
          <Input
            size="middle"
            suffix={<Button icon={<SearchOutlined  />} size='middle' type='text' onClick={()=>{fetch(initialPagination)}} />}
            placeholder={'Search Meetings'}
          />
        </BaseButtonsForm.Item>
        <Button type='ghost' size='middle' icon={<ReloadOutlined />} 
          onClick={()=>{
            form.resetFields(['search']);
            fetch(initialPagination);
            }}/>
      </BaseButtonsForm>}
    >
      <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        current: tableData.pagination.current,
        pageSize: tableData.pagination.pageSize,
        total: tableData.pagination.total,
        showTotal,
      }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: true, scrollToFirstRowOnChange: true }}
    />
    </BaseCard>
    
  );
};
