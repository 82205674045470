import React from 'react';
import { NFTCard } from '../common/NFTCard/NFTCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import * as S from './Status.style';
import { InfoCircleFilled, ExclamationCircleFilled, MinusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { DashboardData } from '@app/pages/DashboardPages/NftDashboardPage';

interface StatusProps{
  data : DashboardData | null;
}
export const Status:React.FC<StatusProps> = ({data}) => {
  return (
    <NFTCard>
      <BaseRow justify="space-around" gutter={[24, 24]}>
        <BaseCol md={8} sm={12} xs={24}  span={8}>
          <S.SuccessBaseCard padding="1.25rem 1.25rem" type="inner">
            <BaseRow gutter={[16, 8]} align="middle">
              <BaseCol span={4}>
                <S.InfoCircle />
              </BaseCol>
              <BaseCol span={20}>
                <S.Stats value={data?.orders_awaiting_for_processing }></S.Stats>
                <S.Title>Orders awaiting for processing</S.Title>
              </BaseCol>
            </BaseRow>
          </S.SuccessBaseCard>
        </BaseCol>
        <BaseCol md={8} sm={12} xs={24} span={8}>
          <S.WarningBaseCard padding="1.25rem" type="inner">
            <BaseRow gutter={[16, 8]} align="middle">
              <BaseCol span={4}>
                <S.ExclamationCircle />
              </BaseCol>
              <BaseCol span={20}>
                <S.Stats value={0}></S.Stats>
                <S.Title>Products low in stock</S.Title>
              </BaseCol>
            </BaseRow>
          </S.WarningBaseCard>
        </BaseCol>
        <BaseCol md={8} sm={12} xs={24} span={8}>
          <S.SuccessBaseCard padding="1.25rem" type="inner">
            <BaseRow gutter={[16, 8]} align="middle">
              <BaseCol span={4}>
                <S.InfoCircle />
              </BaseCol>
              <BaseCol span={20}>
                <S.Stats value={data?.new_monthly_appointments }></S.Stats>
                <S.Title>New monthly appointments</S.Title>
              </BaseCol>
            </BaseRow>
          </S.SuccessBaseCard>
        </BaseCol>
        <BaseCol md={8} sm={12} xs={24} span={8}>
          <S.PrimaryBaseCard padding="1.25rem" type="inner">
            <BaseRow gutter={[16, 8]} align="middle">
              <BaseCol span={4}>
                <S.MinusCircle />
              </BaseCol>
              <BaseCol span={20}>
                <S.Stats value={data?.orders_on_hold}></S.Stats>
                <S.Title>Orders on hold</S.Title>
              </BaseCol>
            </BaseRow>
          </S.PrimaryBaseCard>
        </BaseCol>
        <BaseCol md={8} sm={12} xs={24} span={8}>
          <S.ErrorBaseCard padding="1.25rem" type="inner">
            <BaseRow gutter={[16, 8]} align="middle">
              <BaseCol span={4}>
                <S.CloseCircle />
              </BaseCol>
              <BaseCol span={20}>
                <S.Stats value={0}></S.Stats>
                <S.Title>Products out of stock</S.Title>
              </BaseCol>
            </BaseRow>
          </S.ErrorBaseCard>
        </BaseCol>
        <BaseCol md={8} sm={12} xs={24} span={8}>
          <S.WarningBaseCard padding="1.25rem" type="inner">
            <BaseRow gutter={[16, 8]} align="middle">
              <BaseCol span={4}>
                <S.ExclamationCircle />
              </BaseCol>
              <BaseCol span={20}>
                <S.Stats value={data?.confirmation_required_appointments}></S.Stats>
                <S.Title>Confirmation required appointments</S.Title>
              </BaseCol>
            </BaseRow>
          </S.WarningBaseCard>
        </BaseCol>
      </BaseRow>
    </NFTCard>
  );
};
