import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostCategoryForm } from '@app/components/forms/PostCategoryForm/PostCategoryForm';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { showPostCategory } from '@app/api/postCategories.api';

interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const EditPostCategoryPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  // const [postCategoryName, setPostCategoryName] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(true);

  // useEffect(() => {
  //   if (id) {
  //     showPostCategory(parseInt(id)).then((res: any) => {
  //       setPostCategoryName(res?.postCategory?.name || '');
  //       setLoading(false);
  //     });
  //   }
  // }, [id]);

  // if (loading) {
  //   return <div>Loading...</div>; 
  // }

  return (
    <>
      {/* <PageTitle>{t('common.login')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <PostCategoryForm type={"edit"} id={id} editable = {true} />
    </>
  );
};

export default EditPostCategoryPage;
