import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { UserForm } from '@app/components/forms/UserForm/UserForm';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface RouteParams {
    id: string;
    [key: string]: string | undefined;
  }

const EditUserPage: React.FC = () => {

  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  return (
    <>
      <PageTitle>{t('User')}</PageTitle>
      <UserForm type={"edit"} id={id} editable={true}  />
    </>
  );
}

export default EditUserPage