import { DeleteFilled, EditFilled, EditOutlined, PaperClipOutlined, TableOutlined } from '@ant-design/icons';
import {
  AppointmentStatusOption,
  CustomerStatusOption,
  Product,
  StaffOption,
  calculateUnitPrice,
  OrderConfigs,
} from '@app/api/order.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { AppointmentFormFields } from '@app/components/forms/AppointmentForm/AppointmentFormFields';
import { OrderLineItem } from '@app/components/forms/OrderForm/OrderForm';
import { notificationController } from '@app/controllers/notificationController';
import { Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TabPane from 'antd/lib/tabs/TabPane';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { getAppointmentStatusOption } from '../BasicTable/AppointmentsTable';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { ViewPaperAttachmentSection } from '@app/components/forms/OrderForm/ViewPaperAttachmentSection';
import { RightText } from '@app/components/header/dropdowns/settingsDropdown/settingsOverlay/nightModeSettings/NightTimeSlider/NightTimeSlider.styles';

interface OrderItemsTableProps {
  type: 'create' | 'edit' | 'view';
  orderItems: any[];
  editable: boolean;
  products: Product[];
  customerStatusOptions: CustomerStatusOption[];
  appointmentStatusOptions: AppointmentStatusOption[];
  staffOptions: StaffOption[];
  onOrderItemStateChange: (value: any) => void;
  orderConfigs: OrderConfigs;
  isCancelledOrder: boolean;
}

interface Appointment {
  id?: number;
  customer_status?: string;
  status?: string;
  status_name?: string;
  start_date_time: string | null;
  end_date_time: string | null;
  all_day?: boolean;
  customer_timezone?: string;
  staff?: string;
}

interface PaperDetail{
  name: string;
  file: string;
  abstract: string;
  url: string;
  price: string;
}

interface OrderLineFormRequest {
  product_id: number;
  customer_status: string;
  status: string;
  staff: string;
  customer_timezone: string;
  qty: number;
  all_day: boolean;
  start_date_time: string;
  end_date_time: string;
}

export const OrderItemsTable: React.FC<OrderItemsTableProps> = ({
  type,
  onOrderItemStateChange,
  orderItems,
  editable,
  products,
  customerStatusOptions,
  appointmentStatusOptions,
  staffOptions,
  orderConfigs,
  isCancelledOrder=false
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState('1');
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [formMode, setFormMode] = useState<'create' | 'edit'>('create');
  const [currentIndexOnEdit, setCurrentIndexOnEdit] = useState<number | undefined>(undefined);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [showPaperDetails, setShowPaperDetails] = useState(false);
  const [paperDetails, setPaperDetails] = useState<PaperDetail | null>(null);
  //const [unitPrice, setUnitPrice] = useState<number>(0);
  const [appointmentStatues, setAppointmentStatues] = useState<AppointmentStatusOption[]>([])
  // const [initialValues, setInitialValues] = useState<any>({
  //   status: null,
  //   product_id: null,
  //   start_date_time: null,
  //   end_date_time: null,
  // });

  useEffect(() => {
    setItems(orderItems);
    setAppointmentStatues(appointmentStatusOptions);
  }, [orderItems]);
  const itemsSubTotal = items?.filter((item) => item.action !== '4').reduce((sum, row) => sum + parseInt(row.total), 0);
  const orderTotal = itemsSubTotal;
  //   itemsSubTotal + items?.filter((item) => item.action !== '4').reduce((sum, row) => sum + parseInt(row.tax), 0);

  const summaryRows = [
    // {
    //   id: null,
    //   product_id: 0,
    //   product: null,
    //   isSummaryLine: true,
    //   summaryLine: 'Items Sub Total',
    //   cost: 0,
    //   qty: 0,
    //   total: itemsSubTotal,
    //   tax: 0,
    //   appointment: null,
    //   action:'3'
    // },
    {
      id: null,
      product_id: 0,
      product: null,
      isSummaryLine: true,
      summaryLine: 'Total',
      cost: 0,
      qty: 0,
      total: orderTotal,
      tax: 0,
      appointment: null,
      action: '3',
    },
  ];

  const handleConfirmAppointment = (recordIndex: number) => {
    const currentItem = items.find((_, index) => index === recordIndex);

    const product = products.find((product) => product.id === currentItem?.product?.id);
    const orderItem: OrderLineItem = {
      product_id: currentItem?.product_id ?? null,
      cost: currentItem?.cost ?? 0,
      qty: currentItem?.qty ?? 1,
      total: currentItem?.total ?? ((currentItem?.cost ?? 0 )*  (currentItem?.qty ?? 1)),
      tax: 0,
      appointment: {
        customer_status: currentItem?.appointment?.customer_status ?? '1',
        status: orderConfigs.confirmed_appointment_status,
        start_date_time: dayjs(currentItem?.appointment?.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
        end_date_time: dayjs(currentItem?.appointment?.end_date_time).format('YYYY-MM-DD HH:mm:ss'),
        all_day: currentItem?.appointment?.all_day ?? false,
        customer_timezone: currentItem?.appointment?.customer_timezone ?? '',
        staff: currentItem?.appointment?.staff ?? '1',
        id: currentItem?.appointment?.id,
        appointment_status: appointmentStatues.find(e => e.key == orderConfigs.confirmed_appointment_status)?.name ?? 'N/A'
      },
      id: currentItem?.id ?? null,
      isSummaryLine: false,
      product: {
        id: product?.id ?? 0,
        name: product?.name ?? '',
        sale_price: product?.sale_price ?? 0,
        type: product?.type ?? 'SI',
        is_appointable: product?.is_appointable ?? false,
      },
      action: '2',
    };
    const existingItems = items.filter((_, index) => index !== recordIndex);
    setItems([...existingItems, orderItem]);
    onOrderItemStateChange([...existingItems, orderItem]);
  };

  const handleEditAction = (recordIndex: any) => {
    setFormMode('edit');
    const existingItem = items.find((_, index) => index === recordIndex);
    setShowAppointmentDetails(existingItem?.product?.is_appointable);
    form.setFieldsValue({
      product_id: existingItem?.product?.id,
      customer_status: existingItem?.appointment?.customer_status,
      status: existingItem?.appointment?.status,
      staff: existingItem?.appointment?.staff,
      customer_timezone: existingItem?.appointment?.customer_timezone,
      qty: existingItem?.qty,
      all_day: existingItem?.appointment?.all_day,
      start_date_time: dayjs(existingItem?.appointment?.start_date_time),
      end_date_time: dayjs(existingItem?.appointment?.end_date_time),
    });
    // setInitialValues({
    //   status: existingItem?.appointment?.status || null,
    //   product_id: existingItem?.product?.id || null,
    //   start_date_time: existingItem?.appointment?.start_date_time || null,
    //   end_date_time: existingItem?.appointment?.end_date_time || null,
    // });
    setFormMode('edit');
    setCurrentIndexOnEdit(recordIndex);
    setActiveKey('2');
  };

  const handleDeleteAction = (recordIndex: any) => {
    const item = items.find((_, index) => index === recordIndex);
    const deleteItem = { id: item?.id, action: '4' };
    const updatedItems = items.filter((_, index) => index !== recordIndex);
    if (item?.id !== null) {
      setItems([...updatedItems, deleteItem]);
      onOrderItemStateChange([...updatedItems, deleteItem]);
    } else {
      setItems([...updatedItems]);
      onOrderItemStateChange([...updatedItems]);
    }
  };

  const handleAttachmentAction = (record: any) => {
    console.log(record);
    setShowPaperDetails(true);
    setPaperDetails({
      name: record?.attachment?.name,
      abstract: record?.attachment?.abstract,
      url: record?.attachment?.url,
      file: record?.attachment?.file,
      price: record?.cost,
    });
  };

  const columns: ColumnsType<OrderLineItem> = [
    {
      title: 'Item',
      dataIndex: 'product_id',
      render: (_, record, index) => {
        return record.isSummaryLine ? (
          <>{record.summaryLine}</>
        ) : (
          <>
            <div><b>{record.product?.name}</b></div>
            {record?.product?.is_appointable && (
              <>
                <div>Appointment No: {record.appointment?.id ?? 'N/A'}</div>

                <div>Status: <b>{record.appointment?.appointment_status}</b></div>
                <div>
                  Appointment Date:
                  {` ${dayjs(record.appointment?.start_date_time).format('YYYY/MM/DD')} - ${dayjs(
                    record.appointment?.end_date_time,
                  ).format('YYYY/MM/DD')}`}
                </div>
                <div>
                  Appointment Time:
                  {` ${dayjs(record.appointment?.start_date_time).format('hh:mm a')} - ${dayjs(
                    record.appointment?.end_date_time,
                  ).format('hh:mm a')}`}
                </div>

                <br />
                <BaseSpace>
                  { type === 'edit' && record.appointment?.status && orderConfigs.confirmation_statuses.includes(record.appointment?.status)  && (
                    <BaseButton
                      type="ghost"
                      size="small"
                      style={{ fontSize: 12 }}
                      disabled={!editable}
                      onClick={() => handleConfirmAppointment(index)}
                    >
                      Confirm Appointment
                    </BaseButton>
                  )}
                </BaseSpace>
              </>
            )}
          </>
        );
      },
      width: '30%',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      render: (_, record) => {
        const cost = typeof record.cost === 'string' ? parseFloat(record.cost).toFixed(2) : record.cost.toFixed(2);
        
          
          return `LKR ${cost}`;
        
        
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      render: (_, record) => {
        const tax = typeof record.tax === 'string' ? parseFloat(record.tax).toFixed(2) : record.tax.toFixed(2);
        return `LKR ${tax}`;
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (_, record) => {
        const total = typeof record.total === 'string' ? parseFloat(record.total).toFixed(2) : record.total.toFixed(2);
        return  `LKR ${total}`;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (_, record, index) => {
        if (!record.isSummaryLine)
          return (
            <BaseSpace>
              {record.product?.type === 'PA' && (
                <>
                <BaseButton
                  type="ghost"
                  size="small"
                  icon={<PaperClipOutlined />}
                  style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                  onClick={() => handleAttachmentAction(record)}
                />
                <ViewPaperAttachmentSection
                  open={showPaperDetails}
                  setOpen={setShowPaperDetails}
                  name={paperDetails?.name || ''}
                  abstract={paperDetails?.abstract || ''}
                  url={paperDetails?.url || ''}
                  file={paperDetails?.file || ''}
                  price={paperDetails?.price || ''}
                />
              </>
              )}
              {editable && (
                <>
                  <BaseButton
                    type="ghost"
                    size="small"
                    disabled={!editable}
                    icon={<MdModeEdit />}
                    style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                    onClick={() => handleEditAction(index)}
                  />
                  <BaseButton
                    type="ghost"
                    size="small"
                    danger
                    disabled={!editable}
                    icon={<MdDelete />}
                    style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                    onClick={() => handleDeleteAction(index)}
                  />
                </>
              )}
            </BaseSpace>
          );
      },
    },
  ];

  const createOrderItem = (values: any) => {
    const product = products.find((product) => product.id === values.product_id);
    
     calculateUnitPrice(product?.id ?? 0,
        dayjs(values.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
        dayjs(values.end_date_time).format('YYYY-MM-DD HH:mm:ss'))
      .then((response) => {
        
        console.log(response.price)
        const orderItem: OrderLineItem = {
          product_id: product?.id ?? 0,
          cost: response.price,
          qty: values.qty??1,
          total: values.qty??1 * response.price,
          tax: 0,
          appointment: {
            customer_status: values.customer_status ?? '1',
            status: values.status ?? orderConfigs.default_appointment_status,
            start_date_time: dayjs(values.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
            end_date_time: dayjs(values.end_date_time).format('YYYY-MM-DD HH:mm:ss'),
            all_day: values.all_day ?? false,
            customer_timezone: values.customer_timezone,
            staff: values.staff ?? '1',
            appointment_status: appointmentStatues.find(e => e.key == (values.status?? orderConfigs.default_appointment_status))?.name ?? 'N/A'
          },
          isSummaryLine: false,
          product: {
            id: product?.id ?? 0,
            name: product?.name ?? '',
            sale_price: product?.sale_price ?? 0,
            type: product?.type ?? 'SI',
            is_appointable: product?.is_appointable ?? false,
          },
          action: '1',
          id: null,
        };
        console.log(orderItem)
        setItems([...items, orderItem]);
        onOrderItemStateChange([...items, orderItem]);
      });
    };

  const editOrderItem = (values: any) => {
    const product = products.find((product) => product.id === values.product_id);
    const item = items.find((_, index) => index === currentIndexOnEdit);
    if(!dayjs(values.start_date_time).isSame(item?.appointment?.start_date_time??null) ||
       !dayjs(values.end_date_time).isSame(item?.appointment?.end_date_time??null) || 
       values.product_id != (item?.product_id??null) )
    {
      calculateUnitPrice(product?.id ?? 0,
        dayjs(values.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
        dayjs(values.end_date_time).format('YYYY-MM-DD HH:mm:ss'))
      .then(response => {
        updateOrderItem(values,item,product,response.price);
      });
    }
    else{
      updateOrderItem(values,item,product,item?.cost);
    }
    
  };

  const updateOrderItem = (values: any,item:any,product: Product|undefined,cost:number) => {    
   
      console.log(item,values,cost,orderConfigs,values.status &&  orderConfigs.ol_total_reject_statuses.includes(values.status) ) ;
      const orderItem: OrderLineItem = {
        product_id: product?.id ?? 0,
        cost: cost,
        qty: values.qty??1,
        total: values.status &&  orderConfigs.ol_total_reject_statuses.includes(values.status) ? 0: (values.qty??1) * cost ,
        tax: 0,
        appointment: {
          customer_status: values.customer_status ?? '1',
          status: values.status ?? '1',
          start_date_time: dayjs(values.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
          end_date_time: dayjs(values.end_date_time).format('YYYY-MM-DD HH:mm:ss'),
          all_day: values.all_day ?? false,
          customer_timezone: values.customer_timezone,
          staff: values.staff ?? '1',
          id: item?.appointment?.id,
          appointment_status: appointmentStatues.find(e => e.key == values.status)?.name ?? 'N/A'
        },
        id: item?.id,
        isSummaryLine: false,
        product: {
          id: product?.id ?? 0,
          name: product?.name ?? '',
          sale_price: product?.sale_price ?? 0,
          type: product?.type ?? 'SI',
          is_appointable: product?.is_appointable ?? false,
        },
        action: item?.id !== null ? '2' : '1',
      };

      const existingItems = items.filter((_, index) => index !== currentIndexOnEdit);
      setItems([...existingItems, orderItem]);
      onOrderItemStateChange([...existingItems, orderItem]);
      setCurrentIndexOnEdit(undefined);
  };

  const handleSaveOrderItem = (saveAnother: boolean) => {
    const values: OrderLineFormRequest = form.getFieldsValue();

    form
      .validateFields(['product_id', 'qty'])
      .then(() => {
        if (formMode === 'edit') {
          editOrderItem(values);
        } else {
          createOrderItem(values);
        }
        setFormMode('create');
        form.resetFields();
        if (!saveAnother) {
          setActiveKey('1');
        }
      })
      .catch((error: any) => {
        notificationController.error({ message: 'Enter required fields!' });
      });
  };

  return type === 'view' ? (
    <BaseRow gutter={[16, 16]}>
      <BaseCol span={24}>
        <BaseTable
          pagination={false}
          bordered
          dataSource={[...items.filter((item) => item.action !== '4')]}
          columns={columns}
          footer={() => {
            return (
              <>
                <BaseRow style={{ color: `var(--primary-color)`, fontSize: `${FONT_SIZE.md}`,  fontWeight:`${FONT_WEIGHT.bold}`, lineHeight: `1.25rem` }}>
                  <BaseCol span={14}>Total</BaseCol>
                  <BaseCol span={10} style={{textAlign:'right', textDecoration: isCancelledOrder ? 'line-through' : 'none'}}>{`LKR ${orderTotal.toFixed(2)}`}</BaseCol>
                </BaseRow>
              </>
            );
          }}
          scroll={{ x: true, scrollToFirstRowOnChange: true }}
        />
      </BaseCol>
    </BaseRow>
  ) : (
    <>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onTabClick={(key: string) => {
          setActiveKey(key);
        }}
      >
        <TabPane
          tab={
            <span>
              <TableOutlined />
              {t('All Order Items')}
            </span>
          }
          key="1"
        >
          <BaseRow gutter={[16, 16]}>
            <BaseCol span={24}>
              <BaseButton
                type="ghost"
                size="small"
                style={{ fontSize: 12 }}
                disabled={!editable}
                onClick={() => {
                  setFormMode('create');
                  form.resetFields();
                  setShowAppointmentDetails(false);
                  setActiveKey('2');
                  console.log(formMode);
                }}
              >
                Add Order Item
              </BaseButton>
            </BaseCol>
            <BaseCol span={24}>
              <BaseTable
                bordered
                dataSource={[...items.filter((item) => item.action !== '4')]}
                pagination={false}
                columns={columns}
                footer={() => {
                  return (
                    <>
                      <BaseRow
                        style={{ color: `var(--primary-color)`, fontSize: `${FONT_SIZE.md}`,  fontWeight:`${FONT_WEIGHT.bold}`, lineHeight: `1.25rem` }}
                      >
                        <BaseCol span={14}>Total</BaseCol>
                        <BaseCol span={10} style={{textAlign:'right',textDecoration: isCancelledOrder ? 'line-through' : 'none'}}>{`LKR ${orderTotal.toFixed(2)}`}</BaseCol>
                      </BaseRow>
                    </>
                  );
                }}
                scroll={{ x: true, scrollToFirstRowOnChange: true }}
              ></BaseTable>
            </BaseCol>
          </BaseRow>
        </TabPane>
        <TabPane
          tab={
            <span>
              <EditOutlined />
              {t('Manage Order Item')}
            </span>
          }
          key="2"
        >
          <BaseButtonsForm
            name="orderlineForm"
            form={form}
            isFieldsChanged={isFieldsChanged}
            setFieldsChanged={setFieldsChanged}
          >
            <AppointmentFormFields
              editable={editable}
              products={products}
              customerStatusOptions={customerStatusOptions}
              appointmentStatusOptions={appointmentStatusOptions}
              staffOptions={staffOptions}
              inOrderForm={true}
              showAppointmentDetails={showAppointmentDetails}
              setShowAppointmentDetails={setShowAppointmentDetails}
            />
            <BaseRow gutter={[16, 16]}>
              <BaseCol>
                <BaseButton
                  style={{ marginTop: '10px' }}
                  type="ghost"
                  htmlType="button"
                  disabled={!editable}
                  onClick={() => handleSaveOrderItem(false)}
                >
                  Save Order Item
                </BaseButton>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  style={{ marginTop: '10px' }}
                  type="ghost"
                  htmlType="button"
                  disabled={!editable}
                  onClick={() => handleSaveOrderItem(true)}
                >
                  Save Order Item & Create Another
                </BaseButton>
              </BaseCol>
            </BaseRow>
          </BaseButtonsForm>
        </TabPane>
      </Tabs>
    </>
  );
};
