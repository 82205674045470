import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Space, Input } from 'antd';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RolesTable } from '@app/components/tables/BasicTable/RolesTable';

export const RolePermissionsTablePage:React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlebtnClick = () => {
    navigate('/create-role-permissions');
  };

  return (
    <>
    <PageTitle>{t('Roles')}</PageTitle>
      <Space style={{ marginBottom: '16px', justifyContent: 'space-between', width: '100%' }}>
        <BaseButton icon={<PlusOutlined />} type="primary" onClick={handlebtnClick}>
          {t('Add New Role')}
        </BaseButton>
      </Space>
        <RolesTable/>
      
    </>
  );
}

