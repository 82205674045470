import { InfoCircleFilled, ExclamationCircleFilled, MinusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FONT_FAMILY, FONT_SIZE, media } from '@app/styles/themes/constants';
import { Statistic } from 'antd';
import styled from 'styled-components';

export const InfoCircle = styled(InfoCircleFilled)`
  font-size: ${FONT_SIZE.xxl};
 
  color: var(--success-color);

  font-family: ${FONT_FAMILY.secondary};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xxxl};
  }
`;

export const ExclamationCircle = styled(ExclamationCircleFilled)`
  font-size: ${FONT_SIZE.xxl};
  
  color: var(--warning-color);

  font-family: ${FONT_FAMILY.secondary};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xxxl};
  }
`;

export const CloseCircle = styled(CloseCircleFilled)`
  font-size: ${FONT_SIZE.xxl};
  
  color: var(--error-color);

  font-family: ${FONT_FAMILY.secondary};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xxxl};
  }
`;

export const MinusCircle = styled(MinusCircleFilled)`
  font-size: ${FONT_SIZE.xxl};
 
  color: var(--primary-color);

  font-family: ${FONT_FAMILY.secondary};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xxxl};
  }
`;

export const Stats = styled(Statistic)`
  .ant-statistic-content-value {
    font-size: ${FONT_SIZE.xxxl};
  }

  @media only screen and ${media.xl} {
    .ant-statistic-content-value {
      font-size: ${FONT_SIZE.xxxxl};
    }
  }
`;

export const PrimaryBaseCard = styled(BaseCard)`
  border: 2px solid var(--primary-color);
`;

export const WarningBaseCard = styled(BaseCard)`
  border: 2px solid var(--warning-color);
`;

export const ErrorBaseCard = styled(BaseCard)`
  border: 2px solid var(--error-color);
`;

export const SuccessBaseCard = styled(BaseCard)`
  border: 2px solid var(--success-color);
`;

export const Title = styled(BaseTypography.Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-size: ${FONT_SIZE.xs};
  }
`;
