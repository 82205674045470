
interface Ability {
  [resource: string]: string[];
}
// Token management service
class AbilityService {
  private static instance: AbilityService;
  private abilities: string | null;

  private constructor() {
    // Retrieve the token from local storage during initialization
    this.abilities = localStorage.getItem('user_abilities');
  }

  public static getInstance(): AbilityService {
    if (!this.instance) {
      this.instance = new AbilityService();
    }
    return this.instance;
  }

  public setAbilities(user_abilities: Ability): void {
    const serialized_abilities =  JSON.stringify(user_abilities?user_abilities:'');
    localStorage.setItem('user_abilities', serialized_abilities);
    this.abilities = serialized_abilities;
  }

  public getAbilities(): Ability | null {
    return this.abilities ? JSON.parse(this.abilities) : null;
  }

  public deleteAbilities(): void {
    localStorage.removeItem('user_abilities');
    this.abilities = null;
  } 
}


// Export the TokenService instance
export const abilityService = AbilityService.getInstance();