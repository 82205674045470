import { PrinterOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import React from 'react';
import PDFDownloader from '@app/components/downloader/pdfdownloader/PDFDownloader';
import PdfViewer from '@app/components/downloader/pdfdownloader/PdfViewer';

import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useTranslation } from 'react-i18next';

interface Props {
  invoiceUrl?: string;
  name?: string;
}

export const PrinctInvoiceSection: React.FC<Props> = (props) => {
  const { invoiceUrl,name } = props;
  const { t } = useTranslation();
  return (
    <BaseCard
            title={t('Print Invoice')}
            padding="1.25rem"
            style={{
              width: '100%',
              height: 'fit-content',
              margin: '20px',
            }}
          >
          {invoiceUrl && name && (
            <PDFDownloader pdfUrl={invoiceUrl} name={name} />
          ) || <>{'The Invoice Not Found'}</>}

          {/* {invoiceUrl && name && (
            <PdfViewer pdfUrl={invoiceUrl} />
          ) || <>{'Not Found Any Invoice'}</>} */}
    </BaseCard>
  );
};
