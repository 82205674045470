
import { UserModel } from '@app/domain/UserModel';

// Token management service
class AuthUserService {
  private static instance: AuthUserService;
  private user: string | null;

  private constructor() {
    // Retrieve the token from local storage during initialization
    this.user = localStorage.getItem('user');
  }

  public static getInstance(): AuthUserService {
    if (!this.instance) {
      this.instance = new AuthUserService();
    }
    return this.instance;
  }

  public setUser(user: UserModel): void {
    const serialized_user =  JSON.stringify(user?user:'');
    localStorage.setItem('user', serialized_user);
    this.user = serialized_user;
  }

  public getUser(): UserModel | null {
    return this.user ? JSON.parse(this.user) : null;
  }

  public deleteUser(): void {
    localStorage.removeItem('user');
    this.user = null;
  } 
}


// Export the TokenService instance
export const authUserService = AuthUserService.getInstance();