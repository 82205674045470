import { AppointmentStatusOption, CustomerStatusOption, Product, StaffOption } from '@app/api/appointment.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { Checkbox, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import timezones from 'timezones-list';

interface AppointmentFormFieldsProps {
  editable: boolean;
  products: Product[];
  customerStatusOptions: CustomerStatusOption[];
  appointmentStatusOptions: AppointmentStatusOption[];
  staffOptions: StaffOption[];
  inOrderForm: boolean;
  showAppointmentDetails: boolean;
  setShowAppointmentDetails?: any;
}

export const AppointmentFormFields: React.FC<AppointmentFormFieldsProps> = ({
  inOrderForm,
  editable = false,
  products,
  customerStatusOptions,
  appointmentStatusOptions,
  staffOptions,
  showAppointmentDetails,
  setShowAppointmentDetails
}) => {
  const [disableEndDateTime, setDisableEndDateTime] = useState(true);
  const [minEndDateTime, setMinEndDateTime] = useState(dayjs());
  const [maxQty,setMaxQty] = useState(1)

  useEffect(() => {
    if (!inOrderForm) {
      setShowAppointmentDetails(true);
    }
  }, [inOrderForm]);

  const handleProductOnChange = (value: any) => {
    const isAppointable = products?.find((product) => product.id === value)?.is_appointable;
    const qty = products?.find((product) => product.id === value)?.quantity ?? 10;
    console.log(products?.find((product) => product.id === value)?.quantity)
    setMaxQty(qty)
    setShowAppointmentDetails(isAppointable ?? false);
  };

  const disabledDate = (current: any) => {
    return dayjs(current).isBefore(minEndDateTime);
  };

  const disabledStartDate = (current: any) => {
    return dayjs(current).isBefore(dayjs());
  };

  return (
    <>
      <BaseRow gutter={[16, 16]}>
        <BaseCol xs={24} md={8} lg={12}>
          <BaseButtonsForm.Item
            name="product_id"
            label="Product"
            rules={[{ required: true, message: 'Product is required' }]}
          >
            <BaseSelect
              placeholder="Select Product"
              disabled={!editable }
              filterOption={true}
              onChange={handleProductOnChange}
            >
              {products?.map((option) => (
                <Option key={option.id} value={option.id}>
                  {`${option.name} (#${option.id})`}
                </Option>
              ))}
            </BaseSelect>
          </BaseButtonsForm.Item>
        </BaseCol>
        <BaseCol xs={24} md={12} lg={12}>
          <BaseButtonsForm.Item
            name="qty"
            label="Quantity"
            key="quantity"
            // rules={[{min:1, max:maxQty, message: 'Maximum quantity has exceeded.' }]}
          >
            <InputNumber placeholder="Enter Quantity"  min={1} disabled={!editable} style={{ width: '100%' }} />
          </BaseButtonsForm.Item>
          <p style={{ fontSize: '12px', color: '#888' }}>The quantity provided must not exceed the quantity currently in stock.</p>
        </BaseCol>
      </BaseRow>
      <br />
      {showAppointmentDetails && (
        <>
          <h4>
            <b>Appointment Details</b>
          </h4>
          <br />

          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={8} lg={12}>
              <BaseButtonsForm.Item name="customer_status" label="Customer Status">
                <BaseSelect placeholder="Select Customer Status" disabled={!editable}>
                  {customerStatusOptions?.map((option) => (
                    <Option key={option.key} value={option.key}>
                      {option.name}
                    </Option>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={8} lg={12}>
              <BaseButtonsForm.Item name="status" label="Appointment Status">
                <BaseSelect placeholder="Select Appointment Status" disabled={!editable}>
                  {appointmentStatusOptions?.map((option) => (
                    <Option key={option.key} value={option.key} disabled={option.disabled}>
                      {option.name}
                    </Option>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={8} lg={12}>
              <BaseButtonsForm.Item name="staff" label="Staff">
                <BaseSelect placeholder="Select Staff" disabled={!editable}>
                  {staffOptions?.map((option) => (
                    <Option key={option.key} value={option.key}>
                      {option.name}
                    </Option>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <br />
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={24}>
              <BaseButtonsForm.Item name="all_day" valuePropName="checked" label="All Day">
                <Checkbox>
                  <p style={{ fontSize: 13 }}>Check this box if the appointment lasts all day.</p>
                </Checkbox>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={12} lg={12}>
              <BaseButtonsForm.Item label="Start Date & Time" name="start_date_time">
                <BaseDatePicker
                  style={{ display: 'flex', flexGrow: 1 }}
                  disabled={!editable}
                  onSelect={(value) => {
                    setDisableEndDateTime(false);
                    setMinEndDateTime(dayjs(value));
                  }}
                  showTime={true}
                  placeholder="Select Start Date & Time"
                  disabledDate={disabledStartDate}
                />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12} lg={12}>
              <BaseButtonsForm.Item label="End Date & Time" name="end_date_time">
                <BaseDatePicker
                  style={{ display: 'flex', flexGrow: 1 }}
                  disabled={!editable || disableEndDateTime}
                  showTime={true}
                  placeholder="Select End Date & Time"
                  disabledDate={disabledDate}
                />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={8} lg={12}>
              <BaseButtonsForm.Item name="customer_timezone" label="Customer Timezone">
                <BaseSelect placeholder="Select Customer Timezone" disabled={!editable} filterOption={true}>
                  {timezones.map((option) => (
                    <Option key={option.tzCode} value={option.tzCode}>
                      {option.tzCode}
                    </Option>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </>
      )}
    </>
  );
};
