import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { RolePermissionsForm } from '@app/components/forms/RolePermissionsForm/RolePermissionsForm';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const ViewRolePermissionsPage:React.FC = () => {
  const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('View-Role-Permissions')}</PageTitle>
      <RolePermissionsForm type={"view"}  editable = {false} id={id} pageTitle={`View Role & Permissions`}/>
    </>
  );
  
}