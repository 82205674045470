import React, { useState, useEffect } from 'react';
import { Input, Select, Space, Tooltip } from 'antd';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useTranslation } from 'react-i18next';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import {
  CustomerRequest,
  storeCustomers,
  updateCustomer,
  Customer,
  CustomerApiData,
  showCustomer,
  editCustomer,
  createCustomer,
} from 'api/customers.api';
import { StatusOption } from '@app/api/user.api';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import * as countries from 'countries-list';
import { EyeOutlined, EyeInvisibleOutlined, TableOutlined } from '@ant-design/icons';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface CustomerFormProps extends TypeOfForm {
  editable: boolean;
  id?: string;
}

export const CustomerForm: React.FC<CustomerFormProps> = ({ type, id, editable = false }) => {
  const customerId = id ? parseInt(id) : null;
  const [pageTitle, setPageTitle] = useState<string>('');
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const navigate = useNavigate();
  const [selectedProfileImage, setSelectedProfileImage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [statusOptions, setStatusOptions] = useState<StatusOption[]>([]);
  //const [customer, setCustomer] = useState<Customer|null>();
  const [form] = BaseButtonsForm.useForm();
  const handleErrorMessages = useApiErrorHandler(); 

  form.setFieldsValue({
    country: 'LK',
  });

  // Handle profile picture change
  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedProfileImage(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const getPopupContainer = (triggerNode: HTMLElement | null) => {
    return triggerNode || document.body;
  };

  const handleSubmit = (values: CustomerRequest) => {
    setLoading(true);
    values.image = selectedProfileImage;
    console.log(values);

    if (editable) {
      if (type === 'create') {
        storeCustomers(values)
          .then((res: any) => {
            setLoading(false);
            navigate(`/view-customer/${res?.data?.id}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      } 
      else if (type === 'edit' && customerId) {
          console.log(customerId, values);
          updateCustomer(customerId, values)
          .then(() => {
            setLoading(false);
            navigate(`/view-customer/${customerId}`);
          })
          .catch((err: any) => {
            notificationController.error({ message: err.message });
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      }
    }
  };

  const countryOptions = 
    Object.entries(countries.countries).map(([code,country]) => (
      {
        code: code,
        name: country.name,
      }
    ))
  ;

  console.log(countryOptions);
  const setApiData = (res: CustomerApiData) => {
    console.log(res);
    //setCustomer(res?.customer);
    setStatusOptions(res?.status_options);
    setImageUrl(res?.customer?.image_url||'');
    form.setFieldsValue({
      username: res?.customer?.user?.username,
      email: res?.customer?.user?.email,
      password: res?.customer?.user?.password,
      status: res?.customer?.user?.status,
      first_name: res?.customer?.first_name,
      last_name: res?.customer?.last_name,
      company: res?.customer?.company,
      address_line1: res?.customer?.address_line1,
      address_line2: res?.customer?.address_line2,
      bill_email: res?.customer?.bill_email,
      phone: res?.customer?.phone,
      state: res?.customer?.state,
      postcode: res?.customer?.postcode,
      country: res?.customer?.country,
      city: res?.customer?.city,
    });
  };

  useEffect(() => {
    setLoading(true);
    if (customerId) {
      if (type === 'view') {
        showCustomer(customerId)
          .then((res: any) => {
            setPageTitle(`${res?.customer?.user?.name}`);
            setApiData(res);
            setLoading(false);
          })
          .catch((error) =>handleErrorMessages(error));
      } else if (type === 'edit') {
        editCustomer(customerId)
          .then((res: any) => {
            setLoading(false);
            setPageTitle(`${res?.customer?.user?.name}`);
            setApiData(res);
          })
          .catch((error) =>handleErrorMessages(error));
      }
    } else {
      createCustomer()
        .then((res: any) => {
          setLoading(false);
          setPageTitle(`New`);
          setApiData(res);
        })
        .catch((error) =>handleErrorMessages(error));
    }
  }, []); // The empty dependency array means this effect will only run once, equivalent to componentDidMount
console.log(imageUrl);
  return (
    <BaseCard
      id="customerForm"
      title={`Customer - ` + pageTitle || 'New'}
      padding="1.25rem"
      style={{ margin: '20px', marginTop: '0px' }}
      extra={
        <>
          {(type === 'view' && (
            <>
              <Space>
                <Tooltip title={t('common.edit')}>
                  <BaseButton type="text" onClick={() => navigate(`/edit-customer/${id}`)} icon={<MdModeEdit />} />
                </Tooltip>
              </Space>
            </>
          )) ||
            (type === 'edit' && (
              <>
                <Space>
                  <Tooltip title={t('view')}>
                    <BaseButton type="text" onClick={() => navigate(`/show-customer/${id}`)} icon={<IoEye />} />
                  </Tooltip>
                </Space>
              </>
            ))}
          <Space>
            <Tooltip title={'View all'}>
              <BaseButton type="text" onClick={() => navigate('/all-customers')} icon={<TableOutlined />} />
            </Tooltip>
          </Space>
        </>
      }
    >
      <BaseButtonsForm
        name="customerForm"
        onFinish={(values) => {
          console.log('Form Values from onFinish:', values);
          handleSubmit(values);
        }}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        form={form}
      >
        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={8}>
            <section>
              {/* <h3>
                <b>Name</b>
              </h3> */}
              <BaseRow gutter={[16, 16]}>
                <BaseCol xs={24}>
                  <BaseButtonsForm.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Username is required' }]}
                  >
                    <BaseInput disabled={!editable || type != 'create'} />
                  </BaseButtonsForm.Item>
                </BaseCol>
              </BaseRow>
            </section>
          </BaseCol>

          <BaseCol xs={24} md={16} lg={16}>
            <section>
              <BaseRow gutter={[16, 16]}>
                <BaseCol xs={24} md={12}>
                  <BaseButtonsForm.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }]}
                  >
                    <BaseInput placeholder="Enter Email" type="email" disabled={!editable} />
                  </BaseButtonsForm.Item>
                </BaseCol>
              </BaseRow>
            </section>
          </BaseCol>
        </BaseRow>

        <section>
          {type === 'create' && (
            <BaseRow gutter={[16, 16]}>
              <BaseCol xs={24} md={8}>
                <BaseButtonsForm.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: ' Password is required' },
                    { min: 8, message: 'Password must have at least 8 characters' },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                    disabled={!editable}
                  />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={8}>
                <BaseButtonsForm.Item
                  label="Confirm Password"
                  name="password_confirmation"
                  dependencies={['password']}
                  rules={[
                    { required: true, message: 'Please confirm your password' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords do not match'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                    disabled={!editable}
                  />
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
          )}
          <BaseRow gutter={[16, 16]}>
            <BaseCol xl={8} lg={12} md={12} sm={24} xs={24} >
              <BaseButtonsForm.Item name="status" label="Status">
                <BaseSelect placeholder="Select status" disabled={!editable}>
                {Array.isArray(statusOptions) && statusOptions.map((option) => (
                    <Option key={option.name} value={option.key}>
                      {option.name}
                    </Option>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </section>
        <br />

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={8}>
            <section>
              <BaseRow gutter={[16, 16]}>
                <BaseCol xs={24}></BaseCol>
              </BaseRow>
            </section>
          </BaseCol>
          <BaseCol xs={24} md={12} lg={12}></BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={12} lg={12}>
            <section>
              <BaseRow gutter={[16, 16]}>
                <BaseCol xs={24}>
                  <BaseButtonsForm.Item label="Profile Picture" name="image">
                    <input type="file" accept="image/*" onChange={handleProfileImageChange} hidden={!editable} />
                    <br  hidden={!editable}/>
                    {selectedProfileImage && <img src={selectedProfileImage} alt="thumbnail"
                     style={{ width: '100px', height: '100px' }} />}

                    <BaseImage src={imageUrl} style={{ width: '100px', height: '100px' }} hidden={!!selectedProfileImage}>
              </BaseImage>
                  </BaseButtonsForm.Item>
                </BaseCol>
              </BaseRow>
            </section>
          </BaseCol>
        </BaseRow>
        <br />

        <section>
          <h3>
            <b>Customer Billing Address</b>
          </h3>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <BaseInput placeholder="Enter First Name" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <BaseInput placeholder="Enter Last Name" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item label="Company" name="company">
                <BaseInput placeholder="Enter Company" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item label="Address Line 1" name="address_line1">
                <BaseInput placeholder="Enter Address Line 1" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item label="Address Line 2" name="address_line2">
                <BaseInput placeholder="Enter Address Line 2" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item label="City" name="city">
                <BaseInput placeholder="Enter City" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item label="Postcode/ZIP" name="postcode">
                <BaseInput placeholder="Enter Postcode/ZIP" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item label="Country" name="country">
                <BaseSelect placeholder="Select Country"
                            showSearch
                            filterOption={(input, option) => option?.key?.toLowerCase()?.includes(input?.toLowerCase())} 
                            disabled={!editable}>
                  {Array.isArray(countryOptions) && countryOptions.map((option) => (
                    <Option key={option.name} value={option.code}>
                      {option.name}
                    </Option>
                  ))}
                {/* </Select> */}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item label="State" name="state">
                <BaseInput placeholder="Enter State" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item
                label="Phone"
                name="phone"
                rules={[{ pattern: /^[0-9]+$/, message: 'Please enter a valid phone number' }]}
              >
                <BaseInput type="tel" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={6}>
              <BaseButtonsForm.Item
                label="Billing Email"
                name="bill_email"
                rules={[{ required: true, message: 'Billing Email is required' }]}
              >
                <BaseInput placeholder="Enter Billing Email " type="email" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>

            
          </BaseRow>
        </section>
        <BaseButtonsForm.Item>
          <BaseButton
            type="primary"
            htmlType="submit"
            style={{ marginTop: '40px' }}
            loading={isLoading}
            disabled={type === 'view'}
          >
            Save
          </BaseButton>
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </BaseCard>
  );
};

export default CustomerForm;
