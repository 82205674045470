import { QuestionCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Pagination, RoleTableRow, getRoleTableData } from '@app/api/role.api';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useMounted } from '@app/hooks/useMounted';
import { Space, Tooltip, Button, Popconfirm, Input, InputRef } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoEye } from 'react-icons/io5';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const RolesTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: RoleTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const handleErrorMessages = useApiErrorHandler();
  const navigate = useNavigate();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  
  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getRoleTableData({...pagination, search:  form.getFieldValue('search')})
        .then((res) => {
          if (isMounted.current) {
            setTableData({ data: res.data, pagination: res.pagination, loading: false });
          }
        })
        .catch((error: any) => {
          handleErrorMessages(error);
        });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };
  const handleViewRow = (id: number) => {
    navigate(`/view-role-permissions/${id}`);
  };

  const handleEditRow = (id: number) => {
    navigate(`/edit-role-permissions/${id}`);
  };

  const handleDeleteRow = (id: number) => {
    console.log(`Deleting row with id ${id}`);
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Roles`}</span>
    );
  };

  const columns: ColumnsType<RoleTableRow> = [
    {
      title: 'Role name',
      dataIndex: 'role_name',
      key: 'role_name',
      width: '30%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '30%',
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Button type="text" onClick={() => handleViewRow(record.id)} icon={<IoEye />} />
          </Tooltip>

          <Tooltip title="Edit">
            <Button type="text" onClick={() => handleEditRow(record.id)} icon={<MdModeEdit />} />
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this role?"
              onConfirm={() => handleDeleteRow(record.id)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <Button type="text" danger icon={<MdDelete />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <BaseCard id="basic-table" title="Roles" padding="1.25rem" 
    extra={
      <BaseButtonsForm
      form={form}
      layout="inline"
      isFieldsChanged={fieldsChanged}
      setFieldsChanged={setFieldsChanged}
    >

      <BaseButtonsForm.Item name={'search'}>
        <Input
          size="middle"
          suffix={<Button icon={<SearchOutlined  />} size='middle' type='text' onClick={()=>{fetch(initialPagination)}} />}
          placeholder={'Search Roles'}
        />
      </BaseButtonsForm.Item>
      <Button type='ghost' size='middle' icon={<ReloadOutlined />} 
        onClick={()=>{
          form.resetFields(['search']);
          fetch(initialPagination);
          }}/>
    </BaseButtonsForm>
    }>
        <BaseTable
          columns={columns}
          dataSource={tableData.data}
          pagination={{
            current: tableData.pagination.current,
            pageSize: tableData.pagination.pageSize,
            total: tableData.pagination.total,
            showTotal,
          }}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{ x: true, scrollToFirstRowOnChange: true }}
        />
        </BaseCard>
  );
};
