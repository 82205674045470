import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostCategoryForm } from '@app/components/forms/PostCategoryForm/PostCategoryForm'; // Adjust import path
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const AddPostCategoryPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <PageTitle>{t('Add-Post-Category')}</PageTitle>  */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <PostCategoryForm type={"create"} editable={true}/> 
    </>
  );
};

export default AddPostCategoryPage;
