import { get, post } from '@app/api/http.api';

export interface Permission {
  id: number;
  permission_id: string;
  description: string;
}

export interface PermissionTableRow {
  key: React.Key;
  id: number;
  permission_id: string;
  description: string;
}

export interface RoleRequest {
  id: number | null;
  name: string;
  description: string;
  permissions: number[];
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

export interface RoleTableData {
  data: RoleTableRow[];
  pagination: Pagination;
}

export interface RoleApiData {
  role: Role;
  role_permissions: any[];
  permissions: Permission[];
}

export interface Role {
  id: number | null;
  role_name: string;
  description: string;
}

export interface RoleTableRow {
  id: number;
  role_name: string;
  description: string;
}

export interface UserNavigationPermission {
  show_all_appointments: boolean;
  show_calender: boolean;
  show_all_orders: boolean;
  show_all_categories: boolean;
  show_all_products: boolean;
  show_all_users: boolean;
  show_profile: boolean;
  show_all_customers: boolean;
  show_all_meetings: boolean;
  show_all_roles: boolean;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
}

export const getRoleTableData = async (parameters:Parameters): Promise<RoleTableData> => {
  try {
    const { data, status } = await get<PaginatedResponse<RoleTableRow>>('roles', { params: parameters }, true);
    console.log(`Status: ${status}`, `Data: ${data}`);

    const result = data.data.map((rawData: RoleTableRow) => ({
      id: rawData.id,
      role_name: rawData.role_name,
      description: rawData.description,
    }));

    return {
      data: result,
      pagination: {
        current: data.current_page,
        pageSize: data.per_page,
        total: data.total,
      },
    };
  } catch (error: any) {
    throw error;
  }
};

export const storeRoles = async (StoreRoleData: RoleRequest) => {
  try {
    const { data, status } = await post<RoleRequest>(`roles/create`, StoreRoleData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error(`Error: ${error.status} - ${error.message}`);
  }
};

export const updateRole = async (id: number, UpdateRoleData: RoleRequest) => {
  try {
    const { data, status } = await post<RoleRequest>(`roles/${id}/edit`, UpdateRoleData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error(`Error: ${error.status} - ${error.message}`);
    // notificationController.error({ message: `Error: ${error.status} - ${error.message}`});
  }
};

export const createRole = async () => {
  try {
    const { data, status } = await get<RoleApiData>(`roles/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error(`Error: ${error.status} - ${error.message}`);
  }
};

export const showRole = async (id: number) => {
  try {
    const { data, status } = await get<RoleApiData>(`roles/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error(`Error: ${error.status} - ${error.message}`);
  }
};

export const editRole = async (id: number) => {
  try {
    const { data, status } = await get<RoleApiData>(`roles/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error(`Error: ${error.status} - ${error.message}`);
  }
};

export const getUserPermissions = async () => {
  try {
    const { data, status } = await get<UserNavigationPermission>(`roles/user-permission`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw new Error(`Error: ${error.status} - ${error.message}`);
  }
};
