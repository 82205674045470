import React , {useEffect, useState }from 'react';
import { useTranslation } from 'react-i18next';
import { ProductForm } from '@app/components/forms/ProductsForm/ProductsForm';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { showProduct } from '@app/api/product.api';


interface RouteParams {
  id: string;
  [key: string]: string | undefined;
  
}

const ViewProductPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  // const [productName, setProductName] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(true);

  // useEffect(() => {
  //   // Fetch user data when component mounts
  //   if (id) {
  //     showProduct(parseInt(id)).then((res: any) => {
  //       setProductName(res?.product?.name || '');
  //       setLoading(false);
  //     });
  //   }
  // }, [id]);

  // if (loading) {
  //   return <div>Loading...</div>; 
  // }

  return (
    <>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
        {/* <PageTitle>{t('common.login')}</PageTitle> */}
        <PageTitle>{"JRDC-Admin"}</PageTitle>
        <ProductForm type={"view"} id={id} editable = {false}/>
      {/* </div> */}
      
      
    </>
  );
};

export default ViewProductPage;
