import {
  MeetingApiData,
  MeetingFormData,
  MeetingRequest,
  createMeeting,
  editMeeting,
  showMeeting,
  storeMeetings,
  updateMeeting,
} from '@app/api/meeting.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { notificationController } from '@app/controllers/notificationController';
import { DatePicker, InputNumber, Space, TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { MdModeEdit } from 'react-icons/md';
import { EyeInvisibleOutlined, EyeOutlined, TableOutlined } from '@ant-design/icons';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { IoEye } from 'react-icons/io5';

interface MeetingFormProps {
  type: 'create' | 'edit' | 'view';
  editable: boolean;
  id?: string;
  // showEditIcon?: boolean;
  // pageTitle: string;
}

export const MeetingForm: React.FC<MeetingFormProps> = ({
  type,
  editable = false,
  id,
  // pageTitle,
  // showEditIcon = false,
}) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const meetingId = id ? parseInt(id) : null;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState<string>('');
  const handleErrorMessages = useApiErrorHandler(); 

  const setApiData = (res: MeetingApiData) => {
    form.setFieldsValue({
      topic: res?.topic,
      agenda: res?.agenda,
      start_time: dayjs(res?.start_time),
      duration: res?.duration,
      password: res?.password,
      waiting_room:  res?.settings?.waiting_room || false,
      join_before_host: res?.settings?.join_before_host || false,
      host_video: res?.settings?.host_video || false,
      participant_video: res?.settings?.participant_video || false,
      mute_upon_entry: res?.settings?.mute_upon_entry || false,
    });
  };

  useEffect(() => {
    // Function to fetch data from the API
    setLoading(true)
    if (meetingId) {
      if (type === 'view') {
        showMeeting(meetingId).then((res: any) => {
          console.log('dsfdsfdsf', res);
          setPageTitle(`${res?.topic}`);
          setApiData(res);
          setLoading(false);
        })
        .catch((error) =>  handleErrorMessages(error));
      } else if (type === 'edit') {
        editMeeting(meetingId).then((res: any) => {
          setLoading(false);
          console.log('edit =>', res);
          setPageTitle(`${res?.topic}`);
          setApiData(res);
        })
        .catch((error) =>  handleErrorMessages(error));
      }
    } else {
      createMeeting().then((res: any) => {
        setLoading(false);
        setApiData(res);
        setPageTitle(`New`);
      })
      .catch((error) =>  handleErrorMessages(error));
    }
  }, []);

  const handleEditIconClick = () => {
    navigate(`/edit-meeting/${id}`);
  };

  const handleViewIconClick = () => {
    navigate(`/view-meeting/${id}`);
  };

  const rearrangeFormValues = (values: MeetingFormData): MeetingRequest => {
    return {
      topic: values.topic,
      agenda: values.agenda ?? '',
      start_time: dayjs(values.start_time).format(),
      duration: values.duration,
      password: values.password,
      settings: {
        waiting_room: values.waiting_room || false,
        join_before_host: values.join_before_host || false,
        host_video: values.host_video || false,
        participant_video: values.participant_video || false,
        mute_upon_entry: values.mute_upon_entry || false,
      },
    };
  };

  const handleSubmit = (values: MeetingFormData) => {
    setLoading(true);

    const data: MeetingRequest = rearrangeFormValues(values);
    console.log(data, values.start_time);
    if (editable) {
      if (type === 'create') {
        storeMeetings(data)
          .then((res: any) => {
            setLoading(false);
            console.log(res);
            navigate(`/view-meeting/${res?.data?.id}`);
          })
          .catch((err: any) => {
            // notificationController.error({ message: err.message });
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      } else if (type === 'edit' && meetingId) {
        console.log(meetingId, data);
        updateMeeting(meetingId, data)
          .then(() => {
            setLoading(false);
            navigate(`/view-meeting/${id}`);
          })
          .catch((err: any) => {
            // notificationController.error({ message: `${err.message}` });
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      }
    }
  };

  return (
    <>
      <BaseCard
        id="validation form"
        // title={pageTitle}
        title={`Meeting - `+  pageTitle || 'New'}
        padding="1.25rem"
        style={{ margin: '20px', marginTop: '0px', width: '98%' }}
        extra={
          <>
         { 
            type === 'view' && (
              <>
                <Space>
                  <Tooltip title={t('common.edit')}>
                    <BaseButton type="text" onClick={() => handleEditIconClick()} icon={<MdModeEdit />} />
                  </Tooltip>
                </Space>
              </>
            )
          ||
            type === 'edit' && (
              <>
                <Space>
                  <Tooltip title={t('view')}>
                    <BaseButton type="text" onClick={() => handleViewIconClick()} icon={<IoEye />} />
                  </Tooltip>
                </Space>
              </>
            )
          }
          <Space>
            <Tooltip title={'View all'}>
              <BaseButton type="text" onClick={() => navigate('/all-meetings')} icon={<TableOutlined />} />
            </Tooltip>
          </Space>
        </> 
          
        }
      >
        <BaseButtonsForm
          //   {...formItemLayout}

          name="meetingsForm"
          onFinish={handleSubmit}
          form={form}
          loading={isLoading}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
        >
          {/* {type === 'view' && showEditIcon && (
            <Tooltip title={t('common.edit')}>
              <MdModeEdit onClick={handleEditIconClick} />
            </Tooltip>
          )} */}
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={8} lg={12}>
              <BaseButtonsForm.Item
                name="topic"
                label="Topic"
                key="topic"
                rules={[{ required: true, message: 'The topic is required' }]}
              >
                <BaseInput placeholder="Enter topic" disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={8} lg={12}>
              <BaseButtonsForm.Item
                name="agenda"
                label="Agenda"
                key="agenda"
                rules={[{ required: true, message: 'Agenda is required' }]}
              >
                <BaseInput.TextArea placeholder={t('Enter Meeting Agenda')} disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={12} span={12}>
              <BaseButtonsForm.Item
                label="Meeting Date & Time"
                name="start_time"
                rules={[{ required: true, message: 'Date & Time is required' }]}
              >
                <BaseDatePicker
                  style={{ display: 'flex', flexGrow: 1 }}
                  disabled={!editable}
                  showTime={{ format: 'HH:mm' }}
                  format="MMMM D, YYYY  HH:mm a"
                  use12Hours
                  placeholder="Select Meeting Date & Time"
                />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={8} lg={12} span={12}>
              <BaseButtonsForm.Item
                name="duration"
                label="Meeting Duration in minutes"
                key="duration"
                rules={[{ required: true, message: 'The meeting duration is required' }]}
              >
                <InputNumber placeholder="Enter duration" disabled={!editable} min={0} style={{ width: '100%' }} />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]}>
            <BaseCol xs={24} md={8} lg={12}>
              <BaseButtonsForm.Item
                name="password"
                label="Meeting Password"
                key="password"
                rules={[{ required: true, message: 'The password is required' }]}
              >
                <BaseInput.Password placeholder="Enter Meeting Password" disabled={!editable} 
                iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}/>
              </BaseButtonsForm.Item>
              <ul style={{ fontSize: '12px', color: '#888',marginLeft:'1.25rem' }}>
                <li>The password must contain numerical digits only.</li>
                <li>Only users who have the invite link or passcode can join the meeting.</li>
              </ul>
            </BaseCol>
          </BaseRow>
          <br />
          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol span={2}>
              <BaseButtonsForm.Item name="waiting_room" key="waiting_room" valuePropName="checked"  fieldId="waiting_room">
                <BaseSwitch checkedChildren={'on'} unCheckedChildren={'off'} disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol span={10}>
              <div style={{ color: 'var(--primary-color)', fontSize: '14px' }}>Waiting Room</div>
              <p style={{ fontSize: '12px', color: '#888' }}>Only users admitted by the host can join the meeting</p>
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol span={2}>
              <BaseButtonsForm.Item
                name="join_before_host"
                key="join_before_host"
                valuePropName="checked"
                fieldId="join_before_host"
                style={{ paddingTop: '7px' }}
              >
                <BaseSwitch
                  id="join_before_host"
                  checkedChildren={'on'}
                  unCheckedChildren={'off'}
                  disabled={!editable}
                />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol span={10}>
              <div style={{ color: 'var(--primary-color)', fontSize: '14px' }}>Allow participants to join anytime</div>
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol span={2}>
              <BaseButtonsForm.Item
                name="host_video"
                key="host_video"
                valuePropName="checked"
                fieldId="host_video"
                style={{ paddingTop: '7px' }}
              >
                <BaseSwitch checkedChildren={'on'} unCheckedChildren={'off'} disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol span={10}>
              <div style={{ color: 'var(--primary-color)', fontSize: '14px' }}>Host video</div>
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol span={2}>
              <BaseButtonsForm.Item
                name="participant_video"
                key="participant_video"
                valuePropName="checked"
                fieldId="participant_video"
                style={{ paddingTop: '7px' }}
              >
                <BaseSwitch checkedChildren={'on'} unCheckedChildren={'off'} disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol span={10}>
              <div style={{ color: 'var(--primary-color)', fontSize: '14px' }}>Participants video</div>
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol span={2}>
              <BaseButtonsForm.Item
                name="mute_upon_entry"
                key="mute_upon_entry"
                valuePropName="checked"
                fieldId="mute_upon_entry"
                style={{ paddingTop: '7px' }}
              >
                <BaseSwitch checkedChildren={'on'} unCheckedChildren={'off'} disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol span={10}>
              <div style={{ color: 'var(--primary-color)', fontSize: '14px' }}>Mute participants upon entry</div>
            </BaseCol>
          </BaseRow>

          <br />
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading} disabled={!editable}>
              {t('Save Meeting')}
            </BaseButton>
          </BaseButtonsForm.Item>
        </BaseButtonsForm>
      </BaseCard>
    </>
  );
};
