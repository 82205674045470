import { BaseTypography } from "@app/components/common/BaseTypography/BaseTypography";
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT, media } from "@app/styles/themes/constants";
import styled from "styled-components";


export const Title = styled(BaseTypography.Title)`
  &.ant-typography {
    margin-bottom: 0;
    margin-top: 15px;

    font-size: ${FONT_SIZE.xs};
  }
`;

export const Text = styled(BaseTypography.Text)`
  display: block;
  margin-top: 0;

  font-size: ${FONT_SIZE.xxs};

  color: var(--text-nft-light-color);

  font-family: ${FONT_FAMILY.secondary};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xs};
  }
`;



