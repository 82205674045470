import React , {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerForm } from '@app/components/forms/CustomerForm/CustomerForm';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { showCustomer } from '@app/api/customers.api';

interface RouteParams {
  id: string;
  [key: string]: string | undefined;
}

const ViewCustomerPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  return (
    <>
        <PageTitle>{"JRDC-Admin"}</PageTitle>

        <CustomerForm type={"view"} id={id} editable={false}  />
    
    </>
  );
};

export default ViewCustomerPage;
