import {
  AppointmentApiData,
  AppointmentRequest,
  AppointmentStatusOption,
  CustomerOption,
  CustomerStatusOption,
  OrderOption,
  Product,
  StaffOption,
  createAppointment,
  editAppointment,
  getCustomerDetails,
  showAppointment,
  storeAppointments,
  updateAppointment,
} from '@app/api/appointment.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { List, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppointmentFormFields } from './AppointmentFormFields';
import { MdModeEdit } from 'react-icons/md';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { TableOutlined } from '@ant-design/icons';
import { IoEye } from 'react-icons/io5';
import { DateFormat, calculateUnitPrice } from '@app/api/order.api';

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface AppointmentFormProps extends TypeOfForm {
  editable: boolean;
  id?: string;
  // showEditIcon?: boolean;
  // pageTitle: string;
}

export const AppointmentForm: React.FC<AppointmentFormProps> = ({ type, editable = false, id }) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const appointmentId = id ? parseInt(id) : null;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [orderOptions, setOrderOptions] = useState<OrderOption[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([]);
  const [customer, setCustomer] = useState({
    bill_email: '',
    phone: '',
    address_line1: '',
    address_line2: '',
    city: '',
    postcode: '',
    first_name: '',
    last_name: '',
    country: '',
    state: '',
    company: '',
  });
  const [customerStatusOptions, setCustomerStatusOptions] = useState<CustomerStatusOption[]>([]);
  const [appointmentStatusOptions, setAppointmentStatusOptions] = useState<AppointmentStatusOption[]>([]);
  const [staffOptions, setStaffOptions] = useState<StaffOption[]>([]);
  const [orderId, setOrderId] = useState<number | null>(0);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(true);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [disableField,setDisableField]= useState<boolean>(false)

  const handleErrorMessages = useApiErrorHandler();

  const handleEditIconClick = () => {
    navigate(`/edit-appointment/${id}`);
  };

  const handleViewIconClick = () => {
    navigate(`/view-appointment/${id}`);
  };

  const setApiData = (res: AppointmentApiData) => {
    console.log(res);
    setDisableField(res?.editable);
    setOrderId(res?.appointment?.order_id);
    setOrderOptions(type !== 'create' ? res?.order_options : [{ id: null }, ...res?.order_options]);
    setProducts(res?.product_options);
    setCustomerStatusOptions(res?.customer_status_options);
    setAppointmentStatusOptions(res?.appointment_status_options);
    setCustomerOptions(res?.customer_options);
    setStaffOptions(res?.staff_options);
    setCustomer({
      bill_email: res?.selected_customer?.bill_email ?? '',
      phone: res?.selected_customer?.phone ?? '',
      address_line1: res?.selected_customer?.address_line1 ?? '',
      address_line2: res?.selected_customer?.address_line2 ?? '',
      city: res?.selected_customer?.city ?? '',
      postcode: res?.selected_customer?.postcode ?? '',
      first_name: res?.selected_customer?.first_name ?? '',
      last_name: res?.selected_customer?.last_name ?? '',
      country: res?.selected_customer?.country ?? '',
      state: res?.selected_customer?.state ?? '',
      company: res?.selected_customer?.company ?? '',
    });

    const setValueByType = (view: any, other: any) => {
      if (type === 'view') {
        return view;
      } else {
        return other;
      }
    };

    form.setFieldsValue({
      order_id: setValueByType(`Order #${res?.appointment?.order_id}`, res?.appointment?.order_id ?? null),
      customer_id: setValueByType(
        `${res?.selected_customer?.first_name} ${res?.selected_customer?.last_name}`,
        res?.appointment?.customer_id,
      ),
      customer_status: res?.appointment?.customer_status ?? '1',
      status: res?.appointment?.status ?? '1',
      product_id: setValueByType(
        `${res?.appointment?.product_name} (#${res?.appointment?.product_id})`,
        res?.appointment?.product_id,
      ),
      staff: res?.appointment?.staff ?? '1',
      qty: res?.appointment?.qty,
      all_day: res?.appointment?.all_day ?? false,
      start_date_time: res?.appointment?.start_date_time ? dayjs(res?.appointment?.start_date_time) : null,
      end_date_time: res?.appointment?.end_date_time ? dayjs(res?.appointment?.end_date_time) : null,
      customer_timezone: res?.appointment?.customer_timezone ?? null,
    });
  };

  useEffect(() => {
    // Function to fetch data from the API
    setLoading(true);
    if (appointmentId) {
      if (type === 'view') {
        showAppointment(appointmentId)
          .then((res: any) => {
            console.log(res);
            setPageTitle(`${res?.appointment?.order_id}`);
            setApiData(res);
            setLoading(false);
          })
          .catch((error) => handleErrorMessages(error));
      } else if (type === 'edit') {
        editAppointment(appointmentId)
          .then((res: any) => {
            console.log('edit =>' + res);
            setLoading(false);
            setPageTitle(`${res?.appointment?.order_id}`);
            setApiData(res);
          })
          .catch((error) => handleErrorMessages(error));
      }
    } else {
      createAppointment()
        .then((res: any) => {
          setLoading(false);
          setPageTitle(`New`);
          setApiData(res);
        })
        .catch((error) => handleErrorMessages(error));
    }
  }, []);

  const handleOnSelectOrderId = async (value: any) => {
    console.log(value);
    const order = orderOptions.find((order) => order.id == value);

    form.setFieldsValue({
      customer_id: order?.customer_id,
    });
    if (order?.customer_id !== undefined) {
      await getCustomerDetails(order?.customer_id).then((response: any) => {
        console.log(response.data);
        setCustomer(response.data);
      });
    }
  };

  const handleOnSelectCustomerId = async (value: any) => {
    // const id = form.getFieldValue('customer_id');
    await getCustomerDetails(value).then((response: any) => {
      console.log(response.data);
      setCustomer(response.data);
    });
  };

  const navigateToOrder = () => {
    switch (type) {
      case 'edit': {
        navigate(`/edit-order/${orderId}`);
        break;
      }
      case 'view': {
        navigate(`/show-order/${orderId}`);
        break;
      }
    }
  };

  const handleSubmit = (values: AppointmentRequest) => {
    console.log('onSubmit => ' + values);
    console.log(values);

    values.start_date_time =
      values.start_date_time !== undefined || values.start_date_time !== null
        ? dayjs(values.start_date_time).format('YYYY-MM-DD HH:mm:ss')
        : dayjs().format('YYYY-MM-DD HH:mm:ss');
    values.end_date_time =
      values.end_date_time !== undefined || values.end_date_time !== null
        ? dayjs(values.end_date_time).format('YYYY-MM-DD HH:mm:ss')
        : dayjs().format('YYYY-MM-DD HH:mm:ss');
    values.qty = values.qty || 1;
    console.log(values);

    setLoading(true);

    if (editable) {
      if (type === 'create') {
        storeAppointments(values)
          .then((res: any) => {
            setLoading(false);
            console.log(res);
            navigate(`/view-appointment/${res?.data?.id}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      } else if (type === 'edit' && appointmentId) {
        updateAppointment(appointmentId, values)
          .then(() => {
            setLoading(false);
            navigate(`/view-appointment/${id}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      }
    }
  };

  const handleEditRow = (id: number) => {
    navigate(`/edit-appointment/${id}`);
  };

  return (
    <>
      <BaseRow gutter={[16, 16]}>
        <BaseCol xs={24} md={24} lg={16}>
          <BaseCard
            id="validation form"
            title={`Appointment - ` + pageTitle || 'New'}
            padding="1.25rem"
            style={{ margin: '20px', marginTop: '0px', width: '98%' }}
            extra={
              <>
                {(type === 'view' && (
                  <>
                    <Space>
                      <Tooltip title={t('common.edit')}>
                        <BaseButton type="text" onClick={() => handleEditIconClick()} icon={<MdModeEdit />} />
                      </Tooltip>
                    </Space>
                  </>
                )) ||
                  (type === 'edit' && (
                    <>
                      <Space>
                        <Tooltip title={t('view')}>
                          <BaseButton type="text" onClick={() => handleViewIconClick()} icon={<IoEye />} />
                        </Tooltip>
                      </Space>
                    </>
                  ))}
                <Space>
                  <Tooltip title={'View all'}>
                    <BaseButton type="text" onClick={() => navigate('/all-appointments')} icon={<TableOutlined />} />
                  </Tooltip>
                </Space>
              </>
            }
          >
            <BaseButtonsForm
              name="AppointmentsForm"
              onFinish={handleSubmit}
              form={form}
              loading={isLoading}
              isFieldsChanged={isFieldsChanged}
              setFieldsChanged={setFieldsChanged}
            >
              <p>A duration of 2 hours can be added on.</p>

              <h4>
                <b>General Details</b>
              </h4>
              <br />

              <BaseRow gutter={[16, 16]}>
                <BaseCol xs={24} md={8} lg={12}>
                  <BaseButtonsForm.Item name="order_id" label="Order ID">
                    <BaseSelect
                      placeholder="Select Order ID"
                      disabled={!disableField}
                      filterOption={true}
                      onSelect={(value) => {
                        handleOnSelectOrderId(value);
                      }}
                    >
                      {orderOptions?.map((option) => (
                        <>
                          <Option key={option.id} value={option.id}>
                            {option.id !== null
                              ? `#${option.id} (${dayjs(option.created_at).format('MMMM D, YYYY')})`
                              : 'Create new order'}
                          </Option>
                        </>
                      ))}
                    </BaseSelect>
                  </BaseButtonsForm.Item>
                </BaseCol>
                <BaseCol xs={24} md={8} lg={12}>
                  <BaseButtonsForm.Item
                    name="customer_id"
                    label="Customer"
                    rules={[{ required: true, message: 'Customer is required' }]}
                  >
                    <BaseSelect
                      placeholder="Select Customer"
                      disabled={ !disableField}
                      filterOption={true}
                      onSelect={handleOnSelectCustomerId}
                    >
                      {customerOptions?.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {`${option.first_name} ${option.last_name} (#${option.id})`}
                        </Option>
                      ))}
                    </BaseSelect>
                  </BaseButtonsForm.Item>
                </BaseCol>
                {type !== 'create' && (
                  <>
                    <BaseCol xs={24} md={8} lg={12}>
                      <BaseButtonsForm.Item>
                        <BaseButton type="link" htmlType="button" onClick={navigateToOrder}>
                          {type === 'edit' ? t('Click here to Update Order') : t('Click here to View Order')}
                        </BaseButton>
                      </BaseButtonsForm.Item>
                    </BaseCol>
                  </>
                )}
              </BaseRow>

              <br />
              {/* Includes all fields in the appointment migration. Please note this component is reused in the order form */}
              <AppointmentFormFields
                editable={disableField}
                products={products}
                customerStatusOptions={customerStatusOptions}
                appointmentStatusOptions={appointmentStatusOptions}
                staffOptions={staffOptions}
                inOrderForm={false}
                showAppointmentDetails={true}
                setShowAppointmentDetails={setShowAppointmentDetails} />
              <br />

              <br />
              <BaseButtonsForm.Item>
                <BaseButton type="primary" htmlType="submit" loading={isLoading} disabled={(!editable || !disableField)}>
                  {t('Save Appointment')}
                </BaseButton>
              </BaseButtonsForm.Item>
            </BaseButtonsForm>
          </BaseCard>
        </BaseCol>

        <BaseCol xs={24} lg={6}>
          <BaseCard
            title={t('Customer Details')}
            padding={'1.25rem'}
            style={{
              width: '100%',
              height: 'fit-content',
            }}
          >
            <List size={'small'}>
              <List.Item>
                <Space direction="vertical">
                  <BaseTypography.Text style={{ color: 'var(--primary-color)', fontSize: '14px' }}>
                    Name:
                  </BaseTypography.Text>
                  <BaseTypography.Text>{`${customer?.first_name} ${customer?.last_name}`}</BaseTypography.Text>
                </Space>
              </List.Item>
              <List.Item>
                <Space direction="vertical">
                  <BaseTypography.Text style={{ color: 'var(--primary-color)', fontSize: '14px' }}>
                    Address:
                  </BaseTypography.Text>
                  <BaseTypography.Text>{customer?.address_line1}</BaseTypography.Text>
                  <BaseTypography.Text>{customer?.address_line2}</BaseTypography.Text>
                  <BaseTypography.Text>{customer?.city}</BaseTypography.Text>
                  <BaseTypography.Text>{customer?.state}</BaseTypography.Text>
                  <BaseTypography.Text>{customer?.country}</BaseTypography.Text>
                </Space>
              </List.Item>
              <List.Item>
                <Space direction="vertical">
                  <BaseTypography.Text style={{ color: 'var(--primary-color)', fontSize: '14px' }}>
                    Postal Code:
                  </BaseTypography.Text>
                  <BaseTypography.Text>{customer?.postcode}</BaseTypography.Text>
                </Space>
              </List.Item>
              <List.Item>
                <Space direction="vertical">
                  <BaseTypography.Text style={{ color: 'var(--primary-color)', fontSize: '14px' }}>
                    Email:
                  </BaseTypography.Text>
                  <BaseTypography.Text>{customer?.bill_email}</BaseTypography.Text>
                </Space>
              </List.Item>
              <List.Item>
                <Space direction="vertical">
                  <BaseTypography.Text style={{ color: 'var(--primary-color)', fontSize: '14px' }}>
                    Phone:
                  </BaseTypography.Text>
                  <BaseTypography.Text>{customer?.phone}</BaseTypography.Text>
                </Space>
              </List.Item>
              <List.Item>
                <Space direction="vertical">
                  <BaseTypography.Text style={{ color: 'var(--primary-color)', fontSize: '14px' }}>
                    Company:
                  </BaseTypography.Text>
                  <BaseTypography.Text>{customer?.company}</BaseTypography.Text>
                </Space>
              </List.Item>
            </List>
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};
