import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import OrdersForm from '@app/components/forms/OrderForm/OrderForm';
import { useParams } from 'react-router-dom';

const OrderCreatePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  return (
    <>
      {/* <PageTitle>{t('Create Order')}</PageTitle> */}
      <PageTitle>{"JRDC-Admin"}</PageTitle>
      <OrdersForm type={'create'} id={id} editable={true} />
    </>
  );
};

export default OrderCreatePage;
