import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Space, Button, Input, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTableRow, Pagination, getPageTableData } from '@app/api/page.api';
import { useMounted } from '@app/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { Tooltip } from 'antd';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const PageTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: PageTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const handleErrorMessages = useApiErrorHandler();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const [postCategories, setPostCategories] = useState<any[]>([])

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getPageTableData({...pagination, search: form.getFieldValue('search'),category: form.getFieldValue('category')})
        .then((res) => {
          setPostCategories(res.post_categories.map(e => ({value:e.id,label:e.name})));
          if (isMounted.current) {
            setTableData({ data: res.pages.data, pagination: res.pages.pagination, loading: false });
          }
        })
        .catch((error) => {
          handleErrorMessages(error);
        });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.id !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const showTotal = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span
        style={{ fontSize: '14px', marginRight: '20px', color: '#adadad' }}
      >{`Showing ${range[0]} - ${range[1]} of ${total} Pages`}</span>
    );
  };

  const navigate = useNavigate();

  const handleEditRow = (id: number) => {
    console.log(id);
    navigate(`/edit-page/${id}`);
  };

  const handleViewRow = (id: number) => {
    navigate(`/view-page/${id}`);
  };

  const columns: ColumnsType<PageTableRow> = [
    {
      title: 'Page Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Button
              type="ghost"
              onClick={() => handleViewRow(record.id)}
              icon={<IoEye />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Tooltip title="Edit">
            <Button
              type="ghost"
              onClick={() => handleEditRow(record.id)}
              icon={<MdModeEdit />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Button
              type="default"
              danger
              onClick={() => handleDeleteRow(record.id)}
              icon={<MdDelete />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <BaseCard
    id="basic-table"
    title={'Pages'}
    padding="1.25rem"
    extra={  <BaseButtonsForm
      form={form}
      layout="inline"
      isFieldsChanged={fieldsChanged}
      setFieldsChanged={setFieldsChanged}
    >
      <BaseButtonsForm.Item name={'category'}>
        <Select size='middle'
          placeholder={'Select Post Category'}
          options={postCategories}
          onChange={()=>{fetch(initialPagination)}}
        />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item name={'search'}>
        <Input
          size='middle'
          suffix={<Button icon={<SearchOutlined  />} size='middle' type='text' onClick={()=>{fetch(initialPagination)}} />}
          placeholder={t('Search Pages')}
        />
      </BaseButtonsForm.Item>

      
      <Button type='ghost' size='middle' icon={<ReloadOutlined />} 
      onClick={()=>{
        form.resetFields(['category','search']);
        fetch(initialPagination);
        }}/>
    </BaseButtonsForm>}
  >
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        current: tableData.pagination.current,
        pageSize: tableData.pagination.pageSize,
        total: tableData.pagination.total,
        showTotal,
      }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: true, scrollToFirstRowOnChange: true }}
    /></BaseCard>
  );
};
