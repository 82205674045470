import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
//import { persistUser, readUser } from '@app/services/localStorage.service';
import{ authUserService } from '@app/services/authUser.service';

export interface UserState {
  user: UserModel | null;
}

const initialState: UserState = {
  user: authUserService.getUser(),
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  (newUser);
  authUserService.setUser(newUser);
  return {
    payload: newUser,
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
