import React from 'react';
import { Button, Space, Tooltip } from 'antd';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

interface StockTableRow {
  id: number;
  product: string;
  parent: string;
  unitInStock: number;
  stockStatus: string;
}

interface StockTableProps {
  searchText: string;
}

const StockTable: React.FC<StockTableProps> = ({ searchText }) => {
  const navigate = useNavigate();

  // Default data
  const defaultData: StockTableRow[] = [
    { id: 1, product: 'Product 1', parent: 'Parent 1', unitInStock: 10, stockStatus: 'In Stock' },
    { id: 2, product: 'Product 2', parent: 'Parent 2', unitInStock: 5, stockStatus: 'Out of Stock' },
    { id: 3, product: 'Product 3', parent: 'Parent 3', unitInStock: 15, stockStatus: 'In Stock' },
  ];

  const handleDeleteRow = (id: number) => {
    // Implement deletion logic here
    console.log('Deleting stock item with ID:', id);
  };

  const handleEditRow = (id: number) => {
    // Implement edit navigation logic here
    navigate(`/edit-stock/${id}`);
  };

  const handleViewRow = (id: number) => {
    // Implement view navigation logic here
    navigate(`/view-stock/${id}`);
  };

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Parent',
      dataIndex: 'parent',
      key: 'parent',
    },
    {
      title: 'Unit in Stock',
      dataIndex: 'unitInStock',
      key: 'unitInStock',
    },
    {
      title: 'Stock Status',
      dataIndex: 'stockStatus',
      key: 'stockStatus',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: StockTableRow) => (
        <Space>
          <Tooltip title="View">
            <Button
              type="ghost"
              onClick={() => handleViewRow(record.id)}
              icon={<IoEye />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="ghost"
              onClick={() => handleEditRow(record.id)}
              icon={<MdModeEdit />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="default"
              danger
              onClick={() => handleDeleteRow(record.id)}
              icon={<MdDelete />}
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <BaseCard id="basic-table" title="Stock" padding="1.25rem">
      <BaseTable columns={columns} dataSource={defaultData} scroll={{ x: true, scrollToFirstRowOnChange: true }} />
    </BaseCard>
  );
};

export default StockTable;
