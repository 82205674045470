import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import moment from 'moment';
import { ReceiptStatusOption } from '@app/api/order.api';
import { Select } from 'antd';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

interface UploadReceiptSectionProps {
  editable: boolean;
  setLastStatusChange: (value: any) => any;
  setReceiptStatuschange: (value: any) => any;
  setSelectedReceiptImage: (value: any) => any;
  imageUrl:string;
  selectedReceiptImage:string|null;
  receiptStatuschange: string | null;
  receiptStatuses: ReceiptStatusOption[];
  lastStatusChange: moment.Moment | null;
}
export const UploadReceiptSection: React.FC<UploadReceiptSectionProps> = ({
  editable = false,
  setLastStatusChange,
  setReceiptStatuschange,
  setSelectedReceiptImage,
  imageUrl,
  selectedReceiptImage,
  receiptStatuschange,
  receiptStatuses,
  lastStatusChange
}) => {

  const getPopupContainer = (triggerNode: HTMLElement | null) => {
    return triggerNode || document.body;
  };

  const handleReceiptStatusChange = (value: string) => {
    if (value) {
      const currentDate = moment();
      setLastStatusChange(currentDate);
      setReceiptStatuschange(value);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedReceiptImage(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <BaseButtonsForm.Item name="image" label={editable ? "Click to Change/Upload": "Last uploaded"}>
        <input type="file" accept="image/*" onChange={handleFileChange} hidden={!editable} />
        <br hidden={!editable}/>
        {selectedReceiptImage && <img src={selectedReceiptImage} alt="thumbnail" style={{ width: '100px', height: '100px' }} />}

        <BaseImage src={imageUrl} preview={true} style={{ width: '100px', height: '100px' }} hidden={!!selectedReceiptImage}>
          {/* {imageUrl && <img src={imageUrl} alt="thumbnail" style={{ width: '100px', height: '100px' }} />} */}
        </BaseImage>
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item label="Receipt Approval Status" name="receipt_status">
        <BaseSelect
          getPopupContainer={getPopupContainer}
          onChange={handleReceiptStatusChange as any}
          value={receiptStatuschange}
          disabled={!editable}
        >
          <Select.Option value={'N/A'} key={'N/A'}>
              {'N/A'}
            </Select.Option>
          {receiptStatuses.map((status) => (
            <Select.Option value={status.key} key={status.key}>
              {status.name}
            </Select.Option>
          ))}
        </BaseSelect>
      </BaseButtonsForm.Item>

      <BaseButtonsForm.Item label="Last Receipt Approval Status Change Date">
        <BaseTypography.Text strong>
          {lastStatusChange ? moment(lastStatusChange).format('YYYY-MM-DD') : ''}
        </BaseTypography.Text>
      </BaseButtonsForm.Item>

      <BaseButtonsForm.Item label="Admin Note" name="note">
        <TextArea placeholder="Enter admin note" disabled={!editable} />
      </BaseButtonsForm.Item>
    </>
  );
};
