import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { Row, Col, Card, Statistic } from 'antd';
import { ReportDetails, SummaryStatistic } from '@app/api/report.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

interface OrderChartProps {
  period: 'years' | 'year' | 'lastMonths' | 'thisMonth' | 'last7Days' | 'custom';
  customDateRange?: [moment.Moment, moment.Moment];
  xAxisData: string[];
  orderPlacedData: number[];
  itemPurchaseData: number[];
  grossSaleData: number[];
  avgGrossSaleData: number[];
  netSalesData: number[];
  avgNetSalesData: number[];
  totalOrderPlaced: any;
  totalItemsPurchased: any;
  totalGrossSale: any;
  totalNetSales: any;
}

const OrderChart: React.FC<OrderChartProps> = ({
  period,
  customDateRange,
  xAxisData,
  orderPlacedData,
  itemPurchaseData,
  grossSaleData,
  avgGrossSaleData,
  netSalesData,
  avgNetSalesData,
  totalOrderPlaced,
  totalItemsPurchased,
  totalGrossSale,
  totalNetSales,
}) => {
  return (
    <div className="order-chart">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="left-panel" style={{ gridColumn: 'span 6' }}>
            <Row gutter={[16, 16]}>
              <Col key={1} span={6}>
                <Card><Statistic title="Gross Sales (LKR)" value={totalGrossSale} precision={2} /></Card>
              </Col>
              <Col key={2} span={6}>
              <Card><Statistic title="Net Sales (LKR)" value={totalNetSales} precision={2} /></Card>
              </Col>
              <Col key={3} span={6}>
              <Card><Statistic title="Orders Placed" value={totalOrderPlaced}  /></Card>
              </Col>
              <Col key={4} span={6}>
              <Card><Statistic title="Items Purchased" value={totalItemsPurchased}/></Card>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={24}>
          <div className="right-panel" style={{ gridColumn: 'span 18' }}>
            <ReactECharts
              option={{
                xAxis: {
                  type: 'category',
                  data: xAxisData || [],
                },
                yAxis: [
                  {
                    type: 'value',
                    name: 'Quantity',
                    position: 'left',
                  },
                  {
                    type: 'value',
                    name: 'Currency (LKR)',
                    position: 'right',
                    axisLabel: {
                      formatter: (value: number) => `${value}`,
                    },
                  },
                ],

                legend: {
                  data: [
                    { name: 'Order Placed', icon: 'circle', textStyle: { color: '#1890ff' } },
                    { name: 'Item Purchase', icon: 'circle', textStyle: { color: '#2fc25b' } },
                    { name: 'Gross Sale (LKR)', icon: 'circle', textStyle: { color: '#9890ff' } },
                    { name: 'Average Gross Sales (LKR)', icon: 'circle', textStyle: { color: '#2fc25b' } },
                    { name: 'Net Sales (LKR)', icon: 'circle', textStyle: { color: '#fadb14' } },
                    { name: 'Average Net Sales (LKR)', icon: 'circle', textStyle: { color: '#722ed1' } },
                  ],
                  top: 10,
                  formatter: (name: string) => {
                    const color =
                      name === 'Order Placed'
                        ? '#1890ff'
                        : name === 'Item Purchase'
                        ? '#2fc25b'
                        : name === 'Gross Sale (LKR)'
                        ? '#1890ff'
                        : name === 'Average Gross Sales (LKR)'
                        ? '#2fc25b'
                        : name === 'Net Sales (LKR)'
                        ? '#fadb14'
                        : name === 'Average Net Sales (LKR)'
                        ? '#722ed1'
                        : '';
                    return `${name}`;
                  },
                },
                tooltip: {
                  trigger: 'axis',
                },
                series: [
                  {
                    name: 'Order Placed',
                    data: orderPlacedData || [],
                    type: 'line',
                    yAxisIndex: 0,
                    itemStyle: {
                      color: '#1880ff',
                    },
                  },
                  {
                    name: 'Item Purchase',
                    data: itemPurchaseData || [],
                    type: 'line',
                    yAxisIndex: 0,
                    itemStyle: {
                      color: '#5860ff',
                    },
                  },
                  {
                    name: 'Gross Sale (LKR)',
                    data: grossSaleData || [],
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                      color: '#9890ff',
                    },
                  },
                  {
                    name: 'Average Gross Sales (LKR)',
                    data: avgGrossSaleData || [],
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                      color: '#2fc25b',
                    },
                  },
                  {
                    name: 'Net Sales (LKR)',
                    data: netSalesData || [],
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                      color: '#fadb14',
                    },
                  },
                  {
                    name: 'Average Net Sales (LKR)',
                    data: avgNetSalesData || [],
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                      color: '#722ed1',
                    },
                  },
                ],
              }}
              style={{ height: '400px', width: '100%' }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderChart;
