import { get, post } from '@app/api/http.api';

export interface CategoryRequest {
  name: string;
  slug: string;
  parent_id: null | number;
  description: null | string;
  image: null | string;
}

export interface Category {
  id: number;
  name: string;
  slug: string;
  parent_id: null | number;
  description: null | string;
  image: null | string;
  created_at: string;
  updated_at: string;
  parent_category_chain: null | string;
  category_chain: null | string;
  status: Status;
  display_type: '1' | '2' | '3';
  image_url: string;
}

export interface CategoryTableRow {
  id: number;
  name: string;
  slug: string;
  description: null | string;
  parent_category_chain: null | string;
  status:null|string;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface CategoryTableData {
  categories:{data: CategoryTableRow[];
    pagination: Pagination;}
   status_options: StatusOption[];
  
}

export interface PaginatedResponse<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

interface Status {
  ACTIVE: string;
  INACTIVE: string;
}

export interface DisplayTypeOption {
  key?: '1' | '2' | '3';
  name?: string;
}

export interface StatusOption {
  key?: 'ACTIVE' | 'INACTIVE';
  name?: string;
}

export interface CategoryApiData {
  category_options: CategoryOption[];
  category: Category;
  status_options: StatusOption[];
  display_type_options: DisplayTypeOption[];
}

export interface CategoryOption {
  id: number;
  category_chain: string;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
  status?:string | null;
}

interface CategoryTableDataApi{
   categories:PaginatedResponse<CategoryTableRow>;
   status_options: StatusOption[];
}

export const getCategoryTableData = async (parameters:Parameters): Promise<CategoryTableData> => {
  try {
    const { data, status } = await get<CategoryTableDataApi>(
      'product_categories',
      { params: parameters },
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);

    const result = data?.categories.data.map((rawData: CategoryTableRow) => ({
      id: rawData.id,
      name: rawData.name,
      slug: rawData.slug,
      description:
        <number>rawData?.description?.length > 53
          ? `${rawData?.description?.substring(0, 50)}...`
          : rawData.description,
      parent_category_chain: rawData.parent_category_chain,
      status:rawData.status
    }));

    return {
      categories:{
        data: result,
        pagination: {
          current: data.categories.current_page,
          pageSize: data.categories.per_page,
          total: data.categories.total,
        },
      },
      status_options:data.status_options
    };
  } catch (error: any) {
    throw error;
  }
};

export const getCategories = async () => {
  try {
    const { data, status } = await get<Category>('product_categories', undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const storeCategory = async (StoreCategoryData: CategoryRequest) => {
  try {
    const { data, status } = await post<CategoryRequest>(
      'product_categories/create',
      StoreCategoryData,
      undefined,
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const updateCategory = async (id: number, UpdateCategoryData: CategoryRequest) => {
  try {
    const { data, status } = await post<CategoryRequest>(
      `product_categories/${id}/edit`,
      UpdateCategoryData,
      undefined,
      true,
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createCategory = async () => {
  try {
    const { data, status } = await get<CategoryApiData>(`product_categories/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const showCategory = async (id: number) => {
  try {
    const { data, status } = await get<CategoryApiData>(`product_categories/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const editCategory = async (id: number) => {
  try {
    const { data, status } = await get<CategoryApiData>(`product_categories/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error) {
    throw error;
  }
};
