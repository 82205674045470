
interface Settings {
  [resource: string]: boolean;
}
// Token management service
class SettingService {
  private static instance: SettingService;
  private settings: string | null;

  private constructor() {
    // Retrieve the token from local storage during initialization
    this.settings = localStorage.getItem('settings');
  }

  public static getInstance(): SettingService {
    if (!this.instance) {
      this.instance = new SettingService();
    }
    return this.instance;
  }

  public setSettings(settings: Settings): void {
    const serialized_settings =  JSON.stringify(settings?settings:'');
    localStorage.setItem('settings', serialized_settings);
    this.settings = serialized_settings;
  }

  public getSettings(): Settings | null {
    return this.settings ? JSON.parse(this.settings) : null;
  }

  public deleteSettings(): void {
    localStorage.removeItem('settings');
    this.settings = null;
  } 
}


// Export the TokenService instance
export const settingService = SettingService.getInstance();