import { get, post, remove } from '@app/api/http.api';

export interface FileDetails {
    file: File;
    path:string;
}

export interface FileInfo{
    id: number | null;
    url: string;
    name:string;
    key: string;
    extension: string;
    action: '1'|'2'|'3'
}

export interface FileApiData{
    file:FileInfo
}

export interface RemoveFileApiData{
  message: string;
}


// export const uploadFile = async (fileData:any) => {
//   try {
//     const { data, status } = await post<FileApiData>(`assets/upload`, fileData,undefined,true);
//     console.log(`Status: ${status}`, `Data: ${data}`);
//     return data;
//   } catch (error: any) {
//     console.error(`Error: ${error.status} - ${error.message}`);
//     throw error;
//   }
// };

export const uploadFile = async (fileData:any) => {
    try {
      const { data, status } = await post<FileApiData>(`assets/upload`, fileData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        }},true);
      console.log(`Status: ${status}`, `Data: ${data}`);
      return data;
    } catch (error: any) {
      console.error(`Error: ${error.status} - ${error.message}`);
      throw error;
    }
};

export const removeFile = async (fileData:any) => {
  try {
    const { data, status } = await post<RemoveFileApiData>(`assets/remove`, fileData,undefined,true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
