import { get, post } from '@app/api/http.api';

export interface UserRequest {
  email_verified_at?: boolean;
  id: number;
  name: string;
  email: string;
  password: string;
  role: number;
  change_password: boolean;
  image: string | null;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PaginatedResponse<T> {
  users: {
    data: T[];
    current_page: number;
    per_page: number;
    total: number;
  };
}

export interface User {
  password: any;
  id: number;
  name: string;
  username: string;
  email: string;
  roles: Role[];
  posts: number;
  status: 'ACTIVE' | 'INACTIVE';
  membershipLevel: string;
  image_url: string | null;
}

export interface Role {
  id: number | null;
}

export interface RoleOption {
  id: number | null;
  role_name: string;
}
export interface StatusOption {
  key: number | null;
  name: string;
}

export interface UserApiData {
  user: User;
  role_options: any[];
  status_options: any[];
}

export interface UserTableRow {
  id: number;
  username: string;
  email: string;
  role: string;
}

export interface UsersTableData {
  data: UserTableRow[];
  pagination: Pagination;
}

interface Parameters{
  current?: number;
  pageSize?: number;
  total?: number;
  search?:string | null;
}

export const getUsersTableData = async (parameters:Parameters): Promise<UsersTableData> => {
  try {
    const { data, status } = await get<PaginatedResponse<UserTableRow>>('users', { params: parameters }, true);
    console.log(`Status: ${status}`, `Data: ${data}`);

    const usersData = data.users;

    const result: UserTableRow[] = usersData.data.map((userData: UserTableRow) => ({
      id: userData.id,
      username: userData.username,
      email: userData.email,
      role: userData.role,
    }));

    return {
      data: result,
      pagination: {
        current: usersData.current_page,
        pageSize: usersData.per_page,
        total: usersData.total,
      },
    };
  } catch (error: any) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const getUsers = async (): Promise<User[]> => {
  try {
    const { data, status } = await get<User[]>(`users`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const storeUser = async (storeUserData: UserRequest) => {
  try {
    const { data, status } = await post<UserRequest>(`users/create`, storeUserData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.message}`);
    throw error;
  }
};

export const updateUser = async (id: number, updateUserData: UserRequest) => {
  try {
    const { data, status } = await post<UserRequest>(`users/${id}/edit`, updateUserData, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.message}`);
    throw error;
  }
};

export const editUser = async (id: number) => {
  try {
    const { data, status } = await get<UserApiData>(`users/${id}/edit`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const showUser = async (id: number) => {
  try {
    const { data, status } = await get<UserApiData>(`users/${id}`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};

export const createUser = async () => {
  try {
    const { data, status } = await get<UserApiData>(`users/create`, undefined, true);
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error: any) {
    console.error(`Error: ${error.status} - ${error.message}`);
    // throw new Error(`Error: ${error.status} - ${error.message}`);
    throw error;
  }
};
