import {
  Pagination,
  Permission,
  PermissionTableRow,
  RoleApiData,
  RoleRequest,
  createRole,
  editRole,
  showRole,
  storeRoles,
  updateRole,
} from '@app/api/role.api';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { notificationController } from '@app/controllers/notificationController';
import { Tooltip, Button, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface RoleFormProps extends TypeOfForm {
  editable: boolean;
  id?: string;
  showEditIcon?: boolean;
  pageTitle: string;
}

const pagination: Pagination = {
  pageSize: 20,
};

export const RolePermissionsForm: React.FC<RoleFormProps> = ({ editable = false, id, pageTitle, type }) => {
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>([]);
  const { t } = useTranslation();
  const roleId = id ? parseInt(id) : null;
  const navigate = useNavigate();
  const handleErrorMessages = useApiErrorHandler(); 


  const setApiData = (res: RoleApiData) => {
    setPermissions(res?.permissions);
    setSelectedPermissions(res?.role_permissions);
    form.setFieldsValue({
      role_name: res?.role?.role_name,
      description: res?.role?.description,
      permissions: res?.role_permissions.map((e) => e.id),
    });
  };

  useEffect(() => {
    setLoading(true);
    if (roleId) {
      if (type === 'view') {
        showRole(roleId).then((res: any) => {
          setApiData(res);
          setLoading(false);
        }).catch((err: any) => {
          setLoading(false);
          handleErrorMessages(err);
          console.error(err);
        });;
      } else if (type === 'edit') {
        editRole(roleId).then((res: any) => {
          console.log(res);
          setApiData(res);
          setLoading(false);
        }).catch((err: any) => {
          setLoading(false);
          handleErrorMessages(err);
          console.error(err);
        });;
      }
    } else {
      createRole().then((res: any) => {
        setApiData(res);
        setLoading(false);
      }).catch((err: any) => {
        setLoading(false);
        handleErrorMessages(err);
        console.error(err);
      });;
    }
  }, []);

  const columns: ColumnsType<PermissionTableRow> = [
    {
      title: 'Permission ID',
      dataIndex: 'permission_id',
      width: '30%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Actions',
      width: '20%',
      render: (_, record) => (
        <Button
          type="text"
          danger
          icon={<MdDelete />}
          onClick={() => {
            const updatedValues = selectedPermissions.filter((e) => e.id !== record.id);
            form.setFieldValue(
              'permissions',
              updatedValues.map((e) => e.id),
            );
            setSelectedPermissions(updatedValues);
          }}
        />
      ),
    },
  ];

  const handleSubmit = (values: RoleRequest) => {
    if (editable) {
      if (type === 'create') {
        storeRoles(values)
          .then((res: any) => {
            setLoading(false);
            console.log(res);
            navigate(`/view-role-permissions/${res?.data?.id}`);
          })
          .catch((err: any) => {
            notificationController.error({ message: err.message });
            setLoading(false);
            console.error(err);
          });
      } else if (type === 'edit' && roleId) {
        updateRole(roleId, values)
          .then(() => {
            setLoading(false);
            navigate(`/view-role-permissions/${id}`);
          })
          .catch((err: any) => {
            notificationController.error({ message: `${err.message}` });
            setLoading(false);
            console.error(err);
          });
      }
    }
  };

  const handleEditRow = (id: number) => {
    navigate(`/edit-role-permissions/${id}`);
  };

  return (
    <BaseCard
      id="validation form"
      title={pageTitle}
      padding="1.25rem"
      style={{ margin: '20px', marginTop: '0px', width: '98%' }}
      extra={
        type === 'view' && (
          <>
            <Tooltip title="Edit">
              <Button
                type="ghost"
                onClick={() => {
                  if (roleId !== null) return handleEditRow(roleId);
                }}
                icon={<MdModeEdit />}
              />
            </Tooltip>
          </>
        )
      }
    >
      <BaseButtonsForm
        name="rolePermissionsForm"
        onFinish={handleSubmit}
        form={form}
        loading={isLoading}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item
              name="role_name"
              label="Name"
              key="role_name"
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <BaseInput placeholder="Enter Role Name" disabled={!editable} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 16]}>
          <BaseCol xs={24} md={8} lg={12}>
            <BaseButtonsForm.Item name="description" label="Description" key="description">
              <BaseInput.TextArea rows={4} placeholder="Enter Role Description" disabled={!editable} />
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 16]}>
          {type !== 'view' && (
            <BaseCol xs={24} md={24} lg={24}>
              <BaseButtonsForm.Item name="permissions" label="Permissions" rules={[{ required: true, message: 'Permissions are required' }]}>
                <BaseSelect
                  placeholder="Select Permissions"
                  disabled={!editable}
                  allowClear
                  mode="multiple"
                  onChange={(option: any) => {
                    const values = permissions.filter((e) => option?.includes(e.id));
                    setSelectedPermissions([...values]);
                  }}
                >
                  {permissions?.map((option) => (
                    <>
                      <Select.Option key={option.id} value={option.id}>
                        {option.permission_id}
                      </Select.Option>
                    </>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          )}
        </BaseRow>
        {type !== 'view' && (
          <>
            <BaseButton
              type="ghost"
              size="small"
              htmlType="button"
              loading={isLoading}
              disabled={!editable}
              onClick={() => {
                form.setFieldValue(
                  'permissions',
                  permissions.map((e) => e.id),
                );
                setSelectedPermissions(permissions);
              }}
            >
              select all
            </BaseButton>
          </>
        )}

        <br />
        <br />
        <BaseRow gutter={[16, 16]}>
          <BaseCol span={24}>
            <BaseTable
              size="small"
              bordered
              columns={type === 'view' ? columns.slice(0, 2) : columns}
              dataSource={selectedPermissions}
              pagination={pagination}
              scroll={{ x: true, scrollToFirstRowOnChange: true }}
            />
          </BaseCol>
        </BaseRow>
        {/* <br />
        <br />
        <BaseRow gutter={[16, 16]}>
          <BaseCol span={24}>
            <BaseTable
              bordered
              columns={columns.slice(0,2)}
              rowSelection={rowSelection}
              dataSource={permissions}
              pagination={false}
              scroll={{ x: true, scrollToFirstRowOnChange: true }}
            />
          </BaseCol>
        </BaseRow> */}
        <br />
        <br />
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading} disabled={!editable}>
            {t('Save Role')}
          </BaseButton>
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </BaseCard>
  );
};
