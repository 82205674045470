import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { EyeOutlined, EyeInvisibleOutlined, TableOutlined, UserOutlined } from '@ant-design/icons';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { UserRequest, storeUser, editUser, showUser, UserApiData, createUser, updateUser, RoleOption,StatusOption } from '@app/api/user.api';
import { useNavigate } from 'react-router-dom';
import { Avatar, Space, Tooltip } from 'antd';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import * as S from './ProfileInfo.styles';
import { ProfileImageItem } from '@app/components/forms/UserForm/ProfileImageItem/ProfileImageItem';
import { setUser } from '@app/store/slices/userSlice';

import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import useApiErrorHandler from '@app/components/Error/ErrorMsgHandling';

interface TypeOfForm {
  type: 'edit' | 'create' | 'view';
}

interface UserFormProps extends TypeOfForm {
  id?: string;
  editable: boolean;
  isMyProfile?: boolean;
}

export const UserForm: React.FC<UserFormProps> = ({
  type,
  id,
  editable = false,
  isMyProfile = false

}) => {
  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const userId = id ? parseInt(id) : null;
  const [changePassword, setChangePassword] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const handleErrorMessages = useApiErrorHandler(); 

  const [roleOptions,setRoleOptions] = useState<RoleOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<StatusOption[]>([]);

  const [pageTitle, setPageTitle] = useState<string>('');

  const setApiData = (response: UserApiData) => {
    console.log(response);
    setRoleOptions(response?.role_options);
    setStatusOptions(response?.status_options);
    setImageUrl(response?.user?.image_url);
    form.setFieldsValue({
      username: response?.user?.username,
      name: response?.user?.name,
      email: response?.user?.email,
      password: response?.user?.password ?? null,
      role: response?.user?.roles[0]?.id,
      status: response?.user?.status,
      change_password: changePassword
    });
  };

  useEffect(() => {
    setLoading(true)
    if (userId) {
      if (type === 'view') {
        showUser(userId)
          .then((res: any) => {
            console.log('user =>' + res);
            setApiData(res);
            setPageTitle(`${res?.user?.name}`);
            setLoading(false);
            // setUserName(res?.user?.name || '');
          })
          .catch((error) => handleErrorMessages(error));
      } else if (type === 'edit') {
        editUser(userId)
          .then((res: any) => {
            console.log('edit =>' + res);
            setApiData(res);
            setLoading(false);
            // setUserName(res?.user?.name || '');
            setPageTitle(`${res?.user?.name}`);
          })
          .catch((error) => handleErrorMessages(error));
      }
    } else {
      createUser()
        .then((res: any) => {
          setLoading(false);
          setApiData(res);
          setPageTitle(`New`);
        })
        .catch((error) => handleErrorMessages(error));
    }
  }, []);

  const handleSubmit = (values: UserRequest) => {
    values.image=image;
    console.log(values);
    setLoading(true);

    if (editable) {
      if (type === 'create') {
        storeUser(values)
          .then((res: any) => {
            setLoading(false);
            console.log(res);
            navigate(`/view-user/${res?.data?.id}`);
          })
          .catch((err: any) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      } else if (type === 'edit' && userId) {
        console.log(userId, values);
        updateUser(userId, values)
          .then((res: any) => {
            if(user && userId == user.id){
              const newUser = { ...user, 'name': res.user.name ,image_url:res.user.image_url };
              dispatch(setUser(newUser));
            }
            setLoading(false);
            navigate(`/view-user/${id}`);
          })
          .catch((err: Error) => {
            setLoading(false);
            handleErrorMessages(err);
            console.error(err);
          });
      }
    }
  };

  const handlePasswordChange = (e: any) => {
    setChangePassword(e.target.checked);
  };

  return (
    <>
      <BaseCard
        id="userForm" 
        title={(isMyProfile?`My Profile - `:`User - `) + pageTitle || 'New' }
        padding="1.25rem" 
        style={{ margin: '20px', marginTop: '0px' }}
        extra={
          <>
         { 
            type === 'view' && (
              <>
                <Space>
                  <Tooltip title={t('common.edit')}>
                    <BaseButton type="text" onClick={() => navigate(isMyProfile?`/edit-profile`:`/edit-user/${userId}`)} icon={<MdModeEdit />} />
                  </Tooltip>
                </Space>
              </>
            )
          ||
            type === 'edit' && (
              <>
                <Space>
                  <Tooltip title={t('view')}>
                    <BaseButton type="text" onClick={() => navigate(isMyProfile?`/profile`:`/view-user/${userId}`)} icon={<IoEye />} />
                  </Tooltip>
                </Space>
              </>
            )
          }
          <Space hidden={isMyProfile}>
            <Tooltip title={'View all'}>
              <BaseButton type="text" onClick={() => navigate('/all-users')} icon={<TableOutlined />} />
            </Tooltip>
          </Space>
        </> 
          
        }
      >
    {/* <S.Wrapper>
      <S.ImgWrapper> */}
      <BaseRow justify="center">
      <BaseCol>
      {imageUrl ? 
      <BaseAvatar shape="circle"  style={{width:'150px',height:'150px'}} src={imageUrl} alt="Profile" />
      : <Avatar shape='circle' size={150} icon={<UserOutlined />}/>}
      
      </BaseCol>
      </BaseRow>
        <br/>
      {/* </S.ImgWrapper>
    </S.Wrapper> */}
        <BaseButtonsForm
          name="userForm"
          form={form}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          loading={isLoading}
          onFinish={handleSubmit}
        >
          {/* Form Fields */}
          <BaseRow gutter={[16, 16]}>
            <BaseCol xl={8} lg={24} md={24} sm={24} xs={24}>
              <BaseButtonsForm.Item
                name="name"
                label={t('Name')}
                rules={[{ required: true, message: 'Username is required' }]}
              >
                <BaseInput disabled={!editable} />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xl={8} lg={12} md={12} sm={24} xs={24}>
              <BaseButtonsForm.Item
                name="username"
                label={t('Username')}
                rules={[{ required: true, message: 'Username is required' }]}
              >
                <BaseInput disabled={type != 'create'} />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xl={8} lg={12} md={12} sm={24} xs={24}>
              {/* Email */}
              <BaseButtonsForm.Item
                name="email"
                label={t('Email')}
                rules={[{ required: true, message: 'Email is required' }]}
              >
                <BaseInput disabled={!editable} type="email" />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          {type === 'edit' && (
            <>
              <BaseRow gutter={[16, 16]}>
                <BaseCol span={24}>
                  <BaseButtonsForm.Item name='change_password'>
                    <BaseCheckbox checked={changePassword} onChange={handlePasswordChange} disabled={!editable}>
                      Change Password
                    </BaseCheckbox>
                  </BaseButtonsForm.Item>
                </BaseCol>
              </BaseRow>
            </>
          )}

            {(type === 'create' || (type === 'edit' && changePassword)) && (
              <>
                <BaseRow gutter={[16, 16]}>
                  {/* Password field start */}
                  <BaseCol xl={8} lg={12} md={12} sm={24} xs={24}>
                    {/* Password */}
                    <BaseButtonsForm.Item
                      name="newPassword"
                      label="New Password"
                      // {t('Password')}
                      rules={[
                        { required: true, message: 'New Password is required' },
                        { min: 8, message: 'New Password must have at least 8 characters' },
                      ]}
                    >
                      <BaseInput.Password
                        disabled={!editable}
                        iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                        // style={{ marginBottom: '16px' }}
                      />
                    </BaseButtonsForm.Item>
                  </BaseCol>

                  <BaseCol xl={8} lg={12} md={12} sm={24} xs={24}>
                    <BaseButtonsForm.Item
                      name="password"
                      label="Confirm Password"
                      // {t('Password')}
                      dependencies={['newPassword']}
                      rules={[
                        { required: true, message: 'Please confirm your password' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords do not match'));
                          },
                        }),
                      ]}
                    >
                      <BaseInput.Password
                        disabled={!editable}
                        iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                        // style={{ marginBottom: '16px' }}
                      />
                    </BaseButtonsForm.Item>
                  </BaseCol>
                </BaseRow>
              </>
            )}
            
            {/* Password Field end */}
          

          <BaseRow gutter={[16, 16]}>
            <BaseCol xl={8} lg={12} md={12} sm={24} xs={24} >
              <BaseButtonsForm.Item
                name="role"
                label={t('Role')}
              >
                <BaseSelect placeholder={t('Select role')} disabled={!editable}>
                  {roleOptions.map(option => <Option key={option.id} value={option.id}>{option.role_name}</Option>)}
                  </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xl={8} lg={12} md={12} sm={24} xs={24} >
              <BaseButtonsForm.Item name="status" label="Status">
                <BaseSelect placeholder="Select status" disabled={!editable}>
                {Array.isArray(statusOptions) && statusOptions.map((option) => (
                    <Option key={option.name} value={option.key}>
                      {option.name}
                    </Option>
                  ))}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]} hidden={!editable}>
            <BaseCol xs={24} md={12}>
              <ProfileImageItem image={image} setImage = {setImage} />
            </BaseCol>
          </BaseRow>
          {editable  && (
            <>
              <BaseRow gutter={[16, 16]}>
                <BaseCol span={24}>
                  <BaseButton type="primary" htmlType="submit" loading={isLoading} disabled={type === 'view'}>
                    {t('Save')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </>
          )}
        </BaseButtonsForm>
      </BaseCard>
    </>
  );
};

export default UserForm;
