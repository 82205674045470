import React, { useEffect, useState } from 'react';
import OrderChart from '@app/components/tables/BasicTable/ReportTable/OrderChart';
import { DatePicker, Tabs } from 'antd';
import moment, { Moment } from 'moment';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { CSVLink } from 'react-csv';
import { ReportDetails, SummaryStatistic, getCustomReportData, getReport } from '@app/api/report.api';
import { notificationController } from '@app/controllers/notificationController';
import dayjs from 'dayjs';

const { TabPane } = Tabs;


const OrderChartPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('years');
  const [customDateRange, setCustomDateRange] = useState<[string, string]>([dayjs().toString(),dayjs().toString()]);
  const [yearsData, setYearsData] = useState<ReportDetails | null>(null);
  const [yearsSummaryData, setYearsSummaryData] = useState<SummaryStatistic|null>(null);
  const [yearData, setYearData] = useState<ReportDetails | null>(null);
  const [lastMonthData, setLastMonthData] = useState<ReportDetails | null>(null);
  const [thisMonthData, setThisMonthData] = useState<ReportDetails | null>(null);
  const [lastSevenDaysData, setLastSevenDaysData] = useState<ReportDetails | null>(null);
  const [customData, setCustomData] = useState<ReportDetails | null>(null);

  const fetchData = () => {
    getReport('orders')
      .then((response) => {
        const data = response ?? [];
        setYearsData(data?.find((e) => e.period === 'years') ?? null);
        setYearData(data?.find((e) => e.period === 'year') ?? null);
        setLastMonthData(data?.find((e) => e.period === 'last-month') ?? null);
        setThisMonthData(data?.find((e) => e.period === 'this-month') ?? null);
        setLastSevenDaysData(data?.find((e) => e.period === 'last-7-days') ?? null);
        setCustomData(data?.find((e) => e.period === 'custom') ?? null);
      })
      .catch((e) => notificationController.error({ message: 'Failed to fetch data!' }));
      
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const handleCustomDateChange = (dates:any, dateStrings: [string, string]) => {
    setCustomDateRange(dateStrings);
    setActiveTab('custom');
    getCustomReportData(dateStrings,'orders')
    .then(response => setCustomData(response))
    .catch(e=>notificationController.error({message:'Failed to fetch custom data!'}))
  };

  const prepareCSVData = (period: string) => {
    if (period === 'years') {
      return yearsData?.stats;
    } else if (period === 'year') {
      return yearData?.stats
    } else if (period === 'lastMonth') {
      return lastMonthData?.stats
    } else if (period === 'thisMonth') {
      return thisMonthData?.stats
    } else if (period === 'last7Days') {
      return lastSevenDaysData?.stats
    } else if (period === 'custom') {
      return customData?.stats
    }

  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <BaseCard id="basic-table" title="Orders" padding="1.25rem">
        <Tabs defaultActiveKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Years" key="years">
            <OrderChart
              period="years"
              xAxisData={
                yearsData?.stats?.map((e) => {
                  return e.x_axis;
                }) ?? []
              }
              orderPlacedData={
                yearsData?.stats?.map((e) => {
                  return e.orders_placed ?? 0;
                }) ?? []
              }
              itemPurchaseData={
                yearsData?.stats?.map((e) => {
                  return e.items_purchased ?? 0;
                }) ?? []
              }
              grossSaleData={
                yearsData?.stats?.map((e) => {
                  return e.gross_sales ?? 0;
                }) ?? []
              }
              avgGrossSaleData={
                yearsData?.stats?.map((e) => {
                  return e.avg_gross_sales ?? 0;
                }) ?? []
              }
              netSalesData={
                yearsData?.stats?.map((e) => {
                  return e.net_sales ?? 0;
                }) ?? []
              }
              avgNetSalesData={
                yearsData?.stats?.map((e) => {
                  return e.avg_net_sales ?? 0;
                }) ?? []
              }
              totalOrderPlaced={`${yearsData?.summary_stats?.orders_placed ?? 0.00}`}
              totalItemsPurchased={`${yearsData?.summary_stats?.items_purchased ?? 0}`}
              totalGrossSale={`LKR ${yearsData?.summary_stats?.gross_sales ?? '0.00'}`}
              totalNetSales={`LKR ${yearsData?.summary_stats?.net_sales ?? '0.00'}`}
            />
            <CSVLink data={prepareCSVData('years') ?? []} filename="order_data_years.csv">
              <BaseButton>Download CSV</BaseButton>
            </CSVLink>
          </TabPane>
          <TabPane tab="Year" key="year">
            <OrderChart
              period="year"
              xAxisData={
                yearData?.stats?.map((e) => {
                  return e.x_axis;
                }) ?? []
              }
              orderPlacedData={
                yearData?.stats?.map((e) => {
                  return e.orders_placed ?? 0;
                }) ?? []
              }
              itemPurchaseData={
                yearData?.stats?.map((e) => {
                  return e.items_purchased ?? 0;
                }) ?? []
              }
              grossSaleData={
                yearData?.stats?.map((e) => {
                  return e.gross_sales ?? 0;
                }) ?? []
              }
              avgGrossSaleData={
                yearData?.stats?.map((e) => {
                  return e.avg_gross_sales ?? 0;
                }) ?? []
              }
              netSalesData={
                yearData?.stats?.map((e) => {
                  return e.net_sales ?? 0;
                }) ?? []
              }
              avgNetSalesData={
                yearData?.stats?.map((e) => {
                  return e.avg_net_sales ?? 0;
                }) ?? []
              }
              totalOrderPlaced={yearData?.summary_stats?.orders_placed}
              totalItemsPurchased={yearData?.summary_stats?.items_purchased}
              totalGrossSale={yearData?.summary_stats?.gross_sales}
              totalNetSales={yearData?.summary_stats?.net_sales}
            />
            <CSVLink data={prepareCSVData('year')??[]} filename="order_data_year.csv">
              <BaseButton>Download CSV</BaseButton>
            </CSVLink>
          </TabPane>
          <TabPane tab="Last Months" key="lastMonths">
            <OrderChart
              period="lastMonths"
              xAxisData={
                lastMonthData?.stats?.map((e) => {
                  return e.x_axis;
                }) ?? []
              }
              orderPlacedData={
                lastMonthData?.stats?.map((e) => {
                  return e.orders_placed ?? 0;
                }) ?? []
              }
              itemPurchaseData={
                lastMonthData?.stats?.map((e) => {
                  return e.items_purchased ?? 0;
                }) ?? []
              }
              grossSaleData={
                lastMonthData?.stats?.map((e) => {
                  return e.gross_sales ?? 0;
                }) ?? []
              }
              avgGrossSaleData={
                lastMonthData?.stats?.map((e) => {
                  return e.avg_gross_sales ?? 0;
                }) ?? []
              }
              netSalesData={
                lastMonthData?.stats?.map((e) => {
                  return e.net_sales ?? 0;
                }) ?? []
              }
              avgNetSalesData={
                lastMonthData?.stats?.map((e) => {
                  return e.avg_net_sales ?? 0;
                }) ?? []
              }
        
              totalOrderPlaced={lastMonthData?.summary_stats?.orders_placed}
              totalItemsPurchased={lastMonthData?.summary_stats?.items_purchased}
              totalGrossSale={lastMonthData?.summary_stats?.gross_sales}
              totalNetSales={lastMonthData?.summary_stats?.net_sales}
            />
            <CSVLink data={prepareCSVData('lastMonth')??[]} filename="oder_data_lastMonth.csv">
              <BaseButton>Download CSV</BaseButton>
            </CSVLink>
          </TabPane>
          <TabPane tab="This Month" key="thisMonth">
            <OrderChart
              period="thisMonth"
              xAxisData={
                thisMonthData?.stats?.map((e) => {
                  return e.x_axis;
                }) ?? []
              }
              orderPlacedData={
                thisMonthData?.stats?.map((e) => {
                  return e.orders_placed ?? 0;
                }) ?? []
              }
              itemPurchaseData={
                thisMonthData?.stats?.map((e) => {
                  return e.items_purchased ?? 0;
                }) ?? []
              }
              grossSaleData={
                thisMonthData?.stats?.map((e) => {
                  return e.gross_sales ?? 0;
                }) ?? []
              }
              avgGrossSaleData={
                thisMonthData?.stats?.map((e) => {
                  return e.avg_gross_sales ?? 0;
                }) ?? []
              }
              netSalesData={
                thisMonthData?.stats?.map((e) => {
                  return e.net_sales ?? 0;
                }) ?? []
              }
              avgNetSalesData={
                thisMonthData?.stats?.map((e) => {
                  return e.avg_net_sales ?? 0;
                }) ?? []
              }
              totalOrderPlaced={thisMonthData?.summary_stats?.orders_placed}
              totalItemsPurchased={thisMonthData?.summary_stats?.items_purchased}
              totalGrossSale={thisMonthData?.summary_stats?.gross_sales}
              totalNetSales={thisMonthData?.summary_stats?.net_sales}
            />
            <CSVLink data={prepareCSVData('thisMonth')??[]} filename="order_data_thisMonth.csv">
              <BaseButton>Download CSV</BaseButton>
            </CSVLink>
          </TabPane>
          <TabPane tab="Last 7 Days" key="last7Days">
            <OrderChart
              period="last7Days"
              xAxisData={
                lastSevenDaysData?.stats?.map((e) => {
                  return e.x_axis;
                }) ?? []
              }
              orderPlacedData={
                lastSevenDaysData?.stats?.map((e) => {
                  return e.orders_placed ?? 0;
                }) ?? []
              }
              itemPurchaseData={
                lastSevenDaysData?.stats?.map((e) => {
                  return e.items_purchased ?? 0;
                }) ?? []
              }
              grossSaleData={
                lastSevenDaysData?.stats?.map((e) => {
                  return e.gross_sales ?? 0;
                }) ?? []
              }
              avgGrossSaleData={
                lastSevenDaysData?.stats?.map((e) => {
                  return e.avg_gross_sales ?? 0;
                }) ?? []
              }
              netSalesData={
                lastSevenDaysData?.stats?.map((e) => {
                  return e.net_sales ?? 0;
                }) ?? []
              }
              avgNetSalesData={
                lastSevenDaysData?.stats?.map((e) => {
                  return e.avg_net_sales ?? 0;
                }) ?? []
              }
              totalOrderPlaced={lastSevenDaysData?.summary_stats?.orders_placed}
              totalItemsPurchased={lastSevenDaysData?.summary_stats?.items_purchased}
              totalGrossSale={lastSevenDaysData?.summary_stats?.gross_sales}
              totalNetSales={lastSevenDaysData?.summary_stats?.net_sales}
            />
            <CSVLink data={prepareCSVData('last7Days')??[]} filename="order_data_last7Days.csv">
              <BaseButton>Download CSV</BaseButton>
            </CSVLink>
          </TabPane>
          <TabPane tab="Custom Date" key="custom">
            <div style={{ position: 'relative', marginBottom: '20px' }}>
              <DatePicker.RangePicker
              onChange={handleCustomDateChange}
              />
            </div>
            <OrderChart
              period="custom"
              xAxisData={
                customData?.stats?.map((e) => {
                  return e.x_axis;
                }) ?? []
              }
              orderPlacedData={
                customData?.stats?.map((e) => {
                  return e.orders_placed ?? 0;
                }) ?? []
              }
              itemPurchaseData={
                customData?.stats?.map((e) => {
                  return e.items_purchased ?? 0;
                }) ?? []
              }
              grossSaleData={
                customData?.stats?.map((e) => {
                  return e.gross_sales ?? 0;
                }) ?? []
              }
              avgGrossSaleData={
                customData?.stats?.map((e) => {
                  return e.avg_gross_sales ?? 0;
                }) ?? []
              }
              netSalesData={
                customData?.stats?.map((e) => {
                  return e.net_sales ?? 0;
                }) ?? []
              }
              avgNetSalesData={
                customData?.stats?.map((e) => {
                  return e.avg_net_sales ?? 0;
                }) ?? []
              }
              totalOrderPlaced={customData?.summary_stats?.orders_placed}
              totalItemsPurchased={customData?.summary_stats?.items_purchased}
              totalGrossSale={customData?.summary_stats?.gross_sales}
              totalNetSales={customData?.summary_stats?.net_sales}
            />
            <CSVLink data={prepareCSVData('custom')??'No Data'} filename="order_data_custom.csv">
              <BaseButton>Download CSV</BaseButton>
            </CSVLink>
          </TabPane>
        </Tabs>
      </BaseCard>
    </div>
  );
};

export default OrderChartPage;
